.unit-page-subpage-settings-overview-foreground-image {
	display: block;
	margin-top: 6px;
	margin-bottom: 12px;
	border-radius: 4px 4px 0 0;
	width: 100%;
}

.unit-page-subpage-settings-overview-foreground-container
	.editable-container-is-editing
	.unit-page-subpage-settings-overview-foreground-image-no-image
	.image-input {
	width: 100%;
	height: calc(((100vw - 76px - 12px - 24px - 6px - 16px - 24px) / 2) * (9 / 16));
	max-height: min(calc(var(--vh) * 0.8), 500px);
}

.unit-page-subpage-settings-overview-foreground-image img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	min-height: calc(((100vw - 76px - 12px - 24px - 6px - 16px - 24px) / 2) * (9 / 16));
	max-height: min(calc(var(--vh) * 0.8), 500px);
	object-fit: contain;
}

.unit-page-subpage-settings-overview-foreground-image .label-container {
	margin-right: 0;
	padding: 0 8px;
	width: 100%;
}

.unit-page-subpage-settings-overview-foreground-image .label-label {
	font-size: 16px;
}

@media (max-width: 1100px) {
	.unit-page-subpage-settings-overview-foreground-image img {
		max-height: min(calc(var(--vh) * 0.4), 500px);
	}
}

@media (max-width: 750px) {
	.unit-page-subpage-settings-overview-foreground-image img {
		max-height: min(calc(var(--vh) * 0.4), 500px);
	}
}
