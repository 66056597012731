.home-search-results-list-container {
	margin-top: 6px;
	padding: 12px;
	padding-bottom: 2px;
	border-radius: 8px;
	width: calc(100% - 24px);
	text-align: center;
	background: var(--colour-grey-two);
}

.home-search-results-list-title {
	margin-bottom: 4px;
	font-size: 18px;
	font-weight: 600;
	text-align: left;
}

.home-search-results-users-list {
	display: flex;
	margin-left: -4px;
	margin-bottom: 12px;
	padding-bottom: 12px;

	width: calc(100% + 4px);
	overflow-x: overlay;
}

.home-search-results-stories-list {
	display: flex;
	padding-bottom: 12px;
	overflow-y: hidden;
	overflow-x: overlay;
}

.home-search-results-users-list::-webkit-scrollbar,
.home-search-results-stories-list::-webkit-scrollbar {
	border-radius: 4px;
	height: 6px;
	background: transparent;
	background: var(--colour-grey-two);
}

.home-search-results-users-list::-webkit-scrollbar-thumb:horizontal,
.home-search-results-stories-list::-webkit-scrollbar-thumb:horizontal {
	border-radius: 4px;
	background: var(--colour-blue);
}

.home-search-results-user-item {
	flex-shrink: 0;
}

.home-search-results-story-item {
	flex-shrink: 0;
	margin: 4px;
	transition: margin 0.3s;
}

.home-search-results-story-item:hover {
	margin-top: 0px;
}

@media (max-width: 750px) {
	.home-search-results-list-container {
		margin-left: 0;
		margin-right: 0;
		max-height: calc(var(--vh) - var(--navbar-mobile-height) - 6px - 50px - 4px - 12px);
		overflow-y: overlay;
	}

	.home-search-results-list-container::-webkit-scrollbar {
		width: 6px;
		background: transparent;
	}

	.home-search-results-list-container::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: var(--colour-blue);
	}
}
