.unauthorized-navigation-bar {
	position: relative;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	margin: 0 auto;
	padding: 12px;

	width: calc(100% - 24px);
	max-width: 1250px;

	background: var(--colour-grey-one);
}

.unauthorized-navigation-bar-landing-btn {
	display: flex;
	align-items: center;
	margin-right: auto;
	border: 0;

	background: #00000000;
	color: var(--text-colour-primary);
	font-size: 16px;
	font-weight: 500;
	white-space: nowrap;
	transition: 0.2s;
	cursor: pointer;
	vertical-align: middle;
}

.unauthorized-navigation-bar-landing-btn:hover {
	color: var(--text-colour-secondary);
}

.unauthorized-navigation-bar-landing-btn-logo {
	--logoHeight: 30px;
	min-height: var(--logoHeight);
	height: var(--logoHeight);
	filter: brightness(0) invert(1) brightness(0.8);
	transition: 0.2s;
}

.unauthorized-navigation-bar-landing-btn:hover .unauthorized-navigation-bar-landing-btn-logo {
	filter: brightness(0) invert(1) brightness(0.55);
}

.unauthorized-navigation-bar-landing-btn-text {
	margin-left: 16px;
}

.unauthorized-navigation-bar-btn {
	margin: 0;
	margin-left: 10px;
	padding: 8px 12px;
	border: 0;
	border-radius: 32px;

	height: fit-content;

	background: transparent;
	color: var(--text-colour-primary);
	font-weight: 600;
	transition: 0.2s;
	cursor: pointer;
}

.unauthorized-navigation-bar-btn:hover {
	color: var(--text-colour-secondary);
}

.unauthorized-navigation-bar-btn-register {
	background: var(--colour-grey-two);
	color: var(--text-colour-primary);
}

.unauthorized-navigation-bar-btn-register:hover {
	color: var(--text-colour-secondary);
}

.unauthorized-navigation-bar-btn:last-child {
	margin-right: 0;
}

@media (max-width: 750px) {
	.unauthorized-navigation-bar-landing-btn-text {
		display: none;
	}
}
