.unit-page-subpage-abilities-ability-item-container .content-item {
	margin-left: 0;
	margin-right: 0;
}

.drag-drop-item-enable-drag-drop.unit-page-subpage-abilities-ability-item-container {
	animation: wiggle-very-small infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).unit-page-subpage-abilities-ability-item-container {
	animation-delay: 0.1s;
}

.unit-page-subpage-abilities-ability-item-container {
	position: relative;
}

.unit-page-subpage-abilities-ability-item-container::after {
	position: absolute;
	bottom: 1px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.unit-page-subpage-abilities-ability-item-container:last-child:after {
	display: none;
}

.unit-page-subpage-abilities-ability-item {
	padding: 12px 0;
	padding-bottom: 13px;
}

.editable-container-is-editing .unit-page-subpage-abilities-ability-item-content {
	display: inline-block;
	width: calc(100% - 24px - 8px);
	vertical-align: top;
}

.text-input-container.unit-page-subpage-abilities-ability-item-title {
	height: 17px;
}

.unit-page-subpage-abilities-ability-item-title .text-input-label {
	padding-top: 0;
}

.unit-page-subpage-abilities-ability-item-title {
	margin-bottom: 4px;
}

.unit-page-subpage-abilities-ability-item-title,
.unit-page-subpage-abilities-ability-item-title input {
	font-size: 14px;
	font-weight: 600;
}
