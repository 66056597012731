.unit-page-subpage-settings-map-image-container .label-value {
	display: flex;
	flex-direction: column;
}

.unit-page-subpage-settings-map-image-select-version-container {
	display: flex;
	gap: 8px;
	align-items: center;
	margin: 12px 0;
}

.unit-page-subpage-settings-map-image-select-version-label {
	margin-bottom: -1px;
	font-size: 13px;
}

.unit-page-subpage-settings-map-image-image {
	display: block;
	margin-top: 6px;
	margin-bottom: 12px;
	border-radius: 4px 4px 0 0;
	width: 100%;
	max-height: calc(((100vw - 76px - 12px - 24px - 6px - 16px - 24px) / 2) * (9 / 16));
	background: var(--colour-grey-three);
}

.unit-page-subpage-settings-map-image-image-no-image {
	width: 100%;
	height: calc(((100vw - 76px - 12px - 24px - 6px - 16px - 24px) / 2) * (9 / 16));
}

.unit-page-subpage-settings-map-image-image img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media (max-width: 1100px) {
	.unit-page-subpage-settings-map-image-image {
		height: calc(((100vw - 76px - 12px - 24px - 6px - 16px)) * (9 / 16));
	}
}

@media (max-width: 750px) {
	.unit-page-subpage-settings-map-image-image {
		height: calc(((100vw - 12px - 24px - 6px - 16px)) * (9 / 16));
	}
}
