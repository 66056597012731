.landing-hero-headline {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, calc(-50% - 48px));
	display: flex;
	gap: 0 28px;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
	--fontSize: 72px;
	font-size: var(--fontSize);
	font-weight: 500;
	color: var(--text-colour-primary);
}

.landing-hero-headline-moving-words {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: right;
	height: calc(var(--fontSize) * 1.7);
	overflow-y: hidden;
	text-align: center;
}

.landing-hero-headline-moving-words::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.landing-hero-headline-moving-words::before,
.landing-hero-headline-moving-words::after {
	display: block;
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	height: 20px;

	background: linear-gradient(0deg, transparent, var(--colour-grey-one));
}

.landing-hero-headline-moving-words::before {
	top: -6px;
}

.landing-hero-headline-moving-words::after {
	bottom: -6px;
	transform: rotate(180deg);
}

.landing-hero-headline-moving-word {
	margin-bottom: -4px;
	background: linear-gradient(180deg, #ffff, #fff6);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.landing-hero-headline-moving-word:first-child {
	animation: landing-hero-headline-moving-word-first-child 12s infinite linear;
	animation-delay: -1.2s;
}

@keyframes landing-hero-headline-moving-word-first-child {
	0% {
		margin-top: 0;
	}
	100% {
		margin-top: calc(-5 * (var(--fontSize) + -4px + 15px));
	}
}

.landing-hero-headline-stationary-word {
	text-align: left;
}

@media (max-width: 800px) {
	.landing-hero-headline {
		transform: translate(-50%, calc(-50% - 150px));
	}
}

@media (max-width: 500px) {
	.landing-hero-headline {
		--fontSize: 56px;
		transform: translate(-50%, calc(-50% - 170px));
	}

	@keyframes landing-hero-headline-moving-word-first-child {
		0% {
			margin-top: 0;
		}
		100% {
			margin-top: calc(-5 * (var(--fontSize) + -4px + 12px));
		}
	}
}
