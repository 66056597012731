.drag-drop-list {
	position: relative;
}

.drag-drop-scroll-top,
.drag-drop-scroll-bottom {
	position: sticky;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 15px;
	pointer-events: initial !important;
}

.drag-drop-scroll-top {
	top: 0;
}

.drag-drop-scroll-bottom {
	bottom: 0;
}

.drag-drop-list-absolute-vertical-drag > *:nth-child(2) {
	margin-top: -15px;
}
