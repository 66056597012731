.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-container {
	display: flex;
	gap: 24px;
	flex-direction: column;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-container .label-container {
	margin: 0;
	padding: 0;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-buttons .label-value,
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-buttons .label-value {
	margin-top: 8px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn,
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn {
	position: relative;
	margin: 0 4px;
	border-radius: 5px;
	width: 38px;
	height: 34px;
	background: var(--colour-grey-three);
	transition: 0.2s;
	cursor: pointer;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn:hover,
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn:hover {
	background: var(--colour-grey-five);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn svg,
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn svg {
	position: absolute;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn svg:nth-child(1),
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn svg:nth-child(1) {
	top: 50%;
	left: 6px;
	transform: translateY(-50%);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn svg:nth-child(2),
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn svg:nth-child(2) {
	top: 50%;
	right: 6px;
	transform: translateY(-50%);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn svg:nth-child(1) {
	left: 7px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn svg:nth-child(2) {
	right: 7px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn svg:nth-child(3) {
	top: 1px;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn svg:nth-child(4) {
	bottom: 1px;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn-vertical-center svg:nth-child(1),
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn-vertical svg:nth-child(1) {
	top: 1px;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-alignment-btn-vertical-center svg:nth-child(2),
.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn-vertical svg:nth-child(2) {
	top: unset;
	bottom: 1px;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn-vertical svg:nth-child(1) {
	top: 3px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn-vertical svg:nth-child(2) {
	bottom: 3px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-details-type-image-resize-btn-center svg {
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) rotate(45deg) scale(1.2) !important;
}
