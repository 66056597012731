.unit-page-subpage-details-item-container {
	margin: 12px 0;
}

.unit-page-subpage-details-item-container .content-item .unit-page-subpage-details-item-container .content-item {
	margin: 0;
	margin-top: 8px;
	padding-right: 8px;
}

.unit-page-subpage-details-item-container-0,
.unit-page-subpage-details-item-container-0 .content-item {
	margin-top: 0;
}

.unit-page-subpage-details-item-has-single-line-text .content-item {
	padding-bottom: 8px;
}

.unit-page-subpage-details-item-container:last-child {
	padding-bottom: calc(var(--vh) * 0.5);
}

.unit-page-subpage-details-items-container.editable-container-is-editing .unit-page-subpage-details-items > :nth-last-child(2) {
	padding-bottom: calc(var(--vh) * 0.5 + 48px);
}

.drag-drop-item-enable-drag-drop.unit-page-subpage-details-item-container {
	animation: wiggle-very-small infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).unit-page-subpage-details-item-container {
	animation-delay: 0.1s;
}

.unit-page-subpage-details-item-container {
	position: relative;
}

.unit-page-subpage-details-item-container::after {
	position: absolute;
	bottom: 1px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.unit-page-subpage-details-item-container:last-child:after {
	display: none;
}

.unit-page-subpage-details-item {
	padding: 12px 8px;
	padding-bottom: 13px;
}

.unit-page-subpage-details-item-content {
	display: inline-block;
	width: calc(100% - 32px);
	vertical-align: top;
}

.unit-page-subpage-details-item-text-container {
	display: inline-block;
	margin-right: 12px;
	width: calc(55% - 12px);
	vertical-align: top;
}

.unit-page-subpage-details-item-text-container-full-width {
	margin-right: 0;
	width: 100%;
}

.unit-page-subpage-details-item-text-container-no-width {
	display: none !important;
}

.unit-page-subpage-details-item-images-container {
	display: inline-block;
	padding: 2px;
	border-radius: 2px;
	width: calc(45% - 4px);
	vertical-align: top;
}

.unit-page-subpage-details-item-images-container-full-width {
	width: calc(100% - 4px);
}

.unit-page-subpage-details-item-images-container-no-width {
	display: none !important;
}

.unit-page-subpage-details-item-images-container-is-current {
	animation: unit-page-subpage-details-item-images-container-is-current 1.5s infinite linear;
}

@keyframes unit-page-subpage-details-item-images-container-is-current {
	0%,
	100% {
		background: var(--colour-grey-one);
	}
	35%,
	65% {
		background: var(--colour-grey-three);
	}
}

.unit-page-subpage-details-item-images {
	display: inline-flex;
	flex-wrap: wrap;
	width: 100%;
}

.unit-page-subpage-details-item-image-item {
	position: relative;
	margin: 4px;
	width: calc((100% / 3) - 8px);
}

.drag-drop-item-enable-drag-drop .unit-page-subpage-details-item-image-item {
	-webkit-user-drag: element;
}

.unit-page-subpage-details-item-image-item img {
	width: 100%;
	max-height: max(18vw, 250px);
	object-fit: contain;
}

.unit-page-subpage-details-item-image-item-placeholder {
	position: relative;
	min-height: 200px;
}

.unit-page-subpage-details-item .multi-line-text-input {
	margin-bottom: -4px;
}

.unit-page-subpage-details-item-title {
	word-break: break-word;
	overflow-wrap: anywhere;
}

.unit-page-subpage-details-item-title .text-input {
	margin-top: -4px;
}

.unit-page-subpage-details-item-title,
.unit-page-subpage-details-item-title input {
	font-size: var(--font-size-p-m);
	font-weight: 600;
}

.unit-page-subpage-details-item .text-input-container-empty {
	height: var(--font-size-p-m-height);
}

.unit-page-subpage-details-item-text {
	min-height: var(--font-size-p-m-height);
}

.unit-page-subpage-details-item-image-item-caption {
	padding: 3px 8px;
	border-radius: 4px;
	width: fit-content;
	max-width: calc(100% - 16px);
	word-break: break-word;
	overflow-wrap: anywhere;
	background: var(--colour-grey-three);
}

.unit-page-subpage-details-item-image-item-caption,
.unit-page-subpage-details-item-image-item-caption input {
	font-size: 14px;
	min-height: 20px;
}

.unit-page-subpage-details-item-image-item-btns-container {
	position: absolute;
	top: 2px;
	right: 2px;
}

.unit-page-subpage-details-item-buttons-container {
	display: inline-flex;
	flex-direction: column;
	width: 32px;
	gap: 8px 0;
}

@media (max-width: 1000px) {
	.unit-page-subpage-details-item-container {
		margin: 6px 0;
		width: 100%;
	}

	.unit-page-subpage-details-item-text-container {
		display: inline-block;
		margin-right: 0;
		width: 100%;
	}

	.unit-page-subpage-details-item-images-container {
		display: inline-block;
		margin-top: 12px;
		padding: 2px;
		border-radius: 2px;
		width: calc(100% - 4px);
		vertical-align: top;
	}

	.unit-page-subpage-details-item-image-item {
		width: calc((100% / 2) - 8px);
	}
}
