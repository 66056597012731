.locations-list {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	overflow: hidden;
}

.locations-list-primary {
	display: flex;
	align-items: center;
	gap: 12px;
}

.locations-list-primary .search-input-container {
	width: 100%;
}

.locations-list-items {
	display: flex;
	gap: 32px;
	flex-direction: column;
	margin-top: 4px;
	padding: 4px;
	padding-top: 16px;
	max-height: calc(var(--vh) - 212px);
	overflow: auto;
	box-sizing: border-box;
}

.locations-list-item {
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
	cursor: pointer;
	color: var(--text-colour-secondary);
	transition: color 0.2s;
}

.locations-list-item:hover {
	color: var(--text-colour-primary);
}

.locations-list-item::after {
	position: absolute;
	bottom: -16px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.locations-list-item:last-child {
	margin-bottom: calc(var(--vh) - 212px - 48px);
}

.locations-list-item:last-child::after {
	display: none;
}

.locations-list-item-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
}

.locations-list-item-name {
	font-size: var(--font-size-p-m);
	font-weight: 700;
}

.location-list-item-bullet {
	display: block;
	border-radius: 100%;
	margin: 0 -4px;
	width: 4px;
	height: 4px;
	background: var(--text-colour-secondary);
}

.locations-list-item-type {
	display: flex;
	align-items: center;
	gap: 4px;
}

.locations-list-item-type-text {
	font-size: var(--font-size-p-m);
}

.locations-list-items-loading-circle-container {
	position: relative;
	height: calc(var(--vh) - 494px - 48px);
}

@media (max-width: 750px) {
	.locations-list-items {
		max-height: calc(var(--vh) - 342px);
	}

	.locations-list-items-loading-circle-container {
		height: calc(var(--vh) - 342px - 48px);
	}

	.locations-list-item:last-child {
		margin-bottom: calc(var(--vh) - 342px - 48px);
	}
}
