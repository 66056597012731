.search-input-container {
	position: relative;
	margin: 0;
}

.search-input {
	padding: 10px 16px;
	border: 1px solid var(--colour-grey-four);
	border-radius: 30px;
	outline: 0;

	width: 100%;
	box-sizing: border-box;

	background: var(--colour-grey-two);
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	transition: 0.1s;
}

.search-input:focus {
	outline: 0;
	border-color: var(--colour-blue);
	background: var(--colour-grey-two);
}

.search-input-placeholder-container {
	position: absolute;
	top: 50%;
	left: 18px;
	transform: translateY(-50%);
	display: flex;
	align-items: center;

	transition: 0.1s;
	pointer-events: none;
	font-weight: 600;
}

.search-input-placeholder-icon {
	margin-top: 2px;
	margin-bottom: -2px;
	font-size: 18px;
	color: var(--text-colour-secondary);
}

.search-input-placeholder-text {
	margin-left: 10px;
	font-size: 13px;
	color: var(--text-colour-secondary);
}

.search-input:focus ~ .search-input-placeholder-container {
	opacity: 0.5;
}

@media (max-width: 750px) {
	.search-input {
		font-size: 16px;
	}
}
