.events-timeline-container {
	--timelineWidth: max(40vw, 525px);
	position: relative;
	height: calc(var(--vh) - 118px - 52px - 48px - 12px);
	opacity: 0;
	animation: fade-in 0.75s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

.events-timeline-container::before,
.events-timeline-container::after {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	display: block;
	content: "";
	width: 80px;
	height: 100%;
	background: linear-gradient(270deg, #0000, var(--colour-grey-one));
	pointer-events: none;
	user-select: none;
}

.events-timeline-container::after {
	left: unset;
	right: 0;
	transform: rotate(180deg);
}

.events-timeline {
	height: 100%;
}

.events-timeline .carousel-content {
	padding-bottom: 32px;
	height: 100%;
}

.events-timeline-events {
	position: relative;
	z-index: 2;
	display: flex;
	min-width: 100%;
	width: max-content;
	height: 100%;
	overflow: hidden;
}

.events-timeline-events::before {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	display: block;
	content: "";
	min-width: 100%;
	width: max-content;
	height: 100%;
	background: url("../../../content/timeline.png");
	background-repeat: repeat-x;
	background-size: var(--timelineWidth);
	background-position: 0 center;
	animation: events-timeline-events-line-behind 60s infinite linear;
}

.events-timeline-events::after {
	position: absolute;
	z-index: -2;
	top: 40px;
	left: 0;
	display: block;
	content: "";
	min-width: 100%;
	width: max-content;
	height: 100%;
	background: url("../../../content/timeline.png");
	background-repeat: repeat-x;
	background-size: var(--timelineWidth);
	background-position: 0 center;
	opacity: 0.1;
	animation: events-timeline-events-line-behind 20s infinite linear;
}

@keyframes events-timeline-events-line-behind {
	0% {
		background-position: 0 center;
	}
	100% {
		background-position: 100% center;
	}
}

.events-timeline-event-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;
	width: calc(var(--timelineWidth) * 0.25);
	height: calc(50% + 5px);
	cursor: pointer;
}

.events-timeline-event-container:first-child {
	margin-left: calc(var(--timelineWidth) * 0.125);
}

.events-timeline-event-container:last-child {
	margin-right: calc(var(--timelineWidth) * 0.125);
}

.events-timeline-event-container:nth-child(even) {
	align-self: flex-end;
	flex-direction: column-reverse;
}

.events-timeline-event {
	width: fit-content;
	text-align: center;
}

.events-timeline-event-container-minor .events-timeline-event {
	margin-top: 100px;
}

.events-timeline-event-container:nth-child(even).events-timeline-event-container-minor .events-timeline-event {
	margin-top: 0;
	margin-bottom: 100px;
}

.events-timeline-event-name {
	width: calc(var(--timelineWidth) * 0.25 + 100px);
	text-transform: uppercase;
	font-family: "Playfair Display", serif;
	color: var(--text-colour-secondary);
	user-select: none;
	transition: 0.2s;
}

.events-timeline-event-container:hover .events-timeline-event-name {
	color: var(--text-colour-primary);
}

.events-timeline-event-container-major .events-timeline-event-name {
	font-size: 17px;
	font-weight: 600;
	letter-spacing: 1px;
	word-spacing: 2px;
}

.events-timeline-event-container-minor .events-timeline-event-name {
	font-size: 15px;
}

.events-timeline-event-date {
	font-size: 14px;
	color: var(--text-colour-secondary);
	text-transform: uppercase;
	user-select: none;
}

.events-timeline-event-container-major .events-timeline-event-date {
	font-size: 14px;
}

.events-timeline-event-container-minor .events-timeline-event-date {
	font-size: 12px;
}

.events-timeline-event-line {
	position: relative;
	display: block;
	content: "";
	width: 100%;
	height: 100%;
}

.events-timeline-event-container-minor .events-timeline-event-line {
	height: calc(100% - 100px);
}

.events-timeline-event-line::before {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	content: "";
	width: 6px;
	height: 100%;
	background: linear-gradient(0deg, #fff0, #fffc, #fffc, #fff0);
	opacity: 0.5;
	transition: 0.2s;
}

.events-timeline-event-container:hover .events-timeline-event-line::before {
	opacity: 0.8;
}

@media (max-width: 750px) {
	.events-timeline-container::before,
	.events-timeline-container::after {
		width: 40px;
	}
}
