.locations-location-name-container {
	margin: 16px 0;
	width: 100%;
}

.locations-location-name,
.locations-location-name input {
	--text-input-font-size: 24px;
	font-size: var(--text-input-font-size);
	font-weight: 400;
}

.locations-location-name-container .locations-location-name {
	display: flex;
	align-items: center;
	min-height: 26px;
}
