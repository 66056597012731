.carousel-container {
	position: relative;
	width: 100%;
}

.carousel-content {
	padding-bottom: 8px;
	width: 100%;
	overflow-x: overlay;
}

.carousel-content::-webkit-scrollbar {
	border-radius: 4px;
	height: 6px;
	background: transparent;
	background: var(--colour-grey-one);
}

.carousel-content::-webkit-scrollbar-thumb:horizontal {
	border-radius: 4px;
	background: var(--colour-scroll-bar-thumb-background);
}

.carousel-container-button-scroll .carousel-content {
	scroll-behavior: smooth;
}

.carousel-container-button-scroll .carousel-content::-webkit-scrollbar {
	display: none;
}

.carousel-scroll-left,
.carousel-scroll-right {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	z-index: 3;

	width: 48px;
	height: 100%;

	background: rgba(0, 0, 0, 0);
}

.carousel-scroll-left {
	left: 0px;
}

.carousel-scroll-right {
	right: 0px;
}

.carousel-scroll-hidden {
	display: none;
}

.carousel-container:not(.carousel-container-button-scroll) .carousel-scroll-buttons {
	display: none;
}

.carousel-container-button-scroll .carousel-scroll-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
}

.carousel-scroll-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border: 0;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
	font-size: 18px;
	transition: 0.2s;
	cursor: pointer;
}

.carousel-scroll-btn:hover {
	background: var(--colour-grey-four);
}

.carousel-scroll-btn-left svg {
	margin-left: -2px;
}

.carousel-scroll-btn-right svg {
	margin-left: 2px;
}

@media (max-width: 750px) {
	.carousel-content {
		padding-bottom: 12px;
	}

	.carousel-content::-webkit-scrollbar {
		height: 14px;
	}

	.carousel-content::-webkit-scrollbar-thumb:horizontal {
		border: 4px solid var(--colour-grey-one);
		border-radius: 6px;
		border-left: 0;
		border-right: 0;
	}

	.carousel-container-disabled-on-mobile .carousel-scroll-left,
	.carousel-container-disabled-on-mobile .carousel-scroll-right {
		width: 0;
	}
}
