.unit-page-subpage-abilities-ability {
	display: inline-block;

	width: calc(100% - 260px - 24px);
	vertical-align: top;
}

@media (max-width: 750px) {
	.unit-page-subpage-abilities-ability {
		width: 100%;
	}
}
