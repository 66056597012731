.user-stories {
	margin: 0;
	width: 100%;
}

.user-stories-top {
	display: flex;
	align-items: center;
	margin: 32px 0;
	margin-bottom: 8px;
}

.user-stories-top-title {
	display: inline-block;
	font-size: 32px;
	font-weight: 600;
	vertical-align: middle;
}

.user-stories-top-items-count {
	display: inline-block;
	margin-top: 3px;
	margin-left: 12px;
	font-size: 16px;
	color: var(--text-colour-tertiary);
	vertical-align: middle;
}

.user-stories-top-modify-btns-container {
	margin-left: auto;
}

.user-stories-top-modify-btn {
	margin: 0 8px;
}

.user-stories-top-modify-btn:last-child {
	margin-right: 0;
}

.user-stories-story-items-container {
	margin-bottom: 32px;
}

.user-stories-story-items {
	display: flex;
	gap: 8px;
}

.user-stories-add-first-story-container {
	height: min(50vh, 500px);
}

@media (max-width: 750px) {
	.user-stories {
		margin: 0;
		padding: 6px;
		width: calc(100% - 12px);
	}

	.user-stories-top {
		margin-top: 12px;
	}

	.user-stories-top-title {
		display: inline-block;
		font-size: 24px;
	}

	.user-stories-top-items-count {
		margin-top: 1px;
		margin-left: 8px;
		font-size: 13px;
	}

	.user-stories-story-items {
		gap: 0;
	}
}
