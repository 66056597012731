.characters-create-character-form {
	display: flex;
	gap: 32px;
	flex-direction: column;
}

.characters-create-character-form-input-container {
	margin: 0;
}

.characters-create-character-form-input-container .toggle-input-container {
	padding: 0 8px;
}

.characters-create-character-form {
	margin-left: -4px;
	width: calc(100% + 8px);
}

.characters-create-character-form-submit-container {
	margin-bottom: 5px;
	text-align: right;
}

.characters-create-character-form-submit-btn-text {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}

.characters-create-character-form-submit-btn-icon {
	display: inline-block;
	margin-top: 1px;
	margin-left: 8px;
	vertical-align: middle;
	font-size: 15px;
}
