.home-container {
	position: relative;
	margin: 0 auto;
	padding: 0 24px;
	width: calc(100% - 48px);
	height: 100%;
}

.home-content {
	position: relative;
	top: calc(12px + 42px + 24px);
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 32px;
}

@media (max-width: 750px) {
	.home-container {
		padding: 0 6px;
		width: calc(100% - 12px);
	}

	.home-content {
		top: calc(12px + 42px + 12px);
		width: 100%;
		flex-direction: column;
	}
}
