.story-settings-section-container {
	margin: 12px 0;
	margin-bottom: 24px;
}

.story-settings-section-container:last-child {
	margin-bottom: 12px;
}

.story-settings-section-label {
	font-size: 16px;
	font-weight: 600;
}

.story-settings-input-container {
	position: relative;
	margin: 6px 0;
	width: 100%;
}

.editable-container-is-editing.story-settings-input-container {
	margin-top: 4px;
}

.story-settings-input-container .label-container {
	padding: 0;
}

.story-settings-input-container .label-container .label-label {
	font-size: 16px;
	font-weight: 500;
}

.story-settings-confirm-delete {
	margin-top: 12px !important;
	justify-content: left !important;
	text-align: left !important;
}

.story-settings-confirm-delete .confirm-delete-btn {
	margin-left: 2px;
}

.story-settings-confirm-delete.confirm-delete-container-is-confirming .confirm-delete-btn {
	margin-left: 12px;
}
