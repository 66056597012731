.locations-location-paths-container {
	padding: 8px 0;
}

.locations-location-paths-title {
	font-size: var(--font-size-p-m);
	font-weight: 600;
}

.locations-location-paths-list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px 8px;
	border-radius: 4px;
	background: var(--colour-grey-one);
	max-height: calc(var(--vh) * 0.5 - 2 * 16px - 2 * 16px - 32px);
	overflow-y: auto;
}
