.unit-page-primary-name-container {
	align-items: flex-start;
	overflow: visible;
	width: 100%;
	max-width: calc(100vw - 74px - 48px - 48px - 281px - 180px);
	word-break: break-word;
	overflow-wrap: anywhere;
}

.unit-page-primary-name-container,
.unit-page-primary-name,
.unit-page-primary-name input {
	width: fit-content;
	--text-input-font-size: 40px;
	font-size: 40px;
	font-weight: 700;
	transition: 0.5s color;
	color: var(--text-colour-primary);
}

.unit-page-container-location .unit-page-primary-name-container {
	margin-right: 24px;
	max-width: calc(100vw - var(--navbar-width) - 2 * 24px - 64px);
}

.unit-page-primary-name {
	max-width: calc(100vw - 657px);
}

.unit-page-container-location .unit-page-primary-name {
	width: max-content;
}

@media (max-width: 750px) {
	.unit-page-primary-name-container {
		margin: 4px 0;
		max-width: calc(100% - 24px);
	}

	.editable-container-is-authorized.unit-page-primary-name-container {
		width: 100%;
		max-width: calc(100vw - 200px);
	}

	.unit-page-primary-name-container,
	.unit-page-primary-name,
	.unit-page-primary-name input {
		font-size: 26px;
		--text-input-font-size: 26px;
		word-wrap: break-word;
	}

	.unit-page-primary-name {
		max-width: calc(100vw - 232px);
	}

	.unit-page-primary-name-container .text-input-input {
		max-width: calc(100vw - 211px) !important;
	}
}
