.notes-list-container {
	height: 100%;
}

.notes-list {
	padding-right: 12px;
	width: calc(100% - 12px);
	height: calc(var(--vh) - 95px - 24px - 12px);
	overflow-y: overlay;
	overflow-x: hidden;
}

@media (max-width: 750px) {
	.notes-list {
		height: calc(var(--vh) - var(--navbar-mobile-height) - 80px - 12px);
	}
}
