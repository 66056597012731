.user-stories-create-story-form-input-container {
	margin: 12px 0;
}

.user-stories-create-story-form-submit-container {
	margin-top: 20px;
	text-align: right;
}

.user-stories-create-story-form-submit-btn-text {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}

.user-stories-create-story-form-submit-btn-icon {
	display: inline-block;
	margin-top: 1px;
	margin-left: 8px;
	vertical-align: middle;
	font-size: 15px;
}
