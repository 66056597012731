.story-settings-members-user-item-container {
	margin: 8px 0;
}

.story-settings-members-user-item {
	display: flex;
	align-items: center;
}

.story-settings-members-user-item-profile-picture-container {
	margin-right: 16px;
	border-radius: 100%;
	--profilePictureWidth: 50px;
	min-width: var(--profilePictureWidth);
	width: var(--profilePictureWidth);
	min-height: var(--profilePictureWidth);
	height: var(--profilePictureWidth);
}

.story-settings-members-user-item-profile-picture-container img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.story-settings-members-user-item-names-container {
	margin-right: 16px;
}

.story-settings-members-user-item-names-nickname {
	font-weight: 600;
}

.story-settings-members-user-item-names-username {
	font-weight: 400;
	font-size: 16px;
	color: var(--text-colour-secondary);
}

.story-settings-members-user-item-btns-container {
	margin-left: auto;
}

.story-settings-members-user-item-btns-container .icon-btn:hover,
.story-settings-members-user-item-btns-container .icon-btn:focus {
	color: var(--colour-light-green);
}

@media (max-width: 750px) {
	.story-settings-members-user-item-container {
		margin-left: 0;
	}
}
