.story-header-main-info-members {
	margin-top: -3px;
	margin-bottom: 3px;
	font-weight: 400;
	font-size: 16px;
	color: var(--text-colour-primary);
	min-height: 24px;
}

.story-header-main-info-member {
	position: relative;
	display: inline-block;
	margin: 2px;
	margin-left: 0;
	cursor: pointer;
	font-weight: 300;
}

.story-header-main-info-member-label-container {
	position: relative;
}

.story-header-main-info-member-label {
	position: absolute;
	left: 50%;
	top: 4px;
	left: -8px;
	z-index: 2;
	padding: 8px 16px;
	border-radius: 24px;

	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
	font-weight: 500;
	font-size: 16px;
	font-size: 14px;
	box-shadow: 1px 1px 5px var(--colour-grey-one);
	transition: 0.2s;
	opacity: 0;
}

.story-header-main-info-member:hover .story-header-main-info-member-label {
	opacity: 1;
}

@media (max-width: 750px) {
	.story-header-main-info-members {
		font-size: 13px;
	}

	.story-header-auth-buttons-member {
		margin-right: 12px;
	}
}
