.unit-page-subpage-relationships-chart-unit-page-item-name-container {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	width: 80px;
	font-size: 12px;
}

.unit-page-subpage-relationships-chart-unit-page-item-name {
	width: 100%;

	white-space: pre-wrap;
	word-break: break-word;
	overflow-wrap: anywhere;
}

@media (max-width: 1100px) {
	.unit-page-subpage-relationships-chart-unit-page-item-name {
		display: none;
	}

	.unit-page-subpage-relationships-chart-unit-page-item-active .unit-page-subpage-relationships-chart-unit-page-item-name {
		display: block;
	}
}
