.chararcter-settings-uid-editing {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.chararcter-settings-uid-editing-input {
	width: fit-content;
}

.chararcter-settings-uid-editing-input .text-input-container {
	--text-input-font-size: 16px;
}

.chararcter-settings-uid-editing .error-message {
	padding-right: 0;
	max-width: 220px;
}
