.unit-page-subpage-relationships-relationship-header-title {
	font-size: 16px;
	font-weight: 600;
}

.unit-page-subpage-relationships-relationship-header-type {
	display: flex;
	align-items: center;
	margin-top: 8px;
}

.unit-page-subpage-relationships-relationship-header-type-colour {
	display: block;
	content: "";
	margin-right: 8px;
	border-radius: 4px;

	min-width: 24px;
	width: 24px;
	min-height: 24px;
	height: 24px;

	background: var(--colour-grey-three);
}

.unit-page-subpage-relationships-relationship-header-type-name {
	font-size: 14px;
	font-weight: 600;
}
