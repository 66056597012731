.landing-character-card {
	flex-shrink: 0;
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 8px;
	margin-right: 16px;
	border: 5px solid var(--colour-accent);
	border-color: var(--characterColour);
	border-radius: 16px;

	width: 305px;
	height: 420px;
	overflow: hidden;

	background: var(--colour-grey-one);

	transform-origin: right;
	scale: 0.95;
	transform: rotate(-10deg);
}

@media (max-width: 1400px) {
	.landing-character-card {
		scale: 0.7;
	}
}

@media (max-width: 1100px) {
	.landing-character-card {
		scale: 0.6;
	}
}

@media (max-width: 950px) {
	.landing-character-card {
		scale: 0.55;
	}
}

.landing-character-card-content {
	position: relative;
	z-index: 1;
	height: 100%;
}

.landing-character-card-top-container {
	display: flex;
	align-items: center;
	padding: 12px 20px;
}

.landing-character-card-top-name {
	font-size: 24px;
	font-weight: 600;
	color: var(--characterCardNameColour);
}

.landing-character-card-character-type {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	padding: 6px 12px;
	border-radius: 32px;
	height: fit-content;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	background: linear-gradient(0deg, var(--characterTypeColourGradient1), var(--characterTypeColourGradient2));
}

.landing-character-card-summary-items-container {
	position: absolute;
	top: 224px;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 0 16px;

	width: calc(100% - 16px);
	max-height: calc(100% - 224px);
	overflow: hidden;
}

.landing-character-card-summary-item {
	margin-bottom: 6px;
	padding: 8px 12px;
	border-radius: 8px;

	width: calc(100% - 24px);

	background: var(--characterColour);
	background: linear-gradient(0deg, var(--characterColourGradient1), var(--characterColourGradient2) 130%);
	color: #fff;
	text-align: left;
}

.landing-character-card-summary-item-label {
	font-size: 11px;
	font-weight: 400;
}

.landing-character-card-summary-item-text {
	font-size: 11px;
	font-weight: 600;
}

.font-size-s .landing-character-card-summary-item-label,
.font-size-s .landing-character-card-summary-item-text,
.font-size-xs .landing-character-card-summary-item-label,
.font-size-xs .landing-character-card-summary-item-text {
	font-size: 11px;
}

.landing-character-card-background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 5px solid var(--colour-accent);
	border-radius: inherit;

	width: 100%;
	height: 100%;
}

.landing-character-card-background img {
	transform: translate(0px, 0px);

	border-color: var(--characterColour);
	border-radius: inherit;

	width: calc(100% + 14px);
	height: 100%;

	object-fit: cover;
	-webkit-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
}
