.unit-page-primary-map-btn-container {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	width: max-content;
}

.unit-page-primary-map-btn {
	display: flex;
	align-items: center;
	margin: 0 12px;

	overflow: hidden;

	text-align: left;
}

.unit-page-primary-map-btn svg {
	font-size: 24px;
}

@media (max-width: 350px) {
	.unit-page-primary-map-btn {
		margin: 0;
	}
}
