.characters-create-group-form {
	display: flex;
	gap: 32px;
	flex-direction: column;
}

.characters-create-group-form-input-container {
	margin: 0;
}

.characters-create-group-form-submit-container {
	text-align: right;
}

.characters-create-group-form-submit-btn-text {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}

.characters-create-group-form-submit-btn-icon {
	display: inline-block;
	margin-top: 1px;
	margin-left: 8px;
	vertical-align: middle;
	font-size: 15px;
}
