.locations-map {
	position: relative;
	width: 100%;
	height: 100%;
	background: #000000;
}

.locations-map-loading-circle-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	background: #000000;
	animation: fade-out 0.5s 1;
	animation-fill-mode: forwards;
	animation-delay: 2.5s;
	pointer-events: none;
	user-select: none;
}

.locations-map-loading-circle-container::before {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, #180a18, #000010, #000a1a, #00120a 110%);
	opacity: 0.75;
}

.locations-map-is-displaying {
	animation: fade-in 0.2s 1;
	animation-delay: 0.5s;
	animation-fill-mode: forwards;
	/* opacity: 0; */
	user-select: none;
	pointer-events: none;
}

.locations-map-hidden {
	animation: fade-out 0.1s 1;
	animation-fill-mode: forwards;
}

.locations-map-is-displaying .locations-map-space-map {
	width: 100%;
	height: 100%;
	animation: fade-in 0.2s 1;
	animation-delay: 0.5s;
	animation-fill-mode: forwards;
	opacity: 0;
}

.locations-map-hidden .locations-map-space-map {
	animation: fade-out 0.1s 1;
	animation-fill-mode: forwards;
}

.locations-map-label-container {
	position: fixed;
	z-index: 2;
	width: 100vw;
	height: var(--vh);
	pointer-events: none;
	user-select: none;
	opacity: 0.8;
}

.locations-map-label {
	position: fixed;
	display: flex;
	align-items: center;
	gap: 4px;
	margin-top: -34px;
	margin-left: 24px;
	color: #fff;
	user-select: none;
	animation: fade-in 0.2s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	opacity: 0;
}

.locations-map-label:before {
	position: absolute;
	display: block;
	content: "";
	bottom: -2px;
	left: -8px;
	width: calc(100% + 8px);
	height: 2px;
	background: #fff;
}

.locations-map-label:after {
	position: absolute;
	display: block;
	content: "";
	bottom: -9px;
	left: -25px;
	width: 22px;
	height: 2px;
	background: #fff;
	transform: rotate(-45deg);
}

.locations-map-label span {
	user-select: none;
}
