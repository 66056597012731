.characters-group-character-card-container {
	margin-left: -112px;
	transition: margin 0.4s;
}

.characters-group-character-card-container:first-child {
	margin-left: 0;
}

.characters-group-character-card-container:last-child {
	margin-right: 0;
}

.characters-group-character-card-container:hover + .characters-group-character-card-container {
	margin-left: 4px;
}

.characters-group-characters-cards-container .drag-drop-item-enable-drag-drop {
	margin: 0 4px;
}

.characters-group-character-card-wrapper {
	position: relative;
	z-index: 1;
	margin: 4px;
	padding: 2px;
	border-radius: 16px;
	background: linear-gradient(180deg, var(--characterColour), var(--characterColourTint));
}

.characters-group-character-card {
	flex-shrink: 0;
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 4px;
	border: 0;
	border-radius: 12px;

	width: 412px;
	height: 585px;
	--characterCardHeight: 585px;

	background: var(--colour-grey-one);
	transition: margin-left 0.4s;
	cursor: pointer;
}

.drag-drop-item-enable-drag-drop .characters-group-character-card {
	animation: wiggle-small infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even) .characters-group-character-card {
	animation-delay: 0.1s;
}

.characters-group-character-card-placeholder {
	flex-shrink: 0;
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 4px;
	margin-bottom: 0;
	border: 6px solid var(--colour-accent);
	border-color: var(--colour-grey-two);
	border-radius: 16px;

	width: 418px;
	height: 585px;

	background: var(--colour-grey-one);
	animation: characters-group-character-card-placeholder 2.5s infinite;
}

@keyframes characters-group-character-card-placeholder {
	0% {
		border-color: var(--colour-grey-one);
		background: var(--colour-grey-one);
	}
	15%,
	55% {
		border-color: var(--colour-grey-three);
		background: var(--colour-grey-two);
	}
	88%,
	100% {
		border-color: var(--colour-grey-one);
		background: var(--colour-grey-one);
	}
}

.characters-group-character-card-content {
	position: relative;
	z-index: 1;
	height: 100%;
}

.characters-group-character-card-top-container {
	display: flex;
	align-items: center;
	padding: 12px 20px;
}

.characters-group-character-card-top-name {
	font-size: 28px;
	font-weight: 600;
	color: var(--colour-blue);
	color: var(--characterCardNameColour);
}

.characters-group-character-card-character-type {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	padding: 7px 12px;
	border-radius: 32px;
	height: fit-content;
	font-size: 13px;
	font-weight: 600;
	color: #fff;
	background: linear-gradient(0deg, var(--characterTypeColourGradient1), var(--characterTypeColourGradient2));
}

.characters-group-character-card-summary-item-container {
	position: absolute;
	top: 324px;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	width: calc(100% - 16px);
	max-height: calc(100% - 324px);
	overflow: hidden;
}

.characters-group-character-card-summary-item {
	margin-bottom: 8px;
	padding: 8px 12px;
	border-radius: 8px;

	width: calc(100% - 24px);

	background: var(--colour-blue);
	background: var(--characterColour);
	background: linear-gradient(0deg, var(--characterColourGradient1), var(--characterColourGradient2) 130%);
	color: #fff;
	text-align: left;
}

.characters-group-character-card-summary-item-label {
	font-size: 12px;
	font-weight: 400;
}

.characters-group-character-card-summary-item-text {
	font-size: 12px;
	font-weight: 600;
}

.characters-group-character-card-background-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: inherit;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.characters-group-character-card-background {
	height: 100%;
	overflow: visible;
}

.characters-group-character-card-background-alignment {
	border-radius: inherit;
	min-width: 100%;
	min-height: 100%;
}

.characters-group-character-card-background img {
	display: flex;
	border-radius: inherit;
	width: 100%;
	/* height: 100%;
	min-height: var(--characterCardHeight);
	object-fit: cover; */
	overflow: visible;
	transform-origin: top;
	--scale: 1;
	scale: var(--scale);
}

.characters-group-character-card-background-image-size {
	position: absolute;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.characters-group-character-card-background-alignment-top-left {
	position: absolute;
	top: 0;
	left: 0;
}

.characters-group-character-card-background-alignment-top {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.characters-group-character-card-background-alignment-top-right {
	position: absolute;
	top: 0;
	right: 0;
}

.characters-group-character-card-background-alignment-center-left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.characters-group-character-card-background-alignment-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.characters-group-character-card-background-alignment-center-right {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.characters-group-character-card-background-alignment-bottom-left {
	position: absolute;
	bottom: 0;
	left: 0;
}

.characters-group-character-card-background-alignment-bottom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.characters-group-character-card-background-alignment-bottom-right {
	position: absolute;
	bottom: 0;
	right: 0;
}

@media (max-width: 750px) {
	.characters-group-character-card-container {
		margin-left: -130px;
	}

	.characters-group-character-card,
	.characters-group-character-card-placeholder {
		border-width: 5px;

		width: calc(100vw - 24px - 10px - 24px);
		max-width: min(441px, calc((var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px) * (441 / 607)));
		height: calc((100vw - 24px - 10px - 24px) * (607 / 441));
		max-height: min(607px, calc(var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px));
		--characterCardHeight: min(
			calc((100vw - 24px - 10px - 24px) * (607 / 441)),
			min(607px, calc(var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px))
		);
	}

	.characters-group-character-card-top-container {
		padding: 8px 12px;
	}

	.characters-group-character-card-top-name {
		font-size: 22px;
		word-break: break-word;
		overflow-wrap: anywhere;
		max-width: 100%;
	}

	.characters-group-character-card-character-type {
		margin-top: 1px;
		margin-bottom: -1px;
		padding: 6px 10px;
		font-size: 12px;
	}

	.characters-group-character-card-summary-item-container {
		top: 47.5%;
		max-height: calc(100% - 47.5% + 1px);
	}

	.characters-group-character-card-summary-item-label {
		font-size: 11px;
	}

	.characters-group-character-card-summary-item-text {
		font-size: 11px;
	}
}
