.suggestions-message {
	padding: 12px 0;
	padding-right: 12px;
	padding-bottom: 6px;
	text-align: right;
	color: var(--colour-light-green);
}

.suggestions-message-label {
	display: inline-block;
	font-weight: 600;
	color: var(--text-colour-primary);
}

.suggestions-message-suggestion {
	display: inline-block;
	margin-left: 5px;
}
