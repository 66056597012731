.world-items-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 600px;
}

.world-items {
	display: flex;
	gap: 24px;
	justify-content: space-between;
	align-content: flex-start;
	flex-wrap: wrap;
	margin: 0 auto;
	width: 100%;
	max-width: 1000px;
}

@media (max-width: 1000px) {
	.world-items {
		margin-top: 24px;
		margin-bottom: 48px;
		justify-content: center;
	}
}

@media (max-width: 860px) {
	.world-items-container {
		align-items: flex-start;
		height: calc(100dvh - var(--navbar-mobile-height) - 82px);
		overflow-y: auto;
	}

	.world-items {
		margin-top: 24px;
		margin-bottom: 48px;
		justify-content: center;
		height: unset;
		max-width: 500px;
	}
}
