.verify {
	position: relative;
	width: 100%;
}

.verify-message {
	margin: 80px auto;
	margin-top: 8vh;
	padding: 24px;
	border-radius: 4px;

	width: calc(100% - 48px - 24px);
	max-width: 600px;

	text-align: center;
}

.verify-message-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.verify-message-icon {
	margin-bottom: 10px;
	font-size: 48px;
	color: var(--colour-green);
}

.verify-message-title {
	font-size: 24px;
	font-weight: 600;
}

.verify-message-loading-circle-container {
	margin-right: 48px;
}

.verify-message-error {
	margin: 16px 0;
	font-size: 14px;
	color: var(--colour-red);
}

.verify-message-error:last-child {
	margin-bottom: 0;
}
