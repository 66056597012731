.world-items-item-container {
	flex-shrink: 0;
	margin: 8px;

	cursor: pointer;
}

.world-items-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	color: var(--text-colour-secondary);
	transition: 0.4s;
}

.world-items-item-container:hover .world-items-item {
	scale: 1.05;
	color: var(--text-colour-primary);
}

.world-items-item-title {
	font-size: 18px;
}

.world-items-item-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
	border: 1px solid var(--text-colour-secondary);
	border-radius: 100%;

	width: 150px;
	height: 150px;

	font-size: 64px;
}

.world-items-item-container:hover .world-items-item .world-items-item-icon {
	border-color: var(--text-colour-primary);
}

.world-items-item-icon .fa-landmark {
	font-size: 56px;
}
