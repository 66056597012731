.lightbox-container {
	position: fixed;
	z-index: 100000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;

	width: 100%;
	height: 100%;

	touch-action: none;
}

.lightbox-container-hidden {
	display: none;
	pointer-events: none;
	opacity: 0;
}

.lightbox-loading-circle-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.lightbox-image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 2;

	width: 100%;
	height: 100%;

	transform-origin: 0px 0px;
	transform: scale(3) translate(0px, 0px);
	object-fit: contain;
	user-select: none;
}

.lightbox-image {
	position: relative;
	min-width: 24%;
	max-width: 100%;
	max-height: calc(100vh - 120px);
	width: calc(100% - 240px);
	cursor: grab;
	user-select: none;
	text-align: center;
}

.lightbox-image img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.lightbox-image-is-pixelated img {
	image-rendering: pixelated;
}

.lightbox-text-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap-reverse;
	position: absolute;
	bottom: 12px;
	left: 50%;
	transform: translateX(-50%);
	width: max-content;
	max-width: 100vw;
	pointer-events: none;
	user-select: none;
}

.lightbox-caption-container {
	padding: 3px 8px;
	border-radius: 4px;

	width: fit-content;

	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
	font-size: var(--font-size-p-m);
}

.lightbox-caption {
	width: max-content;
	max-width: calc(100vw - 16px - 16px);
	word-break: break-word;
	overflow-wrap: anywhere;
	pointer-events: none;
	user-select: none;
}

.lightbox-text-image-file-size {
	margin: 2px 0;
	margin-left: 6px;
	font-size: var(--font-size-p-s);
	opacity: 0.4;
	pointer-events: none;
	user-select: none;
}

.lightbox-switch-btn {
	position: absolute;
	z-index: 4;
	border: 0;

	--switchbtn-width: calc(16px * 2);
	min-width: var(--switchbtn-width);
	width: var(--switchbtn-width);
	min-height: var(--switchbtn-width);
	height: var(--switchbtn-width);

	font-size: 32px;
	background: #00000000;
	color: #ffffffbb;
	transition: color 0.2s;
	cursor: pointer;
}

.lightbox-switch-btn:hover {
	color: #ffffffee;
}

.lightbox-switch-btn svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50%;
}

.lightbox-switch-btn-inactive {
	display: none;
}

.lightbox-switch-btn-decrement {
	left: 12px;
}

.lightbox-switch-btn-increment {
	right: 12px;
}

.lightbox-close-btn {
	position: absolute;
	z-index: 4;
	top: 12px;
	right: 12px;
	border: 0;

	--closebtn-width: calc(16px * 2);
	min-width: var(--closebtn-width);
	width: var(--closebtn-width);
	min-height: var(--closebtn-width);
	height: var(--closebtn-width);

	font-size: 32px;
	background: #00000000;
	color: #ffffffaa;
	transition: color 0.2s;
	cursor: pointer;
}

.lightbox-close-btn:hover {
	color: #ffffffee;
}

.lightbox-close-btn svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50%;
}

.lightbox-background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;

	width: 100%;
	height: 100%;

	background: var(--colour-grey-one);
	opacity: 0.7;
}

@media (max-width: 750px) {
	.lightbox-image {
		width: calc(100% - 64px);
	}

	.lightbox-switch-btn-decrement {
		left: 0;
	}

	.lightbox-switch-btn-increment {
		right: 0;
	}

	.lightbox-close-btn {
		top: 10px;
		right: 0;
	}
}
