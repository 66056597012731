.lore-list-item {
	position: relative;
	flex-shrink: 0;
	flex-grow: 0;
	margin: 20px 12px;
	padding: 20px;
	box-sizing: border-box;
	width: 380px;
	height: 500px;
	cursor: pointer;
	transition: 0.2s;
}

.lore-list-item:hover {
	scale: 1.03;
}

.drag-drop-item-enable-drag-drop .lore-list-item {
	animation: wiggle-medium infinite 0.75s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even) .lore-list-item {
	animation-delay: 0.1s;
}

.lore-list-item-image-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	transition: 0.2s;
}

.lore-list-item-image {
	position: relative;
	display: block;
	content: "";
	margin-bottom: 4px;
	border-radius: 16px;

	width: 100%;
	height: 100%;
	overflow: hidden;

	background: var(--colour-grey-two);
	box-shadow: 0 0 20px transparent;
	transition: 0.2s;
}

.lore-list-item:hover .lore-list-item-image {
	box-shadow: 0 0 32px -12px var(--lore_itemColour);
}

.lore-list-item-image img {
	position: absolute;
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: contain;
	filter: brightness(90%);
}

.lore-list-item-image img:first-child {
	object-fit: cover;
	filter: blur(5px) brightness(80%);
	overflow: hidden;
}

.lore-list-item-name {
	font-size: 18px;
	font-weight: 600;
	transform: 0.2s;
}

@media (max-width: 750px) {
	.lore-list-item {
		width: 250px;
		height: 350px;
	}
}
