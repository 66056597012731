.unit-page-storyboard-editor-multi-tab-pane-content-media {
	display: flex;
	gap: 24px;
	flex-direction: column;
	padding: 16px;
	box-sizing: border-box;
	max-height: calc(var(--player_height) - 26px - 8px + 50px - 8px);
	overflow-y: auto;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section .openable-component-header {
	position: relative;
	justify-content: space-between;
	margin-bottom: 12px;
	user-select: none;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section .openable-component-header:before {
	position: absolute;
	bottom: -4px;
	left: 0;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-four);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list {
	display: flex;
	justify-content: space-evenly;
	gap: 6px;
	flex-wrap: wrap;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music {
	padding: 8px 0;
	gap: 8px;
	flex-direction: column;
}

/* Music Section */

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-music.openable-component-container-is-open .openable-component-content {
	display: flex;
	gap: 8px;
	flex-direction: column;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result {
	display: flex;
	gap: 12px;
	align-items: center;
	transition: 0.2s;
	color: var(--text-colour-secondary);
	cursor: pointer;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result:hover {
	color: var(--text-colour-primary);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-plus {
	margin: 0 4px;
	transition: 0.2s;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result:hover
	.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-plus {
	color: var(--colour-accent);
	scale: 1.25;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-artwork {
	width: 50px;
	height: 50px;
	transition: 0.2s;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-artwork img {
	border-radius: 5px;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result:hover
	.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-artwork {
	scale: 1.1;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-text {
	display: flex;
	gap: 4px;
	flex-direction: column;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-result-type {
	font-size: 14px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-page-buttons {
	display: flex;
	gap: 8px;
	justify-content: flex-end;
	align-items: center;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-page-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border: 0;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	background: var(--colour-grey-two);
	color: var(--text-colour-secondary);
	font-size: 18px;
	transition: 0.2s;
	cursor: pointer;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-page-btn-active {
	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-page-btn-active:hover {
	background: var(--colour-grey-four);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-page-btn:nth-child(1) svg {
	margin-left: -2px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-music-page-btn:nth-child(2) svg {
	margin-left: 2px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-images-add-container {
	display: flex;
	justify-content: right;
	width: 100%;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-images-add-container input {
	display: none;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-images-add-btn {
	cursor: pointer;
}
