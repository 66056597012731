.story-header-buttons-container {
	display: flex;
	gap: 12px;
	align-items: center;
	margin-left: auto;
	margin-right: 12px;
	padding-left: 12px;
}

.story-header-btn {
	margin: 0 6px;
}

.story-header-btn:first-child {
	margin-left: 0;
}

.story-header-btn:last-child {
	margin-right: 0;
}

.story-header-follow-btn {
	margin: 0 12px;
	border: 0;
	border-radius: 32px;

	width: 96px;
	height: 36px;

	background: #fff;
	color: #444;
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	transition: 0.2s;
}

.story-header-follow-btn:hover {
	background: var(--storyColour);
	color: #fff;
}

.story-header-is-authorized .story-header-follow-btn {
	margin-left: 12px;
}

.story-header-follow-btn-is-following {
	background: linear-gradient(0deg, var(--storyColour), var(--storyColourTint));
	color: #fff;
}

.story-header-follow-btn-is-following:hover {
	background: linear-gradient(0deg, var(--colour-red), var(--colour-red-tint));
	color: #fff;
}

.story-header-follow-btn-is-following .story-header-follow-btn-text-follow {
	display: none;
}

.story-header-follow-btn-text-following {
	display: none;
}

.story-header-follow-btn-is-following .story-header-follow-btn-text-following {
	display: block;
}

.story-header-follow-btn-is-following:hover .story-header-follow-btn-text-following {
	display: none;
}

.story-header-follow-btn-text-unfollow {
	display: none;
}

.story-header-follow-btn-is-following:hover .story-header-follow-btn-text-unfollow {
	display: block;
}

.story-header-follow-btn-placeholder {
	display: flex;
	align-items: center;
	margin: 0 12px;
	border: 0;
	border-radius: 32px;

	width: 96px;
	height: 36px;
}

@media (max-width: 750px) {
	.story-header-buttons-container {
		padding-left: 0;
	}

	.story-header-btn {
		margin: 2px 8px;
	}

	.story-header-follow-btn {
		margin: 0px;
		margin-right: 4px;
		font-size: 14px;
	}

	.story-header-is-authorized .story-header-follow-btn {
		margin-left: 0px;
	}

	.story-header-follow-btn-is-following:hover {
		background: var(--storyColour);
		color: #fff;
	}

	.story-header-follow-btn-is-following:hover .story-header-follow-btn-text-following {
		display: block;
	}

	.story-header-follow-btn-is-following:hover .story-header-follow-btn-text-unfollow {
		display: none;
	}
}
