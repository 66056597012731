.unit-page-subpage-soundtrack-playlist-selector-container.pop-up-container {
	transform: translateX(calc(100vw - 74px - 24px - 12px - 12px));
}

.unit-page-content-container-is-on-overview .unit-page-subpage-soundtrack-playlist-selector-container {
	display: none;
}

.unit-page-subpage-soundtrack-playlist-selector-subtitle {
	margin: 0;
	font-size: 14px;
}

.unit-page-subpage-soundtrack-playlist-selector-loading-circle-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 24px;
}

.unit-page-subpage-soundtrack-playlist-selector-playlist-items {
	margin-top: 12px;
	margin-left: -16px;
	padding-right: 12px;
	width: calc(100% + 16px);
	max-height: min(600px, calc(680px - 48px - 32px - 24px - 17px - 12px));
	overflow-y: overlay;
}

@media (max-width: 750px) {
	.unit-page-subpage-soundtrack-playlist-selector-container.pop-up-container {
		top: var(--unitPagePaddingTop);
		transform: translateX(100vw);
		max-height: calc(var(--vh) - var(--navbar-mobile-height) - (var(--unitPagePaddingTop)) - 12px);
	}

	.unit-page-subpage-soundtrack-playlist-selector-container.pop-up-container .pop-up-content-container {
		max-height: 100%;
	}

	.unit-page-subpage-soundtrack-playlist-selector-container.pop-up-container .pop-up-content-primary-container {
		margin-bottom: 12px;
	}

	.unit-page-subpage-soundtrack-playlist-selector-playlist-items {
		margin-top: 6px;
		max-height: min(
			600px,
			calc(600px - 24px - 32px - 24px - 17px - 12px - 30px),
			calc(var(--vh) - var(--navbar-mobile-height) - (var(--unitPagePaddingTop)) - 12px - 24px - 32px - 24px - 17px - 12px - 43px)
		);
	}
}
