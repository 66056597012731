.locations-hierarchy {
	--locationsHierarchyHeight: calc(var(--vh) - (2 * 10px));

	position: absolute;
	right: 0px;
	padding: 10px 16px;

	width: calc(100% - 8px - 46px - 2 * 16px);
	height: var(--locationsHierarchyHeight);
}

.locations-hierarchy::before {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	display: block;
	content: "";
	border: 1px solid var(--colour-grey-four);
	border-top: 0;
	border-bottom: 0;
	border-right: 0;

	width: calc(100% - 1px);
	height: 100%;

	background: var(--colour-grey-one);
}

.locations-hierarchy-is-displaying-location {
	--locationsHierarchyHeight: calc(var(--vh) * 0.28 - (1 * 10px));
	display: none;
	padding-bottom: 0;
}

.locations-hierarchy-mouse-over-map {
	user-select: none;
}

.locations-hierarchy-title {
	margin-bottom: 2px;
	font-size: 12px;
	font-weight: 600;
	color: var(--text-colour-tertiary);
}

@media (max-width: 750px) {
	.locations-hierarchy {
		--locationsHierarchyHeight: calc(var(--vh) - var(--navbar-mobile-height) - (2 * 10px));

		position: absolute;
		left: calc(40px + 8px);
		padding: 10px 16px;
		z-index: 2;

		width: calc(100vw - (2 * 16px) - 8px - 8px - 40px);
	}

	.locations-hierarchy-is-displaying-location {
		display: none;
	}
}
