.unit-page-subpage-settings-overview-background-image {
	display: block;
	margin-top: 6px;
	margin-bottom: 12px;
	border-radius: 4px 4px 0 0;
	width: 100%;
	height: calc(((100vw - 76px - 12px - 24px - 6px - 16px - 24px) / 2) * (9 / 16));
	background: var(--colour-grey-three);
}

.unit-page-subpage-settings-overview-background-image img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 1100px) {
	.unit-page-subpage-settings-overview-background-image {
		height: calc(((100vw - 76px - 12px - 24px - 6px - 16px)) * (9 / 16));
	}
}

@media (max-width: 750px) {
	.unit-page-subpage-settings-overview-background-image {
		height: calc(((100vw - 12px - 24px - 6px - 16px)) * (9 / 16));
	}
}
