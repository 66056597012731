.locations-location-path {
	display: flex;
	gap: 6px;
	padding: 8px;
	border-radius: 4px;
	font-size: var(--font-size-p-m);
	background: var(--colour-grey-two);
}

.locations-location-path:last-child {
	margin-bottom: 50%;
}

.locations-location-path-labels {
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex-shrink: 0;
}

.locations-location-path-label {
	padding: 8px 12px;
	border-radius: 4px;
	background: var(--colour-grey-two);
}

.locations-location-path-values {
	display: flex;
	gap: 6px;
	flex-direction: column;
	width: 100%;
}

.locations-location-path-value .toggle-input-container {
	padding: 0;
}

.locations-location-path-value .colour-picker {
	padding-left: 8px;
}
