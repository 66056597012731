.user-settings-ai {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.user-settings-ai-subtitle {
	margin: 0;
	margin-bottom: 4px;
}

.user-settings-ai .toggle-input-container {
	padding: 0 4px;
}
