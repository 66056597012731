.notes {
	margin: 0 auto;
	padding: 0 24px;
	max-width: 1900px;
}

@media (max-width: 750px) {
	.notes {
		padding: 12px;
		padding-top: 0;
	}
}
