.unit-page-storyboard-editor-multi-tab-pane-content-media {
	display: flex;
	gap: 24px;
	flex-direction: column;
	padding: 16px;
	box-sizing: border-box;
	max-height: calc(var(--player_height) - 26px - 8px);
	overflow-y: auto;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section .openable-component-header {
	position: relative;
	justify-content: space-between;
	margin-bottom: 12px;
	user-select: none;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section .openable-component-header:before {
	position: absolute;
	bottom: -4px;
	left: 0;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-four);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list {
	display: flex;
	gap: 6px;
	flex-wrap: wrap;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item {
	width: 150px;
	cursor: pointer;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-preview {
	position: relative;
	display: block;
	border-radius: 5px;
	width: 100%;
	height: 75px;
	background: var(--colour-grey-three);
	overflow: hidden;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-preview img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: contain;
	transform-origin: center;
	transition: 0.2s;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item:hover
	.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-preview
	img {
	scale: 1.2;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-name {
	margin-top: 2px;
	font-size: 13px;
	color: var(--text-colour-secondary);
	transition: 0.2s;
	user-select: none;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item:hover
	.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-name {
	color: var(--text-colour-primary);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-type {
	margin-top: 2px;
	font-size: 12px;
	color: var(--text-colour-secondary);
	transition: 0.2s;
	user-select: none;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item:hover
	.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-type {
	color: var(--text-colour-primary);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-delete-btn {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	opacity: 0;
	transition: 0.2s;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item:hover
	.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-delete-btn {
	opacity: 1;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-delete-btn:hover {
	color: var(--colour-red);
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-preview-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 28px;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-preview-icon-container::before {
	position: absolute;
	z-index: 0;
	display: block;
	content: "";
	width: 70px;
	height: 70px;
	background: radial-gradient(#00ff44 10%, var(--colour-grey-three) 55%);
	opacity: 0.7;
}

.unit-page-storyboard-editor-multi-tab-pane-content-media-section-list-item-preview-icon-container svg {
	position: relative;
	z-index: 1;
}
