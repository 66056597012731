.forgot-password {
	position: relative;
	width: 100%;
}

.forgot-password-form {
	margin: 80px auto;
	margin-top: 16vh;
	padding: 24px;
	border-radius: 4px;

	width: calc(100% - 48px - 24px);
	max-width: 600px;

	text-align: center;
}

.forgot-password-form-title {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 600;
	text-align: left;
}

.forgot-password-form-text-input-container {
	margin: 10px 0;
}

.forgot-password-form-submit-container {
	display: flex;
	align-items: center;
	justify-content: right;
	margin-top: 20px;
}

.forgot-password-form-forgot-password-success-icon {
	margin-bottom: 10px;
	font-size: 48px;
	color: var(--colour-green);
}

.forgot-password-form-forgot-password-success-text {
	font-size: 16px;
	font-weight: 600;
}
