.unit-page-subpage-plot-items-container {
	display: inline-block;

	width: calc(100% - 236px - 356px);

	vertical-align: top;
}

.unit-page-subpage-plot-items-container-all {
	width: calc(100% - 224px);
}

.unit-page-subpage-plot-items-name {
	font-size: 18px;
	font-weight: 600;
}

.unit-page-subpage-plot-items-list {
	width: 100%;
}

.unit-page-subpage-plot-items-name-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.unit-page-subpage-plot-items-name-container .unit-page-subpage-plot-navigation-bar-btn {
	display: none;
}

.unit-page-subpage-plot-items-add-plot-item-btn-container {
	display: flex;
	justify-content: center;
}

.unit-page-subpage-plot-items-add-plot-item-btn {
	display: flex;
	gap: 6px;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
	border: 0;
	border-radius: 16px;
	width: 150px;
	height: 30px;
	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
	cursor: pointer;
	transition: 0.2s;
}

.unit-page-subpage-plot-items-add-plot-item-btn:hover {
	background: var(--colour-grey-four);
}

.unit-page-subpage-plot-items-add-plot-item-btn svg {
	font-size: 15px;
}

.unit-page-subpage-plot-items-add-plot-item-btn span {
	margin-top: -1px;
}

@media (max-width: 1200px) {
	.unit-page-subpage-plot-items-container {
		padding-right: 0;
		width: 100%;
	}

	.unit-page-subpage-plot-items-name-container .unit-page-subpage-plot-navigation-bar-btn {
		display: block;
	}
}
