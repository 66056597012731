.unit-page-subpage-settings-face-image-container {
	margin-right: 6px;
	margin-bottom: 12px;
}

.unit-page-subpage-settings-face-image-image {
	display: block;
	margin-top: 6px;
	border-radius: 100%;
	width: 120px;
	height: 120px;

	background: var(--colour-grey-three);
}

.unit-page-subpage-settings-face-image-image img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.unit-page-subpage-settings-face-image-image .image-input-circular {
	width: 120px;
	height: 120px;
}

.unit-page-subpage-settings-face-image-container .editable-container-content {
	display: flex;
	justify-content: center;
}

@media (max-width: 1100px) {
	.unit-page-subpage-settings-face-image-container {
		margin-right: 0;
		margin-bottom: 12px;
	}
}
