.multi-line-text-input-container {
	display: inline-block;
	flex-direction: column;
	margin: 0;
	padding: 16px 16px;
	border: 3px solid rgba(0, 0, 0, 0);
	border-radius: 12px;

	width: calc(100% - 32px - 6px);

	background: var(--colour-grey-three);
	color: #777;
	font-family: var(--font-family);
	vertical-align: middle;
	cursor: text;
}

.multi-line-text-input-container-seamless {
	padding: 0;
	border: 0;
	width: 100%;
	background: transparent;
}

.multi-line-text-input-container-dark {
	background: var(--colour-grey-one);
}

.multi-line-text-input-container-empty {
	position: relative;
}

.multi-line-text-input-container-unsaved {
	border-color: var(--colour-grey-five);
	animation: multi-line-text-input-container-unsaved 2.25s infinite;
	outline: 0;
}

@keyframes multi-line-text-input-container-unsaved {
	0% {
		border-color: var(--colour-grey-one);
	}
	35% {
		border-color: var(--colour-grey-five);
	}
	65% {
		border-color: var(--colour-grey-five);
	}
	100% {
		border-color: var(--colour-grey-one);
	}
}

.multi-line-text-input {
	position: relative;
	min-height: var(--font-size-p-m-height);
}

.multi-line-text-input-container-empty .multi-line-text-input {
	height: var(--font-size-p-m-height);
}

.multi-line-text-input-container-seamless.multi-line-text-input-container-empty .multi-line-text-input {
	height: var(--font-size-p-m-height);
}

.multi-line-text-input-container-seamless .multi-line-text-input {
	min-height: var(--font-size-p-m-height);
	margin-bottom: -4px;
}

.multi-line-text-input-label {
	margin: 0;
	text-align: left;
	font-size: 12px;
	font-weight: 500;
	transition: 0.1s;
	color: #bbbbbb;
}

.multi-line-text-input-label span {
	width: fit-content;
}

.multi-line-text-input-container-empty .multi-line-text-input-label {
	padding-top: 5px;
	min-height: var(--font-size-p-m-height);
	font-size: var(--font-size-p-m);
	vertical-align: middle;
}

.multi-line-text-input-container-seamless .multi-line-text-input-label {
	position: absolute;
	top: 0;
	display: none;
	padding-top: 0;
}

.multi-line-text-input-container-seamless.multi-line-text-input-container-empty .multi-line-text-input-label {
	display: block;
}

.multi-line-text-input-label svg {
	margin-top: -3px;
	margin-right: 7px;
	vertical-align: middle;
}

.multi-line-text-input-container-empty .multi-line-text-input-label svg {
	margin-right: 18px;
	font-size: 18px;
}

.multi-line-text-input textarea {
	position: absolute;
	top: 0;
	z-index: 1;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 4px;

	width: 100%;
	height: 100%;
	resize: none;
	overflow: hidden;

	background: rgba(0, 0, 0, 0);
	color: var(--text-colour-primary);
	font-size: var(--font-size-p-m);
	font-family: var(--font-family);
	transition: border 0.1s;
}

.multi-line-text-input textarea:-webkit-autofill,
.multi-line-text-input textarea:-webkit-autofill:hover,
.multi-line-text-input textarea:-webkit-autofill:focus,
.multi-line-text-input textarea:-webkit-autofill:active {
	-webkit-text-fill-color: var(--text-colour-primary) !important;
	transition: background-color 99999s ease-in-out 0s;
}

.multi-line-text-input textarea:focus {
	outline: 0;
}

.multi-line-text-input-container-empty textarea {
	top: -1px;
	font-size: 18px;
}

.multi-line-text-input-container-light-text textarea {
	color: #fff;
}

.multi-line-text-input-container-focused {
	border-color: var(--colour-blue);
	outline: 0;
}

.multi-line-text-input-container-seamless textarea {
	font-size: var(--font-size-p-m);
	white-space: pre-wrap;
}

.multi-line-text-input-container-hide-value .multi-line-text-input {
	display: inline-block;
	width: calc(100% - 24px);
	vertical-align: middle;
}

.multi-line-text-input-height-element {
	z-index: -2;
	visibility: hidden;
	opacity: 0;
	transition: 0;
	pointer-events: none;
	white-space: pre-wrap;
	width: 100%;
	margin-bottom: 4px;
}

.multi-line-text-input-height-element p {
	margin: 0;
	min-height: 18px;
	word-wrap: break-word;
}

.multi-line-text-input-container-empty .multi-line-text-input-height-element {
	height: 32px;
}

.multi-line-text-input-container-seamless.multi-line-text-input-container-empty .multi-line-text-input-height-element {
	height: 18px;
}

.multi-line-text-input-container-seamless .multi-line-text-input-height-element {
	font-size: var(--font-size-p-m);
}

.multi-line-text-input-container-seamless .multi-line-text-input-height-element p {
	min-height: var(--font-size-p-m-height);
}

.multi-line-text-input-ai-tools-container {
	width: max(var(--multiLineTextInputWidth), 200px);
}

.multi-line-text-input-ai-tools-container .ai-tools-container-is-displaying {
	margin-top: 4px;
}

.multi-line-text-input-container-empty .multi-line-text-input-ai-tools-container {
	display: none;
}
