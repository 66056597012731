.editable-container {
	display: flex;
	position: relative;
	max-width: 100%;
}

.editable-container-content {
	display: inline-block;
	position: relative;
	width: 100%;
	height: 100%;
	vertical-align: top;
	scroll-behavior: smooth;
}

.editable-container-is-authorized .editable-container-content {
	padding-right: 6px;
	width: calc(100% - 26px - 6px);
}

.editable-container-media .editable-container-content {
	padding: 0;
	width: 100%;
}

.editable-container-is-authorized.editable-container-absolute-position-edit-btns .editable-container-content {
	padding-right: calc(6px + 26px);
}

.editable-container-buttons-container {
	position: relative;
	z-index: 100;
	display: inline-flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 4px;

	height: fit-content;

	vertical-align: top;
}

.editable-container-higher-edit-btns .editable-container-buttons-container {
	margin-top: -4px;
}

.editable-container-media .editable-container-buttons-container {
	position: absolute;
	top: 4px;
	right: 4px;
}

.editable-container-absolute-position-edit-btns .editable-container-buttons-container {
	position: absolute;
	top: 0;
	right: 0;
}

.editable-container-is-editing .editable-container-buttons-container {
	z-index: 120;
	background: var(--colour-grey-one);
}

.editable-container-edit-btn {
	position: relative;
	margin: 8px 0;
	padding: 0;
	border: 0;
	border-radius: 100%;

	width: 18px;
	height: 18px;

	background: transparent;
	color: var(--text-colour-primary);
	font-size: 18px;
	text-align: center;

	transition: color 0.2s;
	cursor: pointer;
}

.editable-container-is-editing .editable-container-edit-btn {
	color: var(--text-colour-primary);
}

.editable-container-edit-btn:hover {
	color: var(--colour-accent);
}

.editable-container-edit-btn:first-child {
	margin-top: 0;
}

.editable-container-edit-btn:last-child {
	margin-bottom: 0;
}

.editable-container-edit-btn svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.editable-container-light:not(.editable-container-is-editing) .editable-container-edit-btn {
	color: #fff;
}

.editable-container-edit-btn-close {
	font-size: 22px;
}

.editable-container-edit-btn-remove {
	font-size: 21px;
}

.editable-container-edit-btn-add {
	font-size: 20px;
}

.editable-container-edit-btn-reorder {
	font-size: 25px;
}

.editable-container-edit-btn-small-icon-container {
	position: absolute;
	z-index: 2;
	top: -4px;
	right: -6px;
	width: 15px;
	height: 15px;
	color: #fff;
}

.editable-container-edit-btn-small-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 15px;
	height: 15px;
	color: #fff;
	transition: background 0.2s;
	transition: color 0.2s;
}

.editable-container-edit-btn-small-icon-bg-1,
.editable-container-edit-btn-small-icon-bg-2 {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	width: 20px;
	height: 20px;
	color: var(--colour-grey-one);
}

.editable-container-edit-btn-small-icon-bg-1 {
	transform: translate(calc(-50% + 1px), calc(-50% + 1px));
}

.editable-container-edit-btn-small-icon-bg-2 {
	transform: translate(calc(-50% + 1px), calc(-50% - 3px));
}

.editable-container-edit-btn-small-icon svg,
.editable-container-edit-btn-small-icon-bg-1 svg,
.editable-container-edit-btn-small-icon-bg-2 svg {
	width: 100%;
}

.editable-container-edit-btn:hover .editable-container-edit-btn-small-icon {
	background: transparent;
	color: var(--colour-accent);
}

.editable-container-edit-btn-small-icon {
	position: absolute;
	z-index: 3;
	top: 2px;
	left: unset;
	right: 8px;
	width: fit-content;
	height: 60%;
}

.editable-container-edit-btn-small-icon svg:nth-child(1) {
	top: unset;
	left: unset;
	width: fit-content;
	height: 100%;
	transform: translate(0, 0);
}

.editable-container-edit-btn-small-icon svg:nth-child(2) {
	position: absolute;
	z-index: -1;
	top: -1px;
	left: 0;
	width: fit-content;
	height: 100%;
	transform: translate(0, 0);
	fill: #000;
	scale: 1.5;
	transform-origin: center;
}

@media (max-width: 750px) {
	.editable-container-edit-btn {
		padding: 16px 0;
	}

	.editable-container-edit-btn:first-child {
		padding-top: 0;
	}

	.editable-container-edit-btn:last-child {
		padding-bottom: 0;
	}
}
