.unit-page-subpage-physical-attribute-items {
	margin-bottom: 64px;
	width: 100%;
}

@media (max-width: 750px) {
	.unit-page-subpage-physical-attribute-items-container {
		display: inline-block;
		padding-right: 0;
		width: 100%;
	}

	.unit-page-subpage-physical-attribute-items {
		max-height: none;
	}
}
