.settings-blocked-users-message {
	margin-top: 6px;
	font-size: max(var(--font-size-p-m), 14px);
}

.settings-blocked-users-item {
	position: relative;
	display: flex;
	align-items: center;
	margin: 8px 0;
	padding: 8px 12px;
	border-radius: 6px;
	transition: 0.2s;
}

.settings-blocked-users-item::after {
	position: absolute;
	bottom: -5px;
	left: 0;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.settings-blocked-users-item:last-child:after {
	display: none;
}

.settings-blocked-users-item-names {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	font-size: max(var(--font-size-p-m), 14px);
}

.settings-blocked-users-item-names div:first-child {
	font-weight: 600;
}

.settings-blocked-users-item-unblock-btn {
	margin-left: auto;
	padding: 4px 8px;
	border: 0;
	border-radius: 30px;
	background: var(--colour-grey-five);
	color: var(--text-colour-primary);
	transition: 0.2s;
	cursor: pointer;
}

.settings-blocked-users-item-unblock-btn:hover {
	background: var(--colour-blue);
}
