.drag-drop-item {
	display: block;
	position: relative;
}

.drag-drop-item-enable-drag-drop {
	-webkit-user-drag: element;
	cursor: grab;
	touch-action: none;
}

.drag-drop-item * {
	-webkit-user-drag: none;
}

.drag-drop-item-enable-drag-drop .drag-drop-item-content {
	-webkit-user-drag: element;
}

.drag-drop-item-prevent-drag {
	-webkit-user-drag: none !important;
}

.drag-drop-item-inline {
	display: inline-block;
}

.drag-drop-item-dragging {
	opacity: 0;
}

.drag-drop-item-dragging-touch {
	animation: drag-drop-item-dragging-touch-pulse infinite 2.5s linear;
	opacity: 0.3;
}

@keyframes drag-drop-item-dragging-touch-pulse {
	0%,
	100% {
		opacity: 0.25;
	}
	50% {
		opacity: 0.5;
	}
}

.drag-drop-item-drag {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0);
}
