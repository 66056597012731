.btn-list-item,
.btn-list-item-loading {
	display: flex;
	margin: 5px 0;
	padding: 8px 16px;
	border: 0;
	border-radius: 20px;
	box-sizing: border-box;
	min-height: 35px;
}

.btn-list-item-list-has-background.btn-list-item,
.btn-list-item-list-has-background.btn-list-item-loading {
	background: var(--colour-grey-three);
}

.btn-list-item {
	transition: background 0.2s, border-color 0.2s;
}

.btn-list-item-clickable {
	cursor: pointer;
}

.btn-list-item-list-has-background.btn-list-item-clickable:hover {
	background: var(--colour-grey-four);
}

.btn-list-item-list-has-background.btn-list-item-active {
	background: var(--colour-grey-four);
	border-color: var(--colour-white);
}

.btn-list-item-list-has-background.btn-list-item-active:hover {
	background: var(--colour-grey-five);
	border-color: var(--colour-white);
}

.btn-list-item-value {
	font-size: 15px;
	word-break: break-word;
	overflow-wrap: anywhere;
	white-space: pre;
}

.btn-list-item-value input {
	font-size: 15px;
}

.btn-list-item-value-placeholder {
	display: block;
	content: "";
	border-radius: 12px;
	width: calc(100% - 10px);
	height: 19px;
}

.btn-list-item-size-s {
	padding: 5px 15px;
	border-width: 1px;
	border-radius: 8px;
	min-height: 27px;
}

.btn-list-item-size-s .btn-list-item-value {
	margin-top: 0;
}

.btn-list-item-size-s .btn-list-item-value,
.btn-list-item-size-s .btn-list-item-value input {
	font-size: 14px;
}

.btn-list-item-size-xs {
	padding: 3px 11px;
	border-width: 1px;
	border-radius: 8px;
	min-height: 22px;
}

.btn-list-item-size-xs .btn-list-item-value {
	margin-top: 0;
}

.btn-list-item-size-xs .btn-list-item-value,
.btn-list-item-size-xs .btn-list-item-value input {
	font-size: 12px;
}

.btn-list-item-btns-container {
	display: flex;
	flex-direction: column;
	margin: -4px 0;
	margin-top: -6px;
	margin-left: auto;
}

.btn-list-item-size-s .btn-list-item-btns-container {
	display: flex;
	flex-direction: column;
	margin: -5px 0;
	margin-top: -8px;
	margin-left: auto;
}

.btn-list-item-arrow-container {
	display: none;
	margin-top: 1px;
	margin-bottom: -1px;
	font-size: 18px;
	height: 18px;
}

@media (max-width: 750px) {
	.btn-list-item-loading,
	.btn-list-item-active {
		transition: 0 !important;
	}

	.btn-list-item {
		transition: 0.1s;
	}

	:not(.btn-list-item-active).btn-list-item-list-closed {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		border: 0;
		min-height: 0;
		height: 0;
		max-height: 0;
		opacity: 0;
		pointer-events: none;
	}

	.btn-list-item-active.btn-list-item-list-closed .btn-list-item-arrow-container {
		display: block;
	}
}
