.characters-relationship-info-home-relationship-types-container {
	margin-bottom: 12px;
}

.characters-relationship-info-home-relationship-types-title {
	font-size: 16px;
	font-weight: 600;
}

.characters-relationship-info-home-relationship-types {
	padding-right: 12px;
}

@media (max-height: 750px) {
	.characters-relationship-info-home-relationship-types {
		max-height: 250px;
	}
}
