.first-add-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 100%;
	cursor: pointer;
	background: #00000000;
	transition: 0.2s;
	animation: first-add-btn-fade-in 0.2s 1;
	animation-delay: 1s;
	opacity: 0;
	pointer-events: none;
	animation-fill-mode: forwards;
}

@keyframes first-add-btn-fade-in {
	0% {
		opacity: 0;
		pointer-events: none;
	}
	100% {
		opacity: 1;
		pointer-events: all;
	}
}

.first-add-btn:hover {
	background: #000000aa;
	opacity: 0.5;
}

.first-add-btn-plus {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 40px;
}

.first-add-btn-label {
	font-size: 16px;
	font-weight: 600;
}
