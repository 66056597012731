.unit-page-subpage-relationships {
	position: relative;
}

.unit-page-subpage-relationships-primary-buttons-container {
	display: none;
	margin-bottom: 8px;
	height: 24px;
}

.unit-page-subpage-relationships-primary-btn-toggle-is-displaying-info {
	margin-left: auto;
	margin-right: 16px;
}

.unit-page-subpage-relationships-content {
	position: relative;
	display: flex;
	gap: 48px;
	min-height: 80px;
}

@media (max-width: 1100px) {
	.unit-page-subpage-relationships-primary-buttons-container {
		display: flex;
	}
}
