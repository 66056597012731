.notes-list-item-container {
	position: relative;
	margin: 6px 0;
}

.notes-list-item-container:first-child {
	margin-top: 0;
}

.drag-drop-item-enable-drag-drop.notes-list-item-container {
	animation: wiggle-very-small infinite 0.7s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).notes-list-item-container {
	animation-delay: 0.1s;
}

.notes-list-item {
	padding: 10px 0;
	padding-bottom: 12px;
	border-radius: 4px;
}

.notes-list-item:after {
	position: absolute;
	bottom: -2px;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	content: "";
	border-radius: 4px;
	width: 100%;
	height: 1px;
	background: var(--colour-grey-five);
}

.notes-list-item-container:first-child .notes-list-item {
	padding-top: 0;
}

.notes-list-item-container:last-child .notes-list-item {
	padding-bottom: 8px;
}

.notes-list-item-container:last-child .notes-list-item::after {
	display: none;
}

.notes-list-item-has-single-line-text {
	padding-bottom: 8px;
}

.notes-list-item-content {
	display: inline-block;
	width: calc(100% - 32px);
	vertical-align: top;
}

.notes-list-item .multi-line-text-input {
	margin-bottom: -4px;
}

.notes-list-item-title {
	margin-bottom: 8px;
}

.notes-list-item-title,
.notes-list-item-title input {
	--text-input-font-size: 14px;
	font-size: 18px;
	font-weight: 600;
}

.notes-list-item .notes-list-item-title.text-input-container {
	margin-top: 3px;
}

.notes-list-item .notes-list-item-title.text-input-container-empty {
	margin-top: 0px;
}

.notes-list-item-text {
	min-height: 23px;
}

.notes-list-item-text .multi-line-text-input-label {
	font-size: 15px;
}

.notes-list-item-images-container,
.notes-list-item-image-item-images {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.notes-list-item-images-add-container {
	width: 100%;
	text-align: center;
}

.notes-list-item-image-item {
	position: relative;
	margin: 4px;
	width: calc((100% / 3) - 8px);
}

.drag-drop-item-enable-drag-drop .notes-list-item-image-item {
	-webkit-user-drag: element;
}

.notes-list-item-image-item img {
	width: 100%;
	max-height: max(18vw, 250px);
	object-fit: contain;
}

.notes-list-item-image-item-loading-circle-container {
	position: relative;
	min-height: 80px;
}

.notes-list-item-buttons-container {
	display: inline-flex;
	flex-direction: column;
}

.notes-list-item-add-image-input {
	display: none;
}

.notes-list-item-image-item-caption {
	padding: 3px 8px;
	border-radius: 4px;
	width: fit-content;
	max-width: calc(100% - 16px);
	word-break: break-word;
	overflow-wrap: anywhere;
	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
}

.notes-list-item-image-item-caption,
.notes-list-item-image-item-caption input {
	font-size: 14px;
	min-height: 20px;
}

.notes-list-item-image-item-btns-container {
	position: absolute;
	top: 2px;
	right: 2px;
}

@media (max-width: 1000px) {
	.notes-list .drag-drop-item {
		margin: 4px;
		width: calc(100% - 8px);
	}

	.notes-list-item-image-item {
		position: relative;
		margin: 4px;
		width: calc((100% / 2) - 8px) !important;
	}
}
