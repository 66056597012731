.unit-page-storyboard-player-volume-control-container {
	display: flex;
	gap: 8px;
	align-items: center;
	width: 100%;
	height: 100%;
	max-width: 150px;
}

.unit-page-storyboard-player-volume-control-button {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	padding: 0 8px;
	border: 0;

	min-width: 20px;
	width: 20px;
	min-height: 100%;
	height: 100%;
	box-sizing: content-box;

	font-size: 16px;
	background: transparent;
	color: #fff;
	cursor: pointer;
}

.unit-page-storyboard-player-volume-control-slider-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: calc(100% - 16px);
	height: 18px;
	cursor: pointer;
}

.unit-page-storyboard-player-volume-control-slider {
	display: block;
	border-radius: 24px;
	overflow: hidden;
	width: 100%;
	height: 4px;
	background: var(--colour-grey-two);
	transition: 0.1s;
}

.unit-page-storyboard-player-volume-control-slider-progress {
	display: block;
	width: var(--player_percent_volume);
	height: 100%;
	background: var(--colour-accent);
}
