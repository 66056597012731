.content-item {
	box-sizing: border-box;
}

.content-item-loading {
	opacity: 0;
}

.content-item-w-bg {
	margin: 8px;
	padding: 12px 16px;
	border-radius: 10px;
	background: var(--colour-grey-two);
}

.content-item-w-bg.content-item-size-s {
	padding: 4px 8px;
}

.content-item-w-bg.content-item-size-m {
	padding: 10px 20px;
}

.content-item-w-bg.content-item-size-l {
	padding: 12px 24px;
}

.content-item-margin-none {
	margin: 0;
}

.content-item-w-bg.content-item-bg-grey-1 {
	background: var(--colour-grey-one);
}

.content-item-w-bg.content-item-bg-grey-2 {
	background: var(--colour-grey-two);
}

.content-item-w-bg.content-item-bg-grey-3 {
	background: var(--colour-grey-three);
}

.content-item-w-bg.content-item-bg-grey-4 {
	background: var(--colour-grey-four);
}

.content-item-w-bg.content-item-bg-grey-5 {
	background: var(--colour-grey-five);
}
