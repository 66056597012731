.submit-btn {
	margin: 0;
	padding: 8px 0;
	border: 0;
	border-radius: 8px;

	background: var(--colour-accent);
	color: var(--text-colour-primary);
	transition: 0.2s;
	cursor: pointer;
}

.submit-btn:hover {
	background: var(--colour-accent-hover);
}

.submit-btn-text {
	margin-top: -1px;
	margin-bottom: 1px;
	padding-left: 20px;
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}

.submit-btn-icon {
	display: inline-block;
	margin: 0 10px;
	vertical-align: middle;
	font-size: 15px;
}
