.characters-character-types-container {
	margin-bottom: 24px;
	border-radius: 4px;
	vertical-align: top;
}

.characters-character-types-container-no-types {
	width: 100%;
}

.characters-character-types {
	display: flex;
	gap: 16px;
	align-items: center;
	width: 100%;
}

.characters-character-types-title {
	margin-top: -1px;
	margin-bottom: 24px;
	font-size: 32px;
	font-weight: 600;
	vertical-align: middle;
}

.characters-character-types-modify-buttons-container {
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.characters-character-types-modify-btn {
	margin: 0 8px;
}

.characters-character-types-modify-btn:last-child {
	margin-right: 0;
}

.characters-character-types-character-type-items-container {
	display: flex;
	gap: 8px;
	padding: 4px 24px;
	border-radius: 32px;

	width: max-content;
	height: fit-content;
	overflow-x: auto;

	background: var(--colour-grey-two);
}

.characters-character-types-character-type-items-container::-webkit-scrollbar {
	width: 6px;
	background: transparent;
}

.characters-character-types-character-type-items-container::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--colour-scroll-bar-thumb-background);
}

.characters-character-types-character-type-item-container .btn-list-item {
	margin: 0;
	background: transparent;
}

.characters-character-types-character-type-item-container .btn-list-item:hover {
	background: var(--colour-grey-three);
}

.characters-character-types-character-type-item-container .btn-list-item-active {
	position: relative;
	z-index: 2;
	color: #fff;
	background: transparent;
}

.characters-character-types-character-type-item-container .btn-list-item-active::before {
	position: absolute;
	z-index: -1;
	display: block;
	content: "";
	top: 0;
	left: 0;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, var(--charactersCharacterTypeActiveColourGradient1), var(--charactersCharacterTypeActiveColourGradient2));
	transition: 0.2s;
}

.characters-character-types-character-type-item-container .btn-list-item-active:hover::before {
	opacity: 0;
}

.characters-character-types-character-type-item-container .btn-list-item-active::after {
	position: absolute;
	z-index: -2;
	display: block;
	content: "";
	top: 0;
	left: 0;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	background: var(--charactersCharacterTypeActiveColourGradient1);
}

.drag-drop-item-enable-drag-drop.characters-character-types-character-type-item-container .btn-list-item {
	animation: wiggle-large infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).characters-character-types-character-type-item-container .btn-list-item {
	animation-delay: 0.1s;
}

.characters-character-types-add-first-character-type-container {
	width: 100%;
	max-width: 500px;
	height: 43px;
	max-height: 43px;
}

.characters-character-types-add-first-character-type-container .first-add-btn {
	gap: 12px;
	flex-direction: row;
	padding: 4px 24px;
	border-radius: 24px;

	width: calc(100% - 2 * 24px);
	height: 35px;
	max-height: 35px;

	background: var(--colour-grey-two);
}

.characters-character-types-add-first-character-type-container .first-add-btn:hover {
	background: var(--colour-grey-three);
}

.characters-character-types-add-first-character-type-container .first-add-btn-plus {
	font-size: 18px;
}

.characters-character-types-character-type-item-container .btn-list-item-value {
	white-space: pre;
	user-select: none;
}

@media (max-width: 950px) {
	.characters-character-types-container {
		width: 100%;
		min-height: 0;
	}

	.characters-character-types-primary {
		margin-top: 48px;
		margin-bottom: 6px;
	}

	.characters-character-types-primary-title {
		font-size: 22px;
	}

	.characters-character-types-primary-modify-btn {
		margin: 0 12px;
	}
}

@media (max-width: 750px) {
	.characters-character-types-character-type-items-container {
		flex-shrink: 1;
		z-index: 2;
		flex-direction: column;
		padding: 0;

		width: 100%;
		max-width: calc(100vw - 236px);
		height: 35px;
		overflow: visible;
	}

	.characters-character-types-character-type-item-container {
		border-radius: 24px;
		width: 100%;
		background: var(--colour-grey-two);
	}

	.characters-character-types-character-type-item-container .btn-list-item {
		margin: 0;
		border-radius: 24px;
	}

	.characters-character-types-character-type-item-container-active {
		order: -1;
	}

	.characters-character-types-character-type-item-container:not(.characters-character-types-character-type-item-container-active)
		.btn-list-item.btn-list-item-list-closed {
		display: none;
	}
}
