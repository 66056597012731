.characters-relationship-info-home-selected-character-relationship-item {
	display: flex;
	align-items: center;
	margin: 4px 0;
	padding: 6px 12px !important;
}

.characters-relationship-info-home-selected-character-relationship-item-content {
	display: flex;
	align-items: center;
	width: 100%;
}

.editable-container-is-editing .characters-relationship-info-home-selected-character-relationship-item-content {
	flex-direction: column;
	align-items: flex-start;
}

.characters-relationship-info-home-selected-character-relationship-item-second-character-name-container {
	flex-shrink: 1;
	margin-right: 12px;
}

.characters-relationship-info-home-selected-character-relationship-item-second-character-name-container {
	font-size: 14px;
	font-weight: 600;
}

.characters-relationship-info-home-selected-character-relationship-item-second-character-name-container .dropdown-value {
	text-align: left;
}

.characters-relationship-info-home-selected-character-relationship-item-second-character-name-container {
	position: relative;
	text-align: left;
}

.characters-relationship-info-home-selected-character-relationship-item-relationship-type-container {
	position: relative;
	margin-left: auto;
}

.editable-container-is-editing .characters-relationship-info-home-selected-character-relationship-item-relationship-type-container {
	margin-top: 4px;
	margin-left: 0;
}

.characters-relationship-info-home-selected-character-relationship-item-relationship-type-container .dropdown-value {
	text-align: right;
}

.characters-relationship-info-home-selected-character-relationship-item-relationship-type {
	border-radius: 4px;

	width: fit-content;

	text-align: right;
	font-size: 13px;
	white-space: pre-wrap;
	word-break: break-word;
	overflow-wrap: anywhere;
}

.characters-relationship-info-home-selected-character-relationship-item-second-character-name-container .dropdown-value-container,
.characters-relationship-info-home-selected-character-relationship-item-relationship-type-container .dropdown-value-container {
	background: var(--colour-grey-four);
}

.characters-relationship-info-home-selected-character-relationship-item-second-character-name-container .dropdown-value,
.characters-relationship-info-home-selected-character-relationship-item-relationship-type-container .dropdown-value {
	font-size: 13px;
}

.characters-relationship-info-home-selected-character-relationship-item-buttons-container {
	flex-shrink: 0;
	margin-left: auto;
	padding-left: 8px;
}

.characters-relationship-info-home-selected-character-relationship-item-buttons-container .icon-btn-container:last-child {
	margin-right: 0;
}
