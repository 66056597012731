.colour-picker {
	position: relative;
	z-index: 200;
	display: flex;
	align-items: center;
	--colourblock-width: 32px;
}

.colour-picker-is-editing {
	z-index: 300;
}

.colour-picker-is-showing-picker {
	z-index: 301;
}

.colour-picker-size-s {
	--colourblock-width: 24px;
}

.colour-picker-alignment-right {
	justify-content: right;
}

.colour-picker-colour-block {
	position: relative;
	z-index: 2;
	margin-right: 12px;
	border-radius: 4px;

	min-width: var(--colourblock-width);
	width: var(--colourblock-width);
	min-height: var(--colourblock-width);
	height: var(--colourblock-width);
}

.colour-picker-size-s .colour-picker-colour-block {
	margin-right: 8px;
}

.colour-picker-is-editing .colour-picker-colour-block {
	cursor: pointer;
}

.colour-picker-circular .colour-picker-colour-block {
	border-radius: 100%;
}

.colour-picker-sketch-picker {
	position: absolute;
	z-index: 3;
	left: -6px;
	bottom: calc(var(--colourblock-width) + 6px);
	height: fit-content;
	background: var(--colour-grey-four) !important;
}

.colour-picker-alignment-right .colour-picker-sketch-picker {
	left: auto;
	right: -6px;
}

.colour-picker-placement-bottom .colour-picker-sketch-picker {
	top: calc(var(--colourblock-width) + 6px);
}

.colour-picker-sketch-picker,
.colour-picker-sketch-picker input,
.colour-picker-sketch-picker label {
	color: var(--text-colour-primary) !important;
}

.colour-picker-sketch-picker input {
	box-shadow: none !important;
	background: var(--colour-grey-three) !important;
}

.colour-picker-background {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	display: block;
	content: "";
	width: 400vw;
	height: 400vh;
	background: transparent;
}

.colour-picker-size-s .colour-picker-colour-text {
	font-size: 14px;
}
