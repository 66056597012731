.substories-list-primary {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	width: 100%;
	min-height: 43px;
	z-index: 4;
}

.substories-list-primary-title {
	margin-top: -1px;
	margin-bottom: 1px;
	font-size: 26px;
	font-weight: 600;
	max-width: calc(100% - 115px);
}

.substories-list-primary-buttons-container {
	display: flex;
	align-items: center;
}

.substories-list-primary-buttons-container-authorized-to-edit {
	height: 40px;
}

.substories-list-primary-modify-btn {
	margin: 0 8px;
}

.substories-list-primary-modify-btn:last-child {
	margin-right: 0;
}

.substories-list-primary-modify-btns-container {
	display: flex;
	align-items: center;
}

@media (max-width: 950px) {
	.substories-list-primary {
		margin-bottom: 8px;
		padding-right: 12px;

		width: calc(100% - 4px);
		min-height: 40px;
		height: 40px;
	}

	.substories-list-primary-title {
		font-size: 24px;
	}

	.substories-list-primary-buttons-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.substories-list-primary-modify-btn {
		margin: 0 12px;
	}

	.substories-list-primary-modify-btn:last-child {
		margin-right: 8px;
	}
}
