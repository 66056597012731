.unit-page-subpages-container {
	position: relative;
	flex-shrink: 0;
	display: inline-block;
	padding-top: var(--unitPagePaddingTop);
	padding-left: 0;
	padding-right: 16px;

	width: calc(100vw - var(--navbar-width) - 16px);
	height: calc(100% - var(--unitPagePaddingTop));
	overflow: visible;

	vertical-align: top;
	transition: 0.6s;
}

.unit-page-subpages-container::before {
	position: absolute;
	top: calc(var(--unitPagePaddingTop) - 120px);
	left: 0;
	display: block;
	content: "";
	width: 100%;
	height: 126px;
	background: linear-gradient(180deg, transparent, var(--colour-grey-one));
	transition: 0.6s;
	pointer-events: none;
	user-select: none;
}

.unit-page-container-subpages-header-not-full-size .unit-page-subpages-container::before {
	top: calc(var(--unitPagePaddingTop) - 60px);
	height: 66px;
	background: linear-gradient(180deg, transparent, var(--colour-grey-one));
}

.unit-page-subpages {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.unit-page-subpage-container {
	--unitPageSubpageMaxHeight: calc(var(--vh) - var(--unitPagePaddingTop) - 60px - 8px);
	padding: 12px 8px;
	padding-right: 2px;
	box-sizing: border-box;
	width: 100%;
	min-height: calc(100% - 60px - 8px);
	max-height: calc(var(--unitPageSubpageMaxHeight) + 24px);
	overflow-y: scroll;
}

.unit-page-subpage-container::-webkit-scrollbar:horizontal {
	height: 0;
}

.unit-page-subpage {
	margin: 0 auto;
	max-width: calc(1200px - 48px);
}

@media (max-width: 750px) {
	.unit-page-subpages-container {
		padding-top: var(--unitPagePaddingTopVariable);
		padding-left: 6px;
		padding-right: 6px;

		width: calc(100vw - 12px);
		height: calc(100% - var(--unitPagePaddingTopVariable));
	}

	.unit-page-subpages-container:before {
		display: none !important;
	}

	.unit-page-subpages {
		flex-direction: column;
		gap: 8px;
		margin-top: 8px;
	}

	.unit-page-subpage-container {
		--unitPageSubpageMaxHeight: calc(
			var(--vh) - var(--navbar-mobile-height) - var(--unitPagePaddingTopVariable) - 32px - 4px - 8px - 16px - 16px
		);
		padding: 4px 8px;
		width: calc(100% - 16px);
		min-height: calc(100% - 8px - 36px - 16px);
	}
}
