.story-navigation-bar-container {
	position: relative;
	z-index: 2;
	margin: 0 auto;
	display: flex;
	gap: 8px;
	margin-top: 8px;
	margin-bottom: -6px;
	padding-bottom: 6px;
	border-radius: 4px;
	width: max-content;
	max-width: 100%;
	overflow-x: auto;
}

.story-navigation-bar-container::-webkit-scrollbar {
	height: 6px;
}

.story-navigation-bar-item {
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 0;
	border-radius: 4px;
	transition: 0.2s;
	min-width: 110px;
	width: 110px;
	user-select: none;
	cursor: pointer;
}

.story-navigation-bar-item:hover {
	color: var(--storyColour);
}

.story-navigation-bar-item-icon {
	font-size: 28px;
}

.story-navigation-bar-item-title {
	margin-top: 6px;
	font-size: 13px;
}

.story-loading {
	display: none;
}

@media (max-width: 750px) {
	.story-navigation-bar-container {
		margin: 0 4px;
		width: max-content;
		max-width: max-content;
	}
}
