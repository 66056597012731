.unit-page-subpage-psychology-big-five-traits-container {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-bottom: 64px;
	padding: 12px 0;
	width: 100%;
}

.unit-page-subpage-psychology-big-five-show-container {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 64px;
	margin-left: 8px;
	font-weight: 600;
	color: var(--text-colour-primary);
	transition: 0.2s;
	cursor: pointer;
	width: max-content;
}

.unit-page-subpage-psychology-big-five-title {
	margin-bottom: 8px;
	font-size: 18px;
	font-weight: 600;
}

.unit-page-subpage-psychology-big-five {
	margin-top: 12px;
}

.unit-page-subpage-psychology-big-five-show-container:hover {
	color: var(--text-colour-tertiary);
}

@media (max-width: 750px) {
	.unit-page-subpage-psychology-big-five-traits-container {
		max-height: none;
	}
}
