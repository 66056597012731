.unit-page-subpage-psychology-item-container {
	flex-shrink: 0;
	align-self: stretch;
	width: calc(50% - 4px);
}

.unit-page-subpage-psychology-big-five-visible .unit-page-subpage-psychology-item-container {
	width: 100%;
}

.unit-page-subpage-psychology-item-container .content-item {
	margin: 0;
	height: 100%;
}

.unit-page-subpage-psychology-item-container:last-child {
	padding-bottom: 8px;
}

.drag-drop-item-enable-drag-drop.unit-page-subpage-psychology-item-container {
	animation: wiggle-very-small infinite 0.7s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).unit-page-subpage-psychology-item-container {
	animation-delay: 0.1s;
}

.unit-page-subpage-psychology-item-container {
	position: relative;
}

.unit-page-subpage-psychology-item-container::after {
	position: absolute;
	bottom: 1px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.unit-page-subpage-psychology-item-container:last-child:after {
	display: none;
}

.unit-page-subpage-psychology-item {
	padding: 12px 0;
	padding-bottom: 13px;
}

.unit-page-subpage-psychology-item-content {
	display: inline-block;
	width: calc(100% - 32px);
	vertical-align: top;
}

.unit-page-subpage-psychology-item .multi-line-text-input {
	margin-bottom: -4px;
}

.unit-page-subpage-psychology-item-title {
	margin-bottom: 3px;
}

.unit-page-subpage-psychology-item-title,
.unit-page-subpage-psychology-item-title input {
	--text-input-font-size: 14px;
	font-size: 14px;
	font-weight: 600;
}

.unit-page-subpage-psychology-item-text {
	min-height: 23px;
}
