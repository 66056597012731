.user {
	margin: 0 auto;
	padding: 6px 48px;
	max-width: 1550px;
}

@media (max-width: 750px) {
	.user {
		padding: 6px;
	}
}
