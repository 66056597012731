.locations-menu-control-scheme {
	--controlSchemeWidth: 240px;
	position: absolute;
	z-index: 2;
	left: calc(-1 * (8px + var(--controlSchemeWidth)));
	bottom: 8px;
	padding: 8px 12px;
	border-radius: 8px;
	width: calc(var(--controlSchemeWidth) - (2 * 12px));
	user-select: none;
	pointer-events: none !important;
	opacity: 0;
	transition: opacity 0.2s;
}

.locations-menu-control-scheme > * {
	pointer-events: none !important;
}

.locations-menu-control-scheme::before {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	display: block;
	content: "";
	border-radius: 4px;

	width: 100%;
	height: 100%;

	background: var(--colour-grey-two);
	opacity: 1;
}

.locations-menu-control-scheme-is-displaying {
	opacity: 1;
	pointer-events: all !important;
}

.locations-menu-control-scheme-is-displaying > * {
	pointer-events: all !important;
}

.locations-menu-control-scheme-control {
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 6px 0;
}

.locations-menu-control-scheme-control-key,
.locations-menu-control-scheme-control-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 6px;
	border-radius: 2px;
	min-width: 18px;
	background: var(--colour-grey-three);
	font-size: var(--font-size-p-l);
	font-weight: 700;
}

.locations-menu-control-scheme-control-icon-l svg {
	scale: 1.5;
}

.locations-menu-control-scheme-control-label {
	font-size: var(--font-size-p-m);
}
