.home-stories-following-container {
	width: 100%;
}

.home-stories-following-primary {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	padding-right: 8px;
	box-sizing: border-box;
}

.home-stories-following-title {
	font-size: 32px;
	font-weight: 600;
}

.home-stories-following-loading-circle-container {
	position: relative;
	height: 480px;
}

.home-stories-following-list {
	display: flex;
}

.home-stories-following-list-item-container {
	flex-grow: 0;
	position: relative;
	width: fit-content;
}

.home-stories-following-list {
	display: flex;
	gap: 8px;
	padding-bottom: 8px;

	width: 100%;
}

@media (max-width: 750px) {
	.home-stories-following-title {
		margin-bottom: 12px;
		padding: 0 8px;
		box-sizing: border-box;
		font-size: 24px;
	}

	.home-stories-following-loading-circle-container {
		height: 431px;
	}

	.home-stories-following-list {
		flex-wrap: nowrap;
	}
}
