.unit-page-subpage-soundtrack-track {
	margin: 0;
	width: 100%;
}

.unit-page-subpage-soundtrack-track-song {
	display: inline-block;
	vertical-align: top;
	margin-right: 6px;
	padding: 7px 0;
	border: 0;
	border-radius: 4px;
	outline: 0;

	width: calc(50% - 6px);

	color: var(--text-colour-primary);
	text-align: left;
}

.unit-page-subpage-soundtrack-track:hover .unit-page-subpage-soundtrack-track-song {
	background: var(--colour-grey-three);
}

.unit-page-subpage-soundtrack-track-song:focus {
	background: var(--colour-grey-four) !important;
}

.unit-page-subpage-soundtrack-track-number {
	display: inline-block;
	vertical-align: middle;
	flex-shrink: 0;
	margin: 0 4px;
	margin-right: 12px;
	font-size: 14px;
	font-weight: 400;
	color: var(--text-colour-secondary);
	width: 27px;
	text-align: right;
}

.unit-page-subpage-soundtrack-track-artwork {
	--artworkWidth: 40px;

	display: inline-block;
	vertical-align: middle;
	margin: 0 12px;
	border-radius: 2px;

	min-width: var(--artworkWidth);
	width: var(--artworkWidth);
	min-height: var(--artworkWidth);
	height: var(--artworkWidth);
	background: var(--colour-grey-two);
}

.unit-page-subpage-soundtrack-track-artwork img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.unit-page-subpage-soundtrack-track-name-container {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 12px - 26px - 12px - 12px - 50px - 12px - 1px);
}

.unit-page-subpage-soundtrack-track-name {
	font-size: 13px;
}

.unit-page-subpage-soundtrack-track-sub-info {
	display: flex;
	gap: 0 4px;
	flex-wrap: wrap;
	align-items: center;
}

.unit-page-subpage-soundtrack-track-sub-info-divider {
	font-size: 8px;
}

.unit-page-subpage-soundtrack-track-artists {
	font-size: 11px;
	color: var(--text-colour-tertiary);
}

.unit-page-subpage-soundtrack-track-album {
	font-size: 11px;
	color: var(--text-colour-tertiary);
}

.unit-page-subpage-soundtrack-track-text-container {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-left: 6px;

	width: calc(50% - 6px);
	max-width: 750px;

	opacity: 0.75;
	transition: opacity 0.1s;
}

.unit-page-subpage-soundtrack-track:hover .unit-page-subpage-soundtrack-track-text-container {
	opacity: 1;
}

.unit-page-subpage-soundtrack-track-text-container .text-container {
	margin-top: 2px;
}

.unit-page-subpage-soundtrack-track-text-container .multi-line-text-input,
.unit-page-subpage-soundtrack-track-text-container textarea {
	min-height: 40px;
}

.unit-page-subpage-soundtrack-track-buttons-container {
	display: none;
	width: 32px;
}

@media (max-width: 750px) {
	.unit-page-subpage-soundtrack-track-song {
		margin-right: 0;
		width: 100%;
	}

	.unit-page-subpage-soundtrack-track-number {
		display: none;
	}

	.unit-page-subpage-soundtrack-track-name-container {
		display: inline-block;
		vertical-align: middle;
		width: calc(100% - 12px - 50px - 12px - 32px + 2px);
	}

	.unit-page-subpage-soundtrack-track-album {
		display: none;
	}

	.unit-page-subpage-soundtrack-track-text-container {
		display: none;
		margin-left: 0;
		padding: 4px 8px;
		padding-bottom: 12px;
		width: 100%;
	}

	.unit-page-subpage-soundtrack-track-text-container-is-displaying {
		display: block;
	}

	.unit-page-subpage-soundtrack-track-buttons-container {
		display: inline-block;
	}
}
