.story-header-main-info-title-container {
	width: fit-content;
}

.editable-container-is-editing.story-header-main-info-title-container {
	align-items: flex-end;
}

.story-header-main-info-title {
	width: 100%;
}

.story-header-main-info-title,
.story-header-main-info-title-container input {
	--text-input-font-size: 40px;
	font-size: 40px;
	font-weight: 600;
	color: var(--text-colour-primary);
}

@media (max-width: 750px) {
	.story-header-main-info-title {
		font-size: 24px;
		width: 100%;
	}

	.story-header-main-info-title,
	.story-header-main-info-title-container input {
		font-size: 28px;
	}
}
