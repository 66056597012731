.unit-page-journal-view-body-section {
	margin: 0 auto;
	padding-bottom: 30dvh;
	width: calc(100% - 24px);
	max-width: 750px;
}

.unit-page-journal-view-body-section-piece-title {
	margin-bottom: 8px;
	font-weight: 600;
}

.unit-page-journal-view-body-section-piece-title-style-h1 {
	margin-top: 100px;
	font-size: 36px;
}

.unit-page-journal-view-body-section-piece-title-style-h2 {
	margin-top: 8px;
	font-size: 28px;
}

.unit-page-journal-view-body-section-piece-title-style-h3 {
	margin-bottom: -4px;
	font-size: 24px;
}

.unit-page-journal-view-body-section-piece-text {
	margin-bottom: 38px;
	--font-family: "Newsreader", "Inter", sans-serif;
	font-family: var(--font-family);
	font-size: 20px;
	font-weight: 400;
	line-height: 1.6;
}

.unit-page-journal-view-body-section-piece-text > ul > li {
	margin-bottom: 12px;
}

.unit-page-journal-view-body-section-piece-text > ul > li:first-child {
	margin-top: -12px;
}

.unit-page-journal-view-body-section-piece-text p {
	text-align: justify;
}

@media (max-width: 750px) {
	.unit-page-journal-view-body-section-piece-text p {
		text-align: left;
	}
}
