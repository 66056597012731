.events-primary {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	width: 100%;
	min-height: 40px;
	z-index: 4;
	opacity: 0;
	animation: fade-in 0.75s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

.events-primary-title {
	margin-top: -1px;
	margin-bottom: 1px;
	font-size: 26px;
	font-weight: 600;
	max-width: calc(100% - 115px);
}

.events-primary-roman-numerals-container {
	display: flex;
	margin-left: 24px;
	margin-right: 64px;
	width: 100%;
	overflow: hidden;
}

.events-primary-roman-numerals {
	white-space: nowrap;
	overflow: hidden;
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	width: 400%;
	font-family: "Playfair Display", serif;
	opacity: 0.1;
	animation: events-primary-roman-numerals-slide 40s infinite linear;
}

@keyframes events-primary-roman-numerals-slide {
	0% {
		transform: translate3d(-50%, 0, 0);
	}
	100% {
		transform: translate3d(-25%, 0, 0);
	}
}

.events-primary-buttons-container {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.events-primary-buttons-container-authorized-to-edit {
	height: 40px;
}

.events-primary-modify-btn {
	margin: 0 8px;
}

.events-primary-modify-btn:last-child {
	margin-right: 0;
}

.events-primary-modify-btns-container {
	display: flex;
	align-items: center;
}

@media (max-width: 950px) {
	.events-primary {
		padding-right: 0;

		width: 100%;
		min-height: 40px;
		height: 40px;
	}

	.events-primary-title {
		font-size: 24px;
	}

	.events-primary-buttons-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 0;
	}

	.events-primary-modify-btn {
		margin: 0 12px;
	}

	.events-primary-modify-btn:last-child {
		margin-right: 8px;
	}

	.events-primary-roman-numerals-container {
		margin: 0;
	}

	.events-primary-roman-numerals {
		opacity: 0.3;
		animation-delay: -5s;
	}
}
