.routes {
	width: 100%;
	height: 100%;
}

.content-container {
	display: inline-block;
	width: calc(100vw - var(--navbar-width));
	height: 100%;
	vertical-align: top;
	overflow-y: overlay;
	overflow-x: hidden;
}

.content-container::-webkit-scrollbar {
	width: 6px;
	background: transparent;
}

.content-container::-webkit-scrollbar-track {
	margin: 4px 2px;
}

.content-container::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--colour-scroll-bar-thumb-background);
}

.content-container-unauthorized {
	display: block;
	width: 100vw;
	height: calc(100% - 68px);
}

@media (max-width: 750px) {
	.routes {
		display: flex;
		flex-direction: column;
	}

	.content-container {
		width: 100vw;
		height: calc(100% - var(--navbar-mobile-height));
	}
}
