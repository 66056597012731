.world-title {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	padding: 14px 12px;
	padding-bottom: calc(14px + 1px);
	border-radius: 4px;
	width: calc(100% - 24px);
}

.world-title::after {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	content: "";
	border-radius: 4px;
	width: 100%;
	height: 1px;
	background: var(--colour-grey-five);
}

.world-title-story-icon {
	display: inline-block;
	margin: 0 24px;
	margin-left: 0;
	border-radius: 100%;
	min-width: 65px;
	width: 65px;
	min-height: 65px;
	height: 65px;
	vertical-align: middle;
}

.world-title-story-icon-placeholder {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	background: var(--colour-grey-three);
}

.world-title-story-icon img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.world-title-story-title {
	margin-top: -1px;
	margin-bottom: 2px;
	font-size: 34px;
	font-weight: 600;
}

.world-title-divider {
	margin: 0 16px;
	margin-top: -1px;
	font-size: 24px;
}

.world-title-world-label {
	font-size: 22px;
	font-weight: 400;
}

.world-title-notes-btn-container {
	margin-left: auto;
}

@media (max-width: 750px) {
	.world-title {
		margin-bottom: 12px;
		padding: 14px 6px;
		padding-bottom: calc(14px + 2px);
		width: calc(100% - 12px);
	}

	.world-title-story-icon {
		display: none;
	}

	.world-title-story-title {
		margin: 0;
		font-size: 22px;
	}

	.world-title-divider {
		margin: 0 8px;
		font-size: 18px;
	}

	.world-title-world-label {
		font-size: 18px;
	}
}
