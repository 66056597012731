.unit-page-subpage-physical-outfit-items-title {
	margin-bottom: 16px;
	font-size: 32px;
	font-weight: 600;
}

.unit-page-subpage-physical-outfit-items {
	width: 100%;
}

@media (max-width: 750px) {
	.unit-page-subpage-physical-outfit-items-container {
		display: inline-block;
		padding-right: 0;
		width: 100%;
	}

	.unit-page-subpage-physical-outfit-items {
		max-height: none;
	}
}
