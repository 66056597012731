.unit-page-subpage-gallery-items-container .drag-drop-item {
	margin: 4px;
	width: calc((100% / 4) - 8px);
}

.drag-drop-item .unit-page-subpage-gallery-item {
	width: 100%;
}

.drag-drop-item-enable-drag-drop .unit-page-subpage-gallery-item {
	animation: wiggle-large infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even) .unit-page-subpage-gallery-item {
	animation-delay: 0.1s;
}

.unit-page-subpage-gallery-item {
	position: relative;
	margin: 4px;
	width: calc((100% / 4) - 8px);
}

.unit-page-subpage-gallery-item img {
	width: 100%;
	max-height: max(18vw, 250px);
	object-fit: contain;
}

.unit-page-subpage-gallery-item-placeholder {
	position: relative;
	min-height: 240px;
}

.unit-page-subpage-gallery-item-caption {
	padding: 3px 8px;
	border-radius: 4px;
	width: fit-content;
	max-width: calc(100% - 16px);
	word-break: break-word;
	overflow-wrap: anywhere;
	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
}

.unit-page-subpage-gallery-item-caption,
.unit-page-subpage-gallery-item-caption input {
	font-size: 14px;
	--text-input-font-size: 14px;
	min-height: 20px;
}

.unit-page-subpage-gallery-item-btns-container {
	position: absolute;
	top: 2px;
	right: 2px;
}

@media (max-width: 1000px) {
	.unit-page-subpage-gallery-item {
		position: relative;
		margin: 4px;
		width: calc((100% / 2) - 8px);
	}

	.unit-page-subpage-gallery-items-container .drag-drop-item {
		margin: 4px;
		width: calc((100% / 2) - 8px);
	}
}
