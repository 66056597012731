.user-header {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: -117px;
	padding: 12px 0;
	padding-bottom: calc(12px + 1px + 12px);
	border-radius: 4px;
}

.user-header-background {
	position: absolute;
	z-index: -1;
	top: -24px;
	left: 50%;
	transform: translate(-50%);
	display: block;
	content: "";
	width: 100%;
	height: calc(117px + 24px + 1px);
	background: linear-gradient(180deg, transparent, var(--colour-grey-one));
	pointer-events: none;
}

@media (max-width: 750px) {
	.user-header {
		margin-top: 0;
		padding: 6px 0;
		padding-bottom: calc(6px + 1px);
	}

	.user-header-background {
		top: calc(-24px + -37px);
		width: 100vw;
	}
}
