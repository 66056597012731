.characters-character-type-name-container {
	box-sizing: border-box;
	width: 100%;
}

.characters-character-type-name-container .editable-container-content {
	margin-top: 5.5px;
}

.characters-character-type-primary-name {
	margin-top: -1px;
	margin-bottom: 1px;
	width: 100%;
}

.characters-character-type-primary-name,
.characters-character-type-name-container input {
	font-size: 22px;
	font-weight: 600;
}

.characters-character-type-name-container .text-input-container {
	margin-top: -1px;
}

@media (max-width: 950px) {
	.characters-character-type-primary-name,
	.characters-character-type-name-container input {
		font-size: 22px;
		font-weight: 600;
	}
}
