.locations-credits-container {
	position: fixed;
	z-index: 2;
	top: 8px;
	right: 8px;

	width: fit-content;
	background: transparent;
}

.locations-credits-title {
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 0 6px;
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: 600;
	color: #aaa;
	cursor: pointer;
	user-select: none;
}

.locations-credits-container-is-displaying-credits .locations-credits-title svg {
	transform: rotate(90deg);
}

.locations-credits {
	display: none;
	max-height: calc(var(--vh) - 40px);
	overflow-y: auto;
}

.locations-credits-container-is-displaying-credits .locations-credits {
	display: flex;
	flex-direction: column;
}

.locations-credit {
	position: relative;
	display: flex;

	margin: 6px 0;
	padding: 8px 12px;

	min-width: fit-content;
}

.locations-credit::before {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	display: block;
	content: "";
	border-radius: 8px;

	width: 100%;
	height: 100%;

	background: var(--colour-grey-two);
	opacity: 0.8;
}

.locations-credit-link {
	color: var(--colour-blue-two);
	text-decoration: underline;
	transition: 0.2s;
	cursor: pointer;
	width: max-content;
	max-width: calc(50vw - (20px / 2));
}

.locations-credit-link:hover {
	color: var(--colour-blue-three);
}

@media (max-width: 768px) {
	.locations-credits-title {
		transform: rotate(90deg) translate(24px, -28px);
	}

	.locations-credits-container-is-displaying-credits .locations-credits-title {
		transform: rotate(0deg) translate(0, 0);
	}

	.locations-credits {
		display: none;
		max-height: calc(var(--vh) - var(--navbar-mobile-height) - 40px);
		overflow-y: auto;
	}
}
