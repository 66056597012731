.unit-page-storyboard-editor-top-bar {
	flex-shrink: 0;
	display: flex;
	gap: 12px;
	align-items: center;
	height: 64px;
}

.unit-page-storyboard-editor-top-bar-back-btn {
	display: flex;
	gap: 8px;
	align-items: center;
	border: 0;
	background: transparent;
	color: var(--text-colour-secondary);
	transition: 0.2s;
	cursor: pointer;
}

.unit-page-storyboard-editor-top-bar-back-btn:hover {
	color: var(--text-colour-primary);
}

.unit-page-storyboard-editor-top-bar-back-btn svg {
	margin-top: 2px;
}

.unit-page-storyboard-editor-top-bar-story-icon {
	margin-left: 24px;
	border-radius: 100%;
	width: 32px;
	height: 32px;
}

.unit-page-storyboard-editor-top-bar-story-icon img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.unit-page-storyboard-editor-top-bar-plot-title {
	font-size: 14px;
}

.unit-page-storyboard-editor-top-bar-save-btn-container {
	margin-left: auto;
}

.unit-page-storyboard-editor-top-bar-save-btn {
	position: relative;
	display: inline-block;
	border: 0;
	border-radius: 32px;
	background: linear-gradient(135deg, #ff88ff, #8844ff, #4466ff, #ff2222);
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	transition: 0.2s;
}

.unit-page-storyboard-editor-top-bar-save-btn:hover {
	scale: 1.1;
}

.unit-page-storyboard-editor-top-bar-save-btn::before {
	position: absolute;
	top: 2px;
	bottom: 2px;
	left: 2px;
	right: 2px;
	display: block;
	content: "";
	background: var(--colour-grey-one);
	border-radius: inherit;
}

.unit-page-storyboard-editor-top-bar-save-btn div {
	position: relative;
	display: inline-block;
	padding: 10px 30px;
	border: 0;
	border-radius: 32px;
	background: linear-gradient(135deg, #ff88ff, #ff88ff, #ff88ff, #ff88ff, #8844ff, #4466ff, #ff2222, #ff2222, #ff2222, #ff2222);
	background-clip: border-box;
	background-clip: text;
	color: transparent;
	font-size: 14px;
	font-weight: 700;
}
