.slider-input-container {
	position: relative;
	margin: 0;
	width: 100%;
}

.slider {
	position: relative;
}

.slider-input {
	position: relative;
	z-index: 10;
	-webkit-appearance: none;
	margin: 0;
	width: 100%;
	min-height: 12px;
	height: 100%;
	background: transparent;
}

.slider-input-container-enabled .slider-input {
	cursor: pointer;
}

.slider-input-container-has-thumb .slider-input {
	position: relative;
	top: 2px;
	height: 24px;
}

.slider-input::-webkit-slider-thumb {
	-webkit-appearance: none;
	margin-top: 0px;
	border: 0;
	border-radius: 4px;

	height: 6px;
	width: 0px;

	background: transparent;
}

.slider-input:focus {
	outline: none;
}

.slider-input::-webkit-slider-runnable-track {
	border: 0;
	border-radius: 4px;
	width: 100%;
	height: 6px;

	background: var(--colour-blue);
	background: var(--colour-grey-five);
	background: transparent;
}

.slider-input-container-enabled .slider-input::-webkit-slider-runnable-track {
	cursor: pointer;
}

.slider-input-container-has-thumb .slider-input-thumb {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	display: block;
	content: "";
	border-radius: 4px;

	width: 6px;
	height: 24px;

	background: #fff;
	pointer-events: none;
	user-select: none;
}

.slider-input-container-enabled .slider-input-thumb {
	cursor: pointer;
}

.slider-input-label-container {
	position: relative;
	width: 100%;
	pointer-events: none;
	user-select: none;
}

.slider-input-label-container-empty {
	position: absolute;
	pointer-events: none;
	user-select: none;
	opacity: 0;
}

.slider-input-label {
	position: relative;
	margin-top: 2px;
	padding: 4px 8px;
	border-radius: 4px;
	width: fit-content;
	background: var(--colour-grey-three);
	pointer-events: none;
	user-select: none;
	font-size: var(--font-size-p-m);
}

.slider-input-progress {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

	display: block;
	content: "";
	border-radius: 4px;

	height: 6px;
	width: 0%;

	background: var(--colour-blue);
}

.slider-input-background {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

	display: block;
	content: "";
	border-radius: 4px;

	height: 6px;
	width: 100%;

	background: var(--colour-grey-four);
}

.slider-input-container-percentage-colour-exceptionally-high .slider-input-progress {
	background: #0077ff;
}

.slider-input-container-percentage-colour-very-high .slider-input-progress {
	background: #00d1ff;
}

.slider-input-container-percentage-colour-high .slider-input-progress {
	background: #00ff47;
}

.slider-input-container-percentage-colour-moderately-high .slider-input-progress {
	background: #60df7b;
}

.slider-input-container-percentage-colour-typical .slider-input-progress {
	background: #ffffff;
}

.slider-input-container-percentage-colour-moderately-low .slider-input-progress {
	background: #dbe734;
}

.slider-input-container-percentage-colour-low .slider-input-progress {
	background: #ffaa00;
}

.slider-input-container-percentage-colour-very-low .slider-input-progress {
	background: #ff6600;
}

.slider-input-container-percentage-colour-exceptionally-low .slider-input-progress {
	background: #ff0000;
}

@media (max-width: 750px) {
	.slider-input-container {
		position: relative;
		margin: 0;
		width: 100%;
	}

	.slider-input-progress,
	.slider-input-background {
		height: 9px;
	}
}
