.characters-groups-container {
	flex-shrink: 0;
	border-radius: 4px;
	width: 100%;
	margin-bottom: 16px;
	vertical-align: top;
}

.characters-groups-container-no-groups {
	width: 100%;
}

.characters-groups {
	display: flex;
	gap: 16px;
	align-items: center;
}

.characters-groups-primary {
	display: flex;
	gap: 16px;
}

.characters-groups-modify-buttons-container {
	display: flex;
	align-items: center;
}

.characters-groups-modify-btn {
	margin: 0 8px;
}

.characters-groups-modify-btn:last-child {
	margin-right: 0;
}

.characters-groups-add-first-group-container {
	width: 100%;
	max-width: 500px;
	height: 43px;
	max-height: 43px;
}

.characters-groups-add-first-group-container .first-add-btn {
	gap: 12px;
	flex-direction: row;
	padding: 4px 24px;
	border-radius: 24px;

	width: calc(100% - 2 * 24px);
	height: 35px;
	max-height: 35px;

	background: var(--colour-grey-two);
}

.characters-groups-add-first-group-container .first-add-btn:hover {
	background: var(--colour-grey-three);
}

.characters-groups-add-first-group-container .first-add-btn-plus {
	font-size: 18px;
}

.characters-groups-add-first-group-container .btn-list-item-value {
	white-space: pre;
	user-select: none;
}

.characters-groups-group-items-container {
	display: flex;
	gap: 8px;
	padding: 4px 24px;
	border-radius: 32px;

	width: max-content;
	height: fit-content;
	overflow-x: auto;

	background: var(--colour-grey-two);
}

.characters-groups-group-items-container::-webkit-scrollbar {
	height: 6px;
	background: transparent;
}

.characters-groups-group-items-container::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--colour-scroll-bar-thumb-background);
}

.characters-groups-group-items-container .btn-list-item {
	margin: 0;
	background: transparent;
}

.characters-groups-group-items-container .btn-list-item:hover {
	background: var(--colour-grey-three);
}

.characters-groups-group-item-container {
	height: fit-content;
	user-select: none;
}

.characters-groups-group-item-container .btn-list-item-list-loading {
	min-width: 100px;
}

.characters-groups-group-item-container .btn-list-item-active {
	position: relative;
	z-index: 1;
	background: transparent;
	color: #fff;
}

.characters-groups-group-item-container .btn-list-item-active:hover {
	color: #fff;
}

.characters-groups-group-item-container .btn-list-item-active::before {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	display: block;
	border-radius: inherit;
	content: "";
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, var(--characters-groups-active-group-colour), var(--characters-groups-active-group-colour-tint));
	transition: 0.2s;
}

.characters-groups-group-item-container .btn-list-item-active:hover::before {
	opacity: 0.3;
}

.characters-groups-group-item-container .btn-list-item-active::after {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -2;
	display: block;
	border-radius: inherit;
	content: "";
	width: 100%;
	height: 100%;
	background: var(--characters-groups-active-group-colour-tint);
}

.drag-drop-item-enable-drag-drop.characters-groups-group-item-container .btn-list-item {
	animation: wiggle-large infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).characters-groups-group-item-container .btn-list-item {
	animation-delay: 0.1s;
}

.characters-groups-group-item-placeholder {
	display: block;
	content: "";
	margin: 6px 0;
	border: 0;
	border-radius: 22px;

	width: 100%;
	height: 39px;

	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
	font-size: 15px;
	text-align: left;
	transition: 0.2s;
	cursor: pointer;
}

.characters-groups-group-item-placeholder:nth-child(1) {
	opacity: 0.6;
}

.characters-groups-group-item-placeholder:nth-child(2) {
	opacity: 0.4;
}

.characters-groups-group-item-placeholder:nth-child(3) {
	opacity: 0.25;
}

.characters-group-primary {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	margin-left: auto;
	min-height: 40px;
}

.characters-group-primary-title {
	margin-top: -1px;
	margin-bottom: 1px;
	margin-left: 6px;
	font-size: 22px;
	font-weight: 600;
	max-width: calc(100% - 115px);
}

.characters-group-primary-buttons-container {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	height: 40px;
}

.characters-group-primary-open-group-btn {
	flex-shrink: 0;
	position: relative;
	z-index: 2;
	display: inline-block;
	margin: 4px;
	margin-left: 0;
	margin-right: 8px;
	padding: 0 16px;
	border: 0;
	border-radius: 32px;
	height: 40px;
	background: transparent;
	color: #fff;
	font-size: 13px;
	transition: 0.2s;
	cursor: pointer;
}

.characters-group-primary-open-group-btn::before {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	display: block;
	border-radius: inherit;
	content: "";
	width: 100%;
	height: 100%;
	background: var(--characters-group-active-group-colour);
	background: linear-gradient(0deg, var(--characters-groups-active-group-colour), var(--characters-groups-active-group-colour-tint));
	transition: 0.2s;
}

.characters-group-primary-open-group-btn:hover::before {
	opacity: 0.3;
}

.characters-group-primary-open-group-btn::after {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -2;
	display: block;
	border-radius: inherit;
	content: "";
	width: 100%;
	height: 100%;
	background: var(--characters-groups-active-group-colour-tint);
}

.characters-group-primary-modify-btn {
	margin: 0 8px;
}

.characters-group-primary-modify-btn:last-child {
	margin-right: 0;
}

.characters-group-primary-modify-btns-container {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.characters-groups-container-no-groups .characters-group-primary {
	display: none;
}

@media (max-width: 950px) {
	.characters-groups-primary {
		flex-grow: 1;
		flex-direction: column-reverse;
		align-items: center;
	}

	.characters-groups-container {
		width: 100%;
		min-height: 92px;
	}

	.characters-groups-primary-title {
		font-size: 24px;
	}

	.characters-groups-primary-modify-btn {
		margin: 0 12px;
	}

	.characters-groups-group-items-container-placeholder .btn-list-item {
		display: none;
	}

	.characters-groups-group-items-container-placeholder .btn-list-item:first-child {
		display: block;
	}

	.characters-groups-add-first-group-container {
		height: 150px;
	}

	.characters-group-primary {
		margin-left: unset;
		min-height: 96px;
	}

	.characters-group-primary-title {
		font-size: 24px;
	}

	.characters-group-primary-buttons-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.characters-group-primary-buttons-container-authorized-to-edit {
		height: 96px;
	}

	.characters-group-primary-open-group-btn {
		margin: 4px;
	}

	.characters-group-primary-buttons-container-authorized-to-edit .characters-group-primary-open-group-btn {
		margin-bottom: 16px;
	}

	.characters-group-primary-modify-btn {
		margin: 0 12px;
	}

	.characters-group-primary-modify-btn:last-child {
		margin-right: 8px;
	}
}

@media (max-width: 750px) {
	.characters-groups-container {
		margin-bottom: 0;
	}

	.characters-groups-group-items-container {
		flex-shrink: 1;
		z-index: 2;
		flex-direction: column;
		padding: 0;

		width: 100%;
		height: 35px;
		overflow: visible;
	}

	.characters-groups-group-item-container {
		border-radius: 24px;
		width: 100%;
		background: var(--colour-grey-two);
	}

	.characters-groups-group-item-container .btn-list-item {
		border-radius: 24px;
	}

	.characters-groups-group-item-container-active {
		order: -1;
	}

	.characters-groups-group-item-container:not(.characters-groups-group-item-container-active) .btn-list-item.btn-list-item-list-closed {
		display: none;
	}

	.characters-groups-group-item-container .btn-list-item.btn-list-item-active.btn-list-item-clickable.btn-list-item-list-closed {
		gap: 4px;
		width: 100%;
	}

	.characters-groups:not(.characters-groups-is-authorized) .characters-groups-group-items-container {
		max-width: unset;
	}
}
