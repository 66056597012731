.navigation-bar {
	position: relative;
	z-index: 3;
	display: inline-block;
	padding: 24px 12px;
	width: calc(var(--navbar-width) - (12px * 2));
	height: calc(100% - 48px);
	background: var(--colour-grey-one);
	--navigationbarbtn-colour: var(--text-colour-secondary);
}

.navigation-bar-btn-container {
	position: relative;
	margin: 16px 0;
}

.navigation-bar-btn-container:first-child {
	margin-top: 8px;
}

.navigation-bar-btn-container:last-child {
	margin-bottom: 8px;
}

.navigation-bar-btn-container:nth-child(3) {
	margin-top: 64px;
}

.navigation-bar-btn {
	position: relative;
	display: inline-block;
	margin: 0 auto;
	padding: 0;
	border: 2px solid var(--colour-grey-two);
	border: 0;
	border-radius: 16px;

	width: 44px;
	height: 44px;

	background: #00000000;
	color: var(--navigationbarbtn-colour);
	font-size: 22px;
	text-align: center;
	transition: color, background 0.2s;
	cursor: pointer;
}

.navigation-bar-btn svg {
	transition: color 0.2s;
}

.navigation-bar-btn-container:hover .navigation-bar-btn,
.navigation-bar-btn-container:hover .navigation-bar-btn:hover img {
	background: var(--colour-grey-three);
}

.navigation-bar-btn::before {
	display: block;
	content: "";
	position: absolute;
	top: 50%;
	left: -12px;
	transform: translateY(-50%);
	width: 3px;
	height: 28px;
	transition: background 0.2s;
}

.navigation-bar-btn-container:hover .navigation-bar-btn::before {
	background: var(--colour-grey-three);
}

.navigation-bar-btn-active,
.navigation-bar-btn-active img {
	background: var(--colour-grey-two);
}

.navigation-bar-btn-container:hover .navigation-bar-btn-active,
.navigation-bar-btn-container:hover .navigation-bar-btn-active img {
	color: var(--navigationbarbtn-colour);
}

.navigation-bar-btn-active::before,
.navigation-bar-btn-container:hover .navigation-bar-btn-active::before {
	background: var(--navigationbarbtn-colour);
}

.navigation-bar-btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 0;
	border-radius: inherit;
	min-width: 100%;
	width: 100%;
	min-height: 100%;
	height: 100%;
	object-fit: cover;
}

.navigation-bar-btn svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
}

.navigation-bar-btn-logo {
	width: 100%;
}

.navigation-bar-btn-logo img {
	min-width: calc(100% - 16px);
	width: calc(100% - 16px);
	height: 100%;
	object-fit: contain;
	filter: brightness(0) invert(1) brightness(0.75);
}

.navigation-bar-btn-container:hover .navigation-bar-btn-logo img {
	background: transparent !important;
}

.navigation-bar-btn-viewer-mode {
	font-size: 17px;
}

.navigation-bar-btn-editor-mode {
	font-size: 20px;
}

.navigation-bar-btn-user-placeholder {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	background: var(--colour-grey-three);
}

.navigation-bar-btn-label {
	position: absolute;
	z-index: 100;
	top: 50%;
	left: calc(51px + 8px);
	left: calc(51px);
	transform: translateY(-50%);
	padding: 8px 12px;
	padding-right: 10px;
	border-radius: 24px;

	width: max-content;
	max-width: 200px;
	overflow-y: visible;

	background: var(--colour-grey-three);
	box-shadow: 1px 1px 5px var(--colour-grey-one);
	font-size: 13px;
	font-weight: 600;
	user-select: none;
	pointer-events: none;
	transition: 0.2s;
	transition-delay: 0s;
	opacity: 0;
}

.navigation-bar-btn-container:hover .navigation-bar-btn-label {
	opacity: 1;
	transition-delay: 0.4s;
}

@media (max-width: 750px) {
	.navigation-bar {
		display: flex;
		justify-content: space-between;
		order: 1;
		padding: 0 6px;
		height: var(--navbar-mobile-height);
		width: calc(100vw - 12px);
	}

	.navigation-bar-btn-container,
	.navigation-bar-btn-container:first-child,
	.navigation-bar-btn-container:last-child {
		margin: 0;
	}

	.navigation-bar-btn-container:nth-child(3) {
		margin-top: 0;
		margin-left: 0;
	}

	.navigation-bar-btn-container-placeholder {
		width: 44px;
		height: 44px;
	}

	.navigation-bar-btn {
		margin: 6px 0;
		width: 44px;
		height: 44px;
		vertical-align: middle;
	}

	.navigation-bar-btn::before {
		top: 48px;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 28px;
		height: 3px;
	}

	.navigation-bar-btn-label {
		display: none;
	}

	.navigation-bar-btn-container-user {
		order: 1;
	}

	.navigation-bar-is-on-story .navigation-bar-btn-container-user {
		display: none;
	}
}
