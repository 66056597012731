.text-input-container {
	position: relative;
	display: inline-block;
	--horizontalPadding: 0px;
	padding-left: var(--horizontalPadding);
	padding-right: var(--horizontalPadding);

	width: 100%;
	box-sizing: border-box;

	--text-input-font-size: var(--font-size-p-m);
	font-size: var(--text-input-font-size);
}

.text-input-label {
	margin: 0 2px;
	margin-bottom: 6px;
	text-align: left;
	font-size: var(--font-size-p-s);
	font-weight: 600;
	color: var(--text-colour-secondary);
}

.text-input {
	position: relative;
	display: flex;
	align-items: center;
	padding: 6px 12px;
	border: 1px solid var(--colour-grey-three);
	border-radius: 6px;
	width: calc(100% - (1px * 2) - (12px * 2));
	cursor: text;
}

.text-input-container-focused .text-input {
	border: 1px solid var(--colour-blue);
}

.text-input-input {
	border: 0;

	width: 100%;
	background: rgba(0, 0, 0, 0);
	color: var(--text-colour-primary);
	caret-color: var(--text-colour-primary);
	font-family: var(--font-family);
	font-size: var(--text-input-font-size);
	transition: border 0.1s;
	cursor: text;
}

.text-input-input:focus {
	outline: none;
}

.text-input-input:-webkit-autofill,
.text-input-input:-webkit-autofill:hover,
.text-input-input:-webkit-autofill:focus,
.text-input-input:-webkit-autofill:active {
	-webkit-text-fill-color: var(--text-colour-primary) !important;
	transition: background-color 99999s ease-in-out 0s;
}

.text-input-width-element {
	position: absolute;
	z-index: -10;
	top: 0;
	visibility: hidden;
	opacity: 0;
	transition: 0;
	pointer-events: none;
	user-select: none;

	min-width: 1px;
	width: fit-content;

	white-space: pre;
	font-family: var(--font-family);
	font-size: var(--text-input-font-size);
}

.text-input-hide-value-btn {
	display: inline-block;
	margin: 0;
	padding: 0;
	border: 0;

	--btnWidth: 16px;
	min-width: var(--btnWidth);
	width: var(--btnWidth);
	min-height: var(--btnWidth);
	height: var(--btnWidth);

	background: #00000000;
	color: #bbbbbb;
	font-size: var(--btnWidth);
	vertical-align: middle;
	cursor: pointer;
	transition: 0.2s;
}

.text-input-hide-value-btn:hover {
	color: var(--colour-blue);
}

/* Seamless */

.text-input-container-seamless .text-input-label {
	display: none;
}

.text-input-container-seamless.text-input-container-empty .text-input-label {
	position: absolute;
	top: 50%;
	left: var(--horizontalPadding);
	transform: translateY(-50%);
	display: block;
	/* margin-top: 2px;
	margin-bottom: -3px; */
	margin-left: 0;
	font-size: var(--text-input-font-size);
}

/* .text-input-container-seamless.text-input-container-empty .text-input-input {
	margin-top: calc(-1 * var(--input-height) - 2px + 7px);
} */

.text-input-container-seamless .text-input {
	margin-top: -1px;
	margin-bottom: -1px;
	margin-left: -2px;
	margin-right: -1.75px;
	padding: 0;
	border: 0;
	width: 100%;
}

/* Hide Value */

.text-input-container-hide-value .text-input-input {
	opacity: 0;
	user-select: none;
	pointer-events: none;
}

.text-input-container-seamless.text-input-container-empty.text-input-container-hide-value::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	display: block;
	content: "";
	width: 1px;
	height: var(--text-input-font-size);
	background: var(--text-colour-primary);
	animation: text-input-container-hide-value-cursor-line-blink 1s infinite;
}

@keyframes text-input-container-hide-value-cursor-line-blink {
	0% {
		opacity: 0;
	}
	39% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.text-input-hidden-characters {
	position: absolute;
	top: 50%;
	left: 13px;
	transform: translateY(-50%);
	margin-top: 1px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: calc(100% - 2 * 13px - 16px - 2px);
	text-align: left;
	user-select: none;
}

.text-input-container:not(.text-input-container-hide-value) .text-input-hidden-characters {
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.text-input-hidden-character-container {
	--text-input-hidden-character-height: 14px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--text-input-hidden-character-height);
}

.text-input-hidden-character-container:last-child {
	flex-grow: 1;
}

.text-input-hidden-character {
	--text-input-hidden-character-size: 6px;
	display: block;
	margin: 1px;
	content: "";
	border-radius: 100%;
	width: var(--text-input-hidden-character-size);
	height: var(--text-input-hidden-character-size);
	background: var(--text-colour-tertiary);
}

.text-input-hidden-character-container:last-child .text-input-hidden-character {
	background: transparent;
}

.text-input-hidden-character-selected {
	background: var(--text-colour-primary);
}

.text-input-hidden-character-selected::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	left: 1px;
	z-index: -1;
	margin: 0 -2px;
	padding: 0 2px;
	width: calc(var(--text-input-hidden-character-size) - 1px);
	height: var(--text-input-hidden-character-height);
	background: var(--colour-blue-two);
}

.text-input-hidden-character-single-selected::before {
	position: absolute;
	display: block;
	content: "";
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 1px;
	height: var(--text-input-hidden-character-height);
	background: var(--text-colour-primary);
}

/* AI Tools */

.text-input-ai-tools-container {
	width: max(var(--textInputWidth), 200px);
}

.text-input-ai-tools-container .ai-tools-container-is-displaying {
	margin-top: 2px;
}

/* Background Colours */

.text-input-container-bg-grey-1 {
	background: var(--colour-grey-one);
}

.text-input-container-bg-grey-2 {
	background: var(--colour-grey-two);
}

.text-input-container-bg-grey-3 {
	background: var(--colour-grey-three);
}

.text-input-container-bg-grey-4 {
	background: var(--colour-grey-four);
}

.text-input-container-bg-grey-5 {
	background: var(--colour-grey-five);
}
