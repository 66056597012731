.story-description-container {
	margin: 0;
}

.story-description {
	width: 100%;
}

.story-description-container .text-container,
.story-description-container textarea,
.story-description-container .multi-line-text-input-height-element {
	font-weight: 500;
	color: var(--text-colour-secondary);
}
