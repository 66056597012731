.unit-page-storyboard-player-content-piece-container {
	position: absolute;
	z-index: var(--z-index);
	top: var(--transformY);
	left: var(--transformX);
	width: var(--width);
	height: var(--height);
	font-size: 64px;
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.unit-page-storyboard-player-content-piece-container-is-in-piece-time {
	opacity: 1;
	pointer-events: all;
	user-select: all;
}

.unit-page-storyboard-player-content-piece {
	width: 100%;
	height: 100%;
	white-space: pre;
	user-select: none;
}

.unit-page-storyboard-player-content-piece img {
	width: 100%;
	height: 100%;
}

.unit-page-storyboard-player-content-controls {
	position: absolute;
	top: 0;
	left: 0;
	border: 2px solid var(--colour-accent);
	width: 100%;
	height: 100%;
	opacity: 0;
}

.unit-page-storyboard-player-container-is-editor-player
	.unit-page-storyboard-player-content-piece-container-is-displaying-piece-controls
	.unit-page-storyboard-player-content-controls {
	opacity: 1;
}

.unit-page-storyboard-player-content-controls-grabbers {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.unit-page-storyboard-player-content-controls-grabber {
	position: absolute;
	border-radius: 8px;
	width: 12px;
	height: 12px;
	border: 4px solid var(--colour-accent);
	transform-origin: center;
	cursor: pointer;
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(1) {
	top: -10px;
	left: -10px;
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(2) {
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(3) {
	top: -10px;
	right: -10px;
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(4) {
	top: 50%;
	right: -10px;
	transform: translateY(-50%);
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(5) {
	bottom: -10px;
	right: -10px;
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(6) {
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(7) {
	bottom: -10px;
	left: -10px;
}

.unit-page-storyboard-player-content-controls-grabber:nth-child(8) {
	top: 50%;
	left: -10px;
	transform: translateY(-50%);
}
