.unit-page-overview-background-container {
	position: absolute;
	z-index: -2;
	top: 50%;
	left: 50%;
	transform: translate(calc(-50% + (var(--navbar-width) / 2 - 23px)), calc(-50%));
	width: calc(100dvw - var(--navbar-width));
	max-width: max(calc(100dvw - var(--navbar-width)), 100%);
	height: var(--vh);
	overflow: hidden;
	transition: 0.6s;
}

.unit-page-overview-background {
	position: absolute;
	z-index: -2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100dvw - var(--navbar-width));
	height: var(--vh);
	overflow: hidden;
	transition-delay: 0.2s;
}

.unit-page-overview-background-no-image {
	background: linear-gradient(180deg, var(--colour-grey-one), var(--colour-grey-two) 15%, var(--unitColour));
	opacity: 0.7;
}

.unit-page-overview-background-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	transition: 0.6s;
}

@media (min-width: 751px) {
	.unit-page-content-container-is-on-subpages .unit-page-overview-background-container {
		max-width: 1200px;
		height: calc(275px + 100px + 100px);
		transform: translate(calc(-50% + (var(--navbar-width) / 2 - 23px)), calc(-50% + 589px - 100px));
	}

	.unit-page-container-subpages-header-not-full-size.unit-page-container-is-on-subpages .unit-page-overview-background-container {
		max-width: 1200px;
		height: calc(110px);
		transform: translate(calc(-50% + (var(--navbar-width) / 2 - 23px)), calc(-50% + 589px - 100px));
	}

	.unit-page-content-container-is-on-subpages .unit-page-overview-background-wrapper {
		transform: translateY(calc(235px + 100px));
	}

	.unit-page-container-subpages-header-not-full-size.unit-page-container-is-on-subpages
		.unit-page-content-container-is-on-subpages
		.unit-page-overview-background-wrapper {
		transform: translateY(calc(235px + 80px));
		filter: blur(3px);
	}

	.unit-page-content-container-is-on-subpages .unit-page-overview-background-wrapper .unit-page-overview-background-space-map-container {
		margin-top: -235px;
	}

	.unit-page-content-container-is-on-subpages .unit-page-overview-background-wrapper .unit-page-overview-background-surface-map-container {
		margin-top: -235px;
	}
}

.unit-page-overview-background-space-map-wrapper {
	width: 100%;
	height: 100%;
}

.unit-page-content-container-is-on-overview .unit-page-overview-background-space-map-wrapper {
	animation: fade-in-1 0s;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	opacity: 0;
	transition: 0s !important;
}

.unit-page-content-container-is-on-subpages .unit-page-overview-background-space-map-wrapper {
	animation: fade-in-2 0s;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	opacity: 0;
	transition: 0s !important;
}

.unit-page-overview-background-surface-map-container {
	transition: 0.6s;
}

.unit-page-overview-background img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.unit-page-overview-foreground-container {
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100vw - var(--navbar-width));
	height: var(--vh);
	overflow: hidden;
	transition: opacity 0.4s;
	transition-delay: 0.2s;
}

.unit-page-overview-foreground-container-no-image {
	display: none;
}

.unit-page-overview-foreground-alignment-top-left {
	position: absolute;
	top: 0;
	left: 0;
}

.unit-page-overview-foreground-alignment-top {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-overview-foreground-alignment-top-right {
	position: absolute;
	top: 0;
	right: 0;
}

.unit-page-overview-foreground-alignment-center-left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.unit-page-overview-foreground-alignment-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.unit-page-overview-foreground-alignment-center-right {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.unit-page-overview-foreground-alignment-bottom-left {
	position: absolute;
	bottom: 0;
	left: 0;
}

.unit-page-overview-foreground-alignment-bottom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-overview-foreground-alignment-bottom-right {
	position: absolute;
	bottom: 0;
	right: 0;
}

.unit-page-overview-foreground-image-size {
	position: absolute;
	width: fit-content;
	height: fit-content;
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.unit-page-overview-background-space-map-container {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: calc(100% + 24px);
	height: 100%;
	background: #000000;
	animation: fade-in 0.2s 1;
	animation-fill-mode: forwards;
	animation-delay: 2s;
	opacity: 0;
	user-select: none;
	pointer-events: none;
}

.unit-page-overview-background-surface-map-container {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: calc(100% + 24px);
	height: 100%;
	overflow: hidden;
}

.unit-page-overview-background-surface-map-container img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	filter: blur(4px) brightness(60%);
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 750px) {
	.unit-page-overview-background-container {
		width: 100vw;
		transform: translate(-50%, calc(-50% + var(--navbar-mobile-height) / 2));
	}

	.unit-page-overview-background {
		width: 100vw;
		transform: translate(-50%, -50%);
		transition: opacity 0.2s;
		transition-delay: 0.1s;
	}

	.unit-page-overview-foreground-container {
		width: 100vw;
		height: calc(var(--vh) - var(--navbar-mobile-height));
		top: 0;
		left: 0;
		transform: unset;
		transition: opacity 0.2s;
		transition-delay: 0.1s;
	}
}
