.story-primary-characters {
	margin: 0;
	padding: 12px;
	border-radius: 4px;
}

.story-primary-characters-primary {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	width: 100%;
}

.story-primary-characters-primary-title {
	font-size: 32px;
	font-weight: 600;
	color: var(--text-colour-primary);
}

.story-primary-characters-primary-modify-btn-container {
	margin-left: auto;
}

.story-primary-characters-primary-modify-btn:last-child {
	margin-right: 0;
}

@media (max-width: 1050px) {
	.story-primary-characters {
		margin: 0;
		padding: 12px 6px;
		padding-bottom: 0;
	}
}
