.unit-page-subpage-physical {
}

.unit-page-subpage-physical-section-1,
.unit-page-subpage-physical-section-2 {
	vertical-align: top;
}

.unit-page-subpage-physical-section-2 {
	margin-bottom: 40dvh;
}

@media (max-width: 750px) {
	.unit-page-subpage-physical-section-1,
	.unit-page-subpage-physical-section-2 {
		padding: 0;
		width: 100%;
	}

	.unit-page-subpage-physical-section-2 {
		padding: 24px 0;
	}
}
