.characters-relationship-chart-container {
	display: inline-block;
	margin-top: 30px;
	vertical-align: top;
	width: calc(100% - 400px - 12px);
	height: calc(var(--charactersRelationshipChartWidth) + 18px);
}

.characters-relationship-chart {
	position: relative;
	margin: 0 auto;
	width: var(--charactersRelationshipChartWidth);
	height: var(--charactersRelationshipChartWidth);
}

.characters-relationship-chart-selected-character-item {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	--selectedCharacterItemWidth: calc(var(--charactersRelationshipChartWidth) * 0.35);
	width: var(--selectedCharacterItemWidth);
	height: calc(var(--selectedCharacterItemWidth) + 22px);

	opacity: 0;
	pointer-events: none;
}

@keyframes characters-relationship-chart-selected-character-item {
	0% {
		opacity: 0;
	}
	40%,
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.characters-relationship-chart-selected-character-item-face-image {
	border: 4px solid var(--characterColour);
	border-radius: 100%;
	width: calc(var(--selectedCharacterItemWidth) - (2 * 4px));
	height: calc(var(--selectedCharacterItemWidth) - (2 * 4px));
	background: var(--colour-grey-three);
}

.characters-relationship-chart-selected-character-item-face-image img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.characters-relationship-chart-selected-character-item-name {
	margin-top: 3px;
	height: 19px;
	font-weight: 600;
	font-size: 15px;
	text-align: center;
}

.characters-relationship-chart-characters-container,
.characters-relationship-chart-characters-names-container {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.characters-relationship-chart-characters-names-container {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-top: -8px;

	width: 100%;
	height: 100%;

	pointer-events: none;
	user-select: none;
}

.characters-relationship-chart-canvas {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
}

@media (max-width: 1100px) {
	.characters-relationship-chart-container {
		width: 100%;
	}

	.characters-relationship-chart-selected-character-item {
		animation: characters-relationship-chart-selected-character-item 1s linear 0s 1;
		animation-fill-mode: forwards;
	}
}
