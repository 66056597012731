.substories-list-substories-poster .drag-drop-item-enable-drag-drop {
	margin: 0 4px;
}

.substories-list-substories-poster-container {
	--posterWidth: 340px;
	--posterHeight: 510px;

	margin: 0 16px;
	margin-top: calc(24px + 4px);
	margin-bottom: 12px;

	width: var(--posterWidth);

	transition: scale 0.4s;
}

.substories-list-substories-poster-container:hover {
	scale: 1.01;
}

@media (max-width: 768px) {
	.substories-list-substories-poster-container:hover {
		margin-top: calc(24px + 4px);
	}
}

.substories-list-substories-poster,
.substories-list-substories-poster-placeholder {
	flex-shrink: 0;
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 0;
	border: 2px solid var(--colour-grey-four);
	border-color: var(--colour-grey-four);
	border-radius: 10px;

	width: var(--posterWidth);
	height: var(--posterHeight);

	background: var(--colour-grey-one);
	box-shadow: 0 0 20px transparent;
	cursor: pointer;
}

.substories-list-substories-poster {
	transition: 0.3s;
}

.substories-list-substories-poster-placeholder {
	animation: substories-list-substories-poster-placeholder 3s infinite;
}

@keyframes substories-list-substories-poster-placeholder {
	0% {
		border-color: var(--colour-grey-one);
		background: var(--colour-grey-one);
	}
	15%,
	55% {
		border-color: var(--colour-grey-three);
		background: var(--colour-grey-two);
	}
	88%,
	100% {
		border-color: var(--colour-grey-one);
		background: var(--colour-grey-one);
	}
}

.substories-list-substories-poster-container:hover .substories-list-substories-poster {
	box-shadow: 0 0 32px -8px var(--substoryColour);
}

.drag-drop-item-enable-drag-drop .substories-list-substories-poster {
	animation: wiggle-medium infinite 0.75s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even) .substories-list-substories-poster {
	animation-delay: 0.1s;
}

.substories-list-substories-poster-content {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
}

.substories-list-substories-poster-content-title-container {
	--titleContainerBottom: 56px;
	--titleFontSize: 24px;
	--titleSubstoryFontSize: 36px;
	--titleStoryFontSize: 28px;

	position: absolute;
	bottom: var(--titleContainerBottom);
	left: 50%;
	transform: translateX(-50%);
	min-width: 100%;
	height: fit-content;
}

.substories-list-substories-poster-content-title-story,
.substories-list-substories-poster-content-title-substory {
	font-weight: 600;
	text-transform: uppercase;
	font-family: "Palatino Linotype", sans-serif;
	color: #fff;
	letter-spacing: 5px;
	word-spacing: 1px;
}

.substories-list-substories-poster-content-title-substory {
	font-size: var(--titleSubstoryFontSize);
}

.substories-list-substories-poster-content-title-story {
	font-size: var(--titleStoryFontSize);
}

.substories-list-substories-poster-content-title-container-with-story-title .substories-list-substories-poster-content-title-substory {
	font-size: var(--titleFontSize);
}

.substories-list-substories-poster-number-container {
	position: relative;
	margin-top: calc(12px - 4px);
	width: 100%;
	height: 46px;
}

.substories-list-substories-poster-number-container-placeholder {
	height: 12px;
}

.substories-list-substories-poster-number {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	color: var(--text-colour-secondary);
	transition: 0.3s;
}

.substories-list-substories-poster-container:hover .substories-list-substories-poster-number {
	color: var(--textPrimaryColour);
	text-shadow: 0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour),
		0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour);
}

.substories-list-substories-poster-number-background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 46px;
	height: 46px;
	filter: invert(1);
	opacity: 0.4;
	transition: 0.3s;
}

.theme-light .substories-list-substories-poster-number-background {
	filter: invert(0);
}

.substories-list-substories-poster-container:hover .substories-list-substories-poster-number-background {
	opacity: 0.6;
}

.substories-list-substories-poster-number-background img {
	width: 100%;
	height: 100%;
}

.substories-list-substories-poster-title-container {
	margin-top: 12px;
	text-align: center;
	font-size: 15px;
	transition: 0.3s;
}

.substories-list-substories-poster-container:hover .substories-list-substories-poster-title-container {
	text-shadow: 0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour), 0 0 12px var(--substoryColour);
}

.substories-list-substories-poster-background-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 9px;
	z-index: -1;
	width: 100%;
	height: 100%;
}

.substories-list-substories-poster-background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 950px) {
	.substories-list-substories-poster-container {
		--posterWidth: calc(340 / 510 * (var(--vh) - var(--navbar-mobile-height) - 70px - 40px - 38px - 19px - 24px - 28px - 12px - 16px - 8px));
		--posterHeight: calc(var(--vh) - var(--navbar-mobile-height) - 70px - 40px - 38px - 19px - 24px - 28px - 12px - 16px - 8px);

		margin-left: 8px;
		margin-right: 8px;
	}

	.substories-list-substories-poster-placeholder,
	.substories-list-substories-poster {
		border-width: 4px;
	}

	.substories-list-substories-poster-content-title-container {
		--titleContainerBottom: 38px;
		--titleFontSize: 16px;
		--titleSubstoryFontSize: 21px;
		--titleStoryFontSize: 18px;
	}

	.substories-list-substories-poster-number-container {
		margin-top: 4px;
		height: 38px;
	}

	.substories-list-substories-poster-number {
		font-size: 16px;
	}

	.substories-list-substories-poster-number-background {
		width: 38px;
		height: 38px;
	}

	.substories-list-substories-poster-title-container {
		margin-top: 4px;
	}
}
