.unit-page-subpage-settings {
	display: flex;
	gap: 32px;
}

.unit-page-subpage-settings-section-1 {
	width: 50%;
}

.unit-page-subpage-settings-section-2 {
	width: 50%;
}

.unit-page-subpage-settings-section-2 .unit-page-settings-delete-container {
	display: none;
}

.unit-page-subpage-settings-item {
	position: relative;
}

.unit-page-subpage-settings-item::after {
	position: absolute;
	top: -2px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.unit-page-subpage-settings-item:first-child:after {
	display: none;
}

.unit-page-subpage-settings-item {
	margin-top: 0;
	margin-bottom: 0;
	padding: 20px 0;
	width: 100%;
}

.unit-page-subpage-settings-item .label-container {
	padding: 0;
}

@media (max-width: 1100px) {
	.unit-page-subpage-settings {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.unit-page-subpage-settings-section-1,
	.unit-page-subpage-settings-section-2 {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.unit-page-subpage-settings-section-2 {
		margin-top: -8px;
	}

	.unit-page-subpage-settings-section-2 .unit-page-subpage-settings-item:first-child:after {
		display: block;
	}

	.unit-page-subpage-settings-section-1 .unit-page-settings-delete-container {
		display: none;
	}

	.unit-page-subpage-settings-section-2 .unit-page-settings-delete-container {
		display: block;
	}
}
