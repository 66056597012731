.icon-btn-container {
	position: relative;
	display: inline-block;
	margin: 4px;
	overflow: visible;
}

.icon-btn-container-hidden {
	display: none;
}

.icon-btn {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 100%;

	min-width: 32px;
	width: 32px;
	min-height: 32px;
	height: 32px;
	overflow: visible;

	background: var(--colour-accent);
	color: var(--text-colour-primary);
	text-align: center;
	vertical-align: middle;
	transition: 0.2s;
	cursor: pointer;
}

.icon-btn:hover,
.icon-btn:focus {
	background: var(--colour-accent-hover);
	color: var(--text-colour-primary);
}

.icon-btn svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	font-size: 28px;
}

.icon-btn-container-plus-icon svg {
	width: 50%;
}

.icon-btn-container-sort-icon svg {
	width: 43%;
}

.icon-btn-container-trash-icon svg {
	width: 50%;
}

.icon-btn-container-times-icon svg {
	width: 45%;
}

.icon-btn-container-seamless .icon-btn {
	--iconBtnWidth: 24px;
	min-width: var(--iconBtnWidth);
	width: var(--iconBtnWidth);
	min-height: var(--iconBtnWidth);
	height: var(--iconBtnWidth);
	background: transparent;
}

.icon-btn-container-seamless.icon-btn-container-large .icon-btn {
	--iconBtnWidth: 32px;
}

.icon-btn-container-seamless.icon-btn-container-small .icon-btn {
	--iconBtnWidth: 18px;
}

.icon-btn-container-seamless.icon-btn-container-extra-small .icon-btn {
	--iconBtnWidth: 16px;
}

.icon-btn-container-seamless .icon-btn:hover,
.icon-btn-container-seamless .icon-btn:focus {
	background: transparent;
	color: var(--colour-accent);
}

.icon-btn-container-has-hover-icon .icon-btn .icon-btn-hover-icon-container svg,
.icon-btn-container-has-hover-icon .icon-btn .icon-btn-hover-icon-container svg {
	display: none;
}

.icon-btn-container-has-hover-icon .icon-btn:hover .icon-btn-icon-container svg,
.icon-btn-container-has-hover-icon .icon-btn:focus .icon-btn-icon-container svg {
	display: none;
}

.icon-btn-container-has-hover-icon .icon-btn:hover .icon-btn-hover-icon-container svg,
.icon-btn-container-has-hover-icon .icon-btn:focus .icon-btn-hover-icon-container svg {
	display: block;
}

.icon-btn-container-seamless svg {
	width: 75%;
}

.icon-btn-container-seamless.icon-btn-container-user-plus-icon svg {
	width: 100%;
}

.icon-btn-container-seamless.icon-btn-container-times-icon svg {
	width: 72%;
}

.icon-btn-container-seamless.icon-btn-container-plus-icon svg {
	width: 85%;
}

.icon-btn-container-seamless.icon-btn-container-arrows-alt-icon svg {
	width: 92%;
}

.icon-btn-small-icon-container {
	position: absolute;
	z-index: 2;
	top: -2px;
	right: -3px;
	width: 16px;
	height: 16px;
	color: var(--text-colour-primary);
	pointer-events: none;
}

.icon-btn-small-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 16px;
	height: 16px;
	color: var(--text-colour-primary);
	transition: background 0.2s;
	transition: color 0.2s;
}

.icon-btn-small-icon svg {
	max-width: 16px;
	max-height: 16px;
}

.icon-btn-small-icon-bg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	width: 24px;
	height: 24px;
	color: var(--colour-grey-one);
}

.icon-btn-small-icon-bg svg {
	max-width: 24px;
	max-height: 24px;
}

.icon-btn-container-seamless .icon-btn:hover .icon-btn-small-icon,
.icon-btn-container-seamless .icon-btn:focus .icon-btn-small-icon {
	background: transparent;
	color: var(--colour-accent);
}

.icon-btn-label {
	position: absolute;
	left: 0;
	z-index: 200;
	display: flex;
	flex-wrap: wrap;
	gap: 0 5px;

	margin-top: 8px;
	margin-left: -20px;
	margin-right: -20px;
	padding: 8px;
	border-radius: 4px;

	width: fit-content;
	max-width: 120px;

	background: var(--colour-grey-three);
	font-size: 15px;

	pointer-events: none;
	user-select: none;
	opacity: 0;
	transition: opacity 0.2s;
	transition-delay: 0.1;
}

.icon-btn-label-word {
	white-space: nowrap;
}

.icon-btn-container:hover .icon-btn-label {
	transition-delay: 0.3s;
	opacity: 1;
}

.icon-btn-container-left-label-align .icon-btn-label {
	top: 50%;
	transform: translateY(calc(-50% - 8px));
	left: unset;
	right: 250%;
	width: max-content;
	max-width: unset;
}

@media (max-width: 750px) {
	.icon-btn-container-large .icon-btn-label {
		margin-top: 16px;
	}
}

.icon-btn-container-light .icon-btn,
.icon-btn-container-light .icon-btn-small-icon-container {
	color: #fff;
}
