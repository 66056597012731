.settings-item-font-size-container .settings-item-title {
	margin-bottom: 8px;
}

.user-settings-font-size-container {
	overflow: hidden;
}

.user-settings-font-size-input-container {
	width: 100%;
}

.user-settings-font-size-input-container input {
	width: 100%;
}

.user-settings-font-size-labels-container {
	display: flex;
	justify-content: space-between;
	width: 125%;
}

.user-settings-font-size-label-container {
	width: 100%;
	text-align: left;
}
