.unit-page-subpage-settings-list-image-container {
	margin-right: 6px;
	margin-bottom: 12px;
}

.unit-page-subpage-settings-list-image-image {
	display: block;
	margin-top: 6px;
	border-radius: 5px;
	width: calc(14px * 16);
	height: calc(14px * 9);

	background: var(--colour-grey-three);
}

.unit-page-subpage-settings-list-image-image img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.unit-page-subpage-settings-list-image-image .image-input-circular {
	width: 100%;
	height: 100%;
}

@media (max-width: 1100px) {
	.unit-page-subpage-settings-list-image-container {
		margin-right: 0;
		margin-bottom: 12px;
	}
}
