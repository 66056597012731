.toggle-input-container {
	--gap: 4px;
	--width: 50px;
	--height: 28px;
	padding: 8px;
}

.toggle-input-container-size-s {
	--gap: 3px;
	--width: 40px;
	--height: 20px;
	padding: 4px;
}

.toggle-input-label {
	display: inline-block;
	margin-right: 12px;
	user-select: none;
	vertical-align: middle;
}

.toggle-input-labels-second {
	margin-left: 12px;
	margin-right: 0;
}

.toggle-input {
	position: relative;
	display: inline-block;
	margin: 4px;

	width: var(--width);
	height: var(--height);

	vertical-align: middle;
}

.toggle-input-slider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border-radius: 25px;

	background: var(--colour-grey-five);
	transition: 0.2s;
}

.toggle-input-container-enable-edit .toggle-input-slider {
	cursor: pointer;
}

.toggle-input-slider:before {
	position: absolute;
	display: block;
	content: "";
	top: var(--gap);
	left: var(--gap);

	border-radius: 100%;

	height: calc(var(--height) - 2 * var(--gap));
	width: calc(var(--height) - 2 * var(--gap));

	background: #fff;
	transition: transform 0.2s;
}

.toggle-input-active .toggle-input-slider {
	background: var(--colour-blue);
}

.toggle-input-active .toggle-input-slider:before {
	transform: translateX(calc(var(--width) - var(--height)));
}
