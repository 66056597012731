.characters-relationship-container {
	position: relative;
	margin: 24px auto;
	width: 100%;
	min-height: calc(var(--charactersRelationshipChartWidth) + 8px + 26px + 48px);
}

.characters-relationship-primary {
	display: flex;
	align-items: center;
}

.characters-relationship-primary-title {
	margin-bottom: 16px;
	margin-right: 12px;
	font-size: 32px;
	font-weight: 600;
	max-width: calc(100% - 115px);
}

.characters-relationship-primary-buttons-container {
	display: none;
	margin-left: auto;
	margin-right: 6px;
}

.characters-relationship-content {
	position: relative;
	margin-top: 8px;
	min-height: var(--charactersRelationshipChartWidth);
	height: 100%;
}

@media (max-width: 1100px) {
	.characters-relationship-container {
		margin-bottom: 0;
		min-height: calc(var(--vh) - var(--navbar-mobile-height) - 48px - 48px);
	}

	.characters-relationship-primary-buttons-container {
		display: block;
	}
}
