.confirm-delete-container {
	display: flex;
	justify-content: right;
	align-items: center;
	margin-top: 48px;
	text-align: right;
}

.confirm-delete-label {
	margin-right: 12px;
	font-size: 15px;
}

.confirm-delete-btn {
	margin: 4px 12px;
	transition: color 0.2s;
}

.confirm-delete-btn:last-child {
	margin-right: 0;
}

.confirm-delete-btn-delete:hover {
	color: var(--colour-red);
}

.confirm-delete-hidden-container {
	display: flex;
	align-items: center;
	padding-right: 12px;
	pointer-events: none;
	user-select: none;
	opacity: 0;
}
