.user-followers-menu-subpage-buttons {
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 320px;
	padding-bottom: 16px;
}

.user-followers-menu-subpage-btn {
	padding: 6px 18px;
	border: 0;
	border-radius: 24px;
	background: transparent;
	color: var(--text-colour-tertiary);
	font-size: var(--font-size-p-m);
	cursor: pointer;
	transition: 0.2s;
}

.user-followers-menu-subpage-btn:hover {
	color: var(--text-colour-secondary);
}

.user-followers-menu-subpage-btn-active,
.user-followers-menu-subpage-btn-active:hover {
	color: var(--text-colour-primary);
	background: var(--colour-grey-four);
}

.user-followers-menu-subpage-btn-hidden {
	opacity: 0;
	user-select: none;
	pointer-events: none;
}
