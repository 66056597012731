.unit-page-relationship-info-home-selected-unit-page-title {
	margin-bottom: 6px;
	font-size: 16px;
	font-weight: 600;
}

.unit-page-relationship-info-home-selected-unit-page-relationships {
	padding-right: 12px;
	height: 300px;
	overflow-y: overlay;
}

.unit-page-relationship-info-home-selected-unit-page-divider {
	display: block;
	content: "";
	margin: 6px 0;
	margin-top: calc(6px + 6px);
	width: 100%;
	height: 1px;
	background: var(--text-colour-tertiary);
}

@media (max-height: 750px) {
	.unit-page-relationship-info-home-selected-unit-page-relationships {
		height: unset;
	}
}
