.unit-page-subpage-physical-outfit-item-container .content-item {
	margin-left: 0;
	margin-right: 0;
}

.unit-page-subpage-physical-outfit-item-container .content-item .unit-page-subpage-physical-outfit-item-container .content-item {
	margin: 0;
	margin-top: 8px;
	padding-right: 8px;
}

.unit-page-subpage-physical-outfit-item-container-0,
.unit-page-subpage-physical-outfit-item-container-0 .content-item {
	margin-top: 0;
}

.unit-page-subpage-physical-outfit-item-container:last-child {
	padding-bottom: 8px;
}

.drag-drop-item-enable-drag-drop.unit-page-subpage-physical-outfit-item-container {
	animation: wiggle-very-small infinite 0.7s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).unit-page-subpage-physical-outfit-item-container {
	animation-delay: 0.1s;
}

.unit-page-subpage-physical-outfit-item-container {
	position: relative;
}

.unit-page-subpage-physical-outfit-item-container::after {
	position: absolute;
	bottom: 1px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.unit-page-subpage-physical-outfit-item-container:last-child:after {
	display: none;
}

.unit-page-subpage-physical-outfit-item {
	padding: 12px 0;
	padding-bottom: 13px;
}

.unit-page-subpage-physical-outfit-item-content {
	display: inline-block;
	width: calc(100% - 32px);
	vertical-align: top;
}

.unit-page-subpage-physical-outfit-item .multi-line-text-input {
	margin-bottom: -4px;
}

.unit-page-subpage-physical-outfit-item-title {
	margin-bottom: 3px;
}

.unit-page-subpage-physical-outfit-item-title .text-input {
	margin-top: 2px;
}

.unit-page-subpage-physical-outfit-item-title .text-input-label ~ input {
	height: var(--font-size-p-m-height);
}

.unit-page-subpage-physical-outfit-item-title .text-input-label {
	font-size: var(--font-size-p-m);
}

.unit-page-subpage-physical-outfit-item-title,
.unit-page-subpage-physical-outfit-item-title input {
	font-size: var(--font-size-p-m);
	font-weight: 600;
}

.unit-page-subpage-physical-outfit-item .text-input-container-empty {
	margin-bottom: 5px;
	height: var(--font-size-p-m-height);
}

.unit-page-subpage-physical-outfit-item-text {
	min-height: var(--font-size-p-m-height);
}

.unit-page-subpage-physical-outfit-item-btns-container {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	vertical-align: top;
	width: calc(22px + 8px);
	gap: 4px 0;
}

.unit-page-subpage-physical-outfit-item-images {
	display: inline-flex;
	flex-wrap: wrap;
	text-align: left;
	width: 100%;
}

.unit-page-subpage-physical-outfit-item-image-item {
	position: relative;
	margin: 4px;
	width: calc((100% / 3) - 8px);
}

.drag-drop-item-enable-drag-drop .unit-page-subpage-physical-outfit-item-image-item {
	-webkit-user-drag: element;
}

.unit-page-subpage-physical-outfit-item-image-item img {
	width: 100%;
	max-height: max(18vw, 140px);
	object-fit: contain;
}

.unit-page-subpage-physical-outfit-item-image-item-caption {
	padding: 3px 8px;
	border-radius: 4px;
	width: fit-content;
	max-width: calc(100% - 16px);
	word-break: break-word;
	overflow-wrap: anywhere;
	background: var(--colour-grey-three);
}

.unit-page-subpage-physical-outfit-item-image-item-caption,
.unit-page-subpage-physical-outfit-item-image-item-caption input {
	font-size: var(--font-size-p-m);
	min-height: 20px;
}

.unit-page-subpage-physical-outfit-item-image-item-btns-container {
	position: absolute;
	top: 2px;
	right: 2px;
}
