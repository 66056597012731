.unit-page-subpage-gallery {
	vertical-align: top;
	width: 100%;
}

.unit-page-subpage-gallery-edit {
	display: flex;
	height: var(--unitPageSubpageMaxHeight);
}

.unit-page-subpage-gallery-items-container {
	width: 100%;
}

.unit-page-subpage-gallery-items {
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 750px) {
	.unit-page-subpage-gallery-items-container {
		max-height: calc(var(--vh) - var(--navbar-mobile-height) - var(--characterPaddingTop) - 32px - 4px - 12px - 12px - 48px);
	}
}
