.story-settings-member-item-container {
	margin: 8px 0;
}

.story-settings-member-item-container:first-child {
	margin-top: 0;
}

.story-settings-member-item-container:last-child {
	margin-bottom: 8px;
}

.story-settings-member-profile-picture-container {
	margin-right: 16px;
	border-radius: 100%;
	--profilePictureWidth: 50px;
	min-width: var(--profilePictureWidth);
	width: var(--profilePictureWidth);
	min-height: var(--profilePictureWidth);
	height: var(--profilePictureWidth);
}

.story-settings-member {
	display: flex;
	align-items: center;
}

.story-settings-member-profile-picture-container img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.story-settings-member-names-container {
	margin-right: 16px;
}

.story-settings-member-nickname {
	font-weight: 600;
}

.story-settings-member-username {
	font-weight: 400;
	font-size: 16px;
	color: var(--text-colour-secondary);
}

.story-settings-member-type-container {
	position: relative;
	margin-left: auto;
	margin-right: 16px;
	width: fit-content;
}

.story-settings-member-type {
	padding: 8px 12px;
	border-radius: 4px;
	background: var(--colour-grey-two);
	font-size: 13px;
}

.story-settings-member-type-container .dropdown-value-container {
	background: var(--colour-grey-two);
}

.story-settings-member-item-btns-container .icon-btn:hover,
.story-settings-member-item-btns-container .icon-btn:focus {
	color: var(--colour-red);
}

@media (max-width: 750px) {
	.story-settings-members-list .content-item-w-bg {
		padding: 8px;
	}

	.story-settings-member {
		flex-wrap: wrap;
	}

	.story-settings-member-profile-picture-container {
		margin-right: 8px;
		--profilePictureWidth: 40px;
	}

	.story-settings-member-names-container {
		margin-right: 0;
	}

	.story-settings-member-flex-break {
		flex-basis: 100%;
	}

	.story-settings-member-type-container {
		margin: 0px;
		margin-top: 12px;
	}

	.story-settings-member-item-btns-container {
		margin-top: 6px;
		margin-left: auto;
	}
}
