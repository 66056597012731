.unit-page-section-switcher {
	position: absolute;
	z-index: 2;
	top: calc(100vh - 40px);
	left: 0;
	padding: 0;
	width: 100%;
	height: 40px;
	transition: 0.2s;
	opacity: 1;
	pointer-events: none;
}

.unit-page-container-is-authorized .unit-page-section-switcher {
	display: none;
}

.unit-page-section-switcher-on-subpages {
	opacity: 0;
	pointer-events: none;
}

.unit-page-section-switcher-btn {
	position: relative;
	transform: rotate(90deg);
	padding: 0;
	border: 0;
	width: 100%;
	background: transparent;
	color: var(--text-colour-primary);
	transition: transform 0.75s;
	cursor: pointer;
	pointer-events: all;
}

.unit-page-section-switcher-btn svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 50px;
}

@media (max-width: 1300px) {
	.unit-page-section-switcher {
		display: none;
	}
}
