.story {
	margin: 0 auto;
	padding: 6px 48px;
	max-width: 1550px;
}

.story-content {
	display: flex;
	margin-top: 24px;
}

.story-section-1 {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 8px;
	width: 50%;
	vertical-align: top;
}

.story-section-2 {
	flex-shrink: 0;
	display: inline-block;
	margin-top: 8px;
	width: 50%;
	vertical-align: top;
}

.story-section-2-full-width {
	width: 100%;
}

.story-section-1 .content-item,
.story-section-2 .content-item {
	margin: 8px 4px;
}

@media (max-width: 1050px) {
	.story {
		padding: 6px;
	}

	.story-content {
		margin-top: 16px;
		flex-direction: column;
		gap: 32px;
	}

	.story-section-1,
	.story-section-2 {
		margin: 0;
		width: 100%;
	}

	.story-section-2 {
		margin-top: -8px;
	}
}
