.register {
	position: relative;
	width: 100%;
}

.register-form {
	margin: 80px auto;
	margin-top: 10vh;
	padding: 24px;
	border-radius: 4px;

	width: calc(100% - 48px - 24px);
	max-width: 600px;

	text-align: center;
}

.register-form-title {
	margin-bottom: 24px;
	font-size: 28px;
	font-weight: 600;
	text-align: left;
}

.register-form-text-input-container {
	margin: 10px 0;
}

.register-form-submit-container {
	margin-top: 20px;
	text-align: right;
}

.register-form-submit-container .submit-btn-text {
	min-width: 64px;
}

.register-completed-container {
	margin: 80px auto;
	margin-top: 8vh;
	padding: 24px;
	border-radius: 4px;

	width: calc(100% - 48px - 24px);
	max-width: 600px;

	text-align: left;
}

.register-completed-title {
	margin-bottom: 10px;
	font-size: 24px;
	font-weight: 600;
	text-align: left;
}

.register-completed-text {
	font-size: 16px;
}

.register-completed-email {
	margin: 24px 0;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}

@media (max-width: 750px) {
	.register-form {
		margin-top: 24px;
	}
}
