.characters-group-characters-cards-container {
	margin-top: 12px;
	position: relative;
	width: 100%;
}

.characters-group-characters-cards {
	display: flex;
	width: 100%;
}

@media (max-width: 950px) {
	.characters-group-characters-cards-container {
		margin-top: 12px;
	}
}
