.drag-drop-item-enable-drag-drop .story-item {
	animation: wiggle-large infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even) .story-item {
	animation-delay: 0.1s;
}

@keyframes story-item-placeholder {
	0% {
		background: var(--colour-grey-one);
	}
	10%,
	55% {
		background: var(--colour-grey-two);
	}
	88%,
	100% {
		background: var(--colour-grey-one);
	}
}

.story-item-loading {
	display: none;
}

.story-item-container {
	margin: 8px;
}

.story-item,
.story-item-placeholder {
	position: relative;
	display: inline-block;
	padding: 24px 24px;
	border-radius: 16px;

	width: 260px;
	height: 400px;

	background: linear-gradient(45deg, var(--colour-grey-two), var(--colour-grey-three));
	cursor: pointer;
	transition: 0.2s;
	overflow: visible;
}

.story-item:hover {
	scale: 1.03;
}

.story-item-size-l {
	border-radius: 5px;
}

.story-item-size-s {
	border-radius: 3px;
}

.story-item-content {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	width: 100%;
	border-radius: inherit;
	vertical-align: middle;
}

.story-item-primary {
	display: flex;
	flex-direction: column;
}

.story-item-size-l .story-item-content {
	width: calc(100% - 28px);
}

.story-item-size-s .story-item-content {
	width: calc(100% - 12px);
}

.story-item-title-container {
	font-size: 30px;
	font-weight: 600;

	font-size: 24px;
}

.story-item-title {
	width: fit-content;
}

.story-item-owner {
	position: relative;
	font-size: 14px;
	font-weight: 600;
}

.story-item-size-l .story-item-owner {
	font-size: 16px;
}

.story-item-size-s .story-item-owner {
	font-size: 13px;
}

.story-item-owner-label-container {
	position: relative;
}

.story-item-owner-label {
	position: absolute;
	top: 4px;
	left: -8px;
	padding: 8px 16px;
	border-radius: 24px;

	background: var(--colour-grey-three);
	box-shadow: 1px 1px 5px var(--colour-grey-one);
	font-weight: 500;
	font-size: 14px;
	opacity: 0;
	transition: 0.2s;
	transition-delay: 0.2s;
}

.story-item-owner:hover .story-item-owner-label {
	opacity: 1;
	transition-delay: 0s;
}

.story-item-owner-label:hover {
	background: var(--colour-grey-four);
	box-shadow: 1px 1px 5px var(--colour-grey-two);
}

.story-item-private {
	font-size: 14px;
}

@media (max-width: 750px) {
	.story-item,
	.story-item-placeholder {
		padding: 24px 24px;
		border-radius: 16px;

		width: 200px;
		height: 307px;
	}

	.story-item-title-container {
		font-size: 20px;
	}

	.story-item-owner-label {
		font-size: 13px;
	}
}
