.label-container {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 8px 0;
	padding: 12px;
	border-radius: 4px;
	width: calc(100% - 24px);
}

.label-container-is-inline {
	flex-direction: row;
}

.label-container-vertical-alignment-center {
	justify-content: center;
}

.label-label {
	display: flex;
	justify-content: left;
	align-items: center;
	margin: 0;

	width: fit-content;

	font-size: 18px;
	font-weight: 600;
	word-break: break-word;
	overflow-wrap: anywhere;
	color: var(--text-colour-primary);
}

.label-container-is-inline .label-label {
	max-width: 50%;
}

.label-container-is-bold .label-label {
	font-weight: 600 !important;
}

.label-container-big-label .label-label {
	margin-bottom: 16px;
	font-size: 32px;
}

.label-value {
	display: flex;
	margin: 0;

	min-height: 29px;

	vertical-align: top;
	font-size: 16px;
	font-weight: 400;
	word-break: break-word;
	overflow-wrap: anywhere;
	color: var(--text-colour-primary);
}

.label-container-is-inline .label-value {
	justify-content: right;
	align-items: center;
	margin-left: auto;
	text-align: right;
}

.label-value .editable-container {
	width: 100% !important;
}

.label-value .text-input-container {
	margin-top: 5px;
}

.label-container-is-inline .label-value input {
	text-align: right;
	color: var(--text-colour-primary);
}

.label-container-is-inline .label-value .dropdown-container {
	position: relative;
	margin-left: auto;
	width: fit-content;
	text-align: right;
}

.label-value .dropdown-options-container {
	width: 100% !important;
}

.label-value .toggle-input-container {
	padding: 0;
}

.label-value .confirm-delete-container {
	margin-top: 0;
}

@media (max-width: 750px) {
	.label-container {
		margin: 6px 0;
		padding: 10px 6px;
	}

	.label-label {
		font-size: 17px;
	}

	.label-value {
		margin-top: 2px;
		font-size: 15px;
	}
}
