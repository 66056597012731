.dropdown-container {
	width: 100%;
}

.dropdown-value-container {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	padding: 8px 12px;
	border: 0;
	border-radius: 4px;
	background: var(--colour-grey-three);
	color: var(--text-colour-primary);
	min-width: 110px;
}

.dropdown-value-container {
	cursor: pointer;
}

.dropdown-container-disable-edit .dropdown-value-container {
	cursor: auto;
}

.dropdown-container-seamless .dropdown-value-container {
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: transparent;
}

.dropdown-value {
	margin-right: 6px;
}

.dropdown-value-arrow {
	flex-shrink: 0;
	margin-left: auto;
	transition: transform 0.3s;
}

.dropdown-container-is-selecting .dropdown-value-arrow {
	transform: rotate(180deg);
}

.dropdown-container-disable-edit .dropdown-value-arrow {
	opacity: 0;
}
