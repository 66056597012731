.unit-page-subpage-biography-cluster {
	display: inline-block;
	width: calc(100% - 224px - 24px - 36px);
	vertical-align: top;
}

@media (max-width: 750px) {
	.unit-page-subpage-biography-cluster {
		width: 100%;
	}
}
