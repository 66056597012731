.unit-page-subpage-relationships-relationship-item {
	display: flex;
	margin: 4px 0;
}

.drag-drop-item-enable-drag-drop.unit-page-subpage-relationships-relationship-item-container {
	animation: wiggle-medium infinite 0.7s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).unit-page-subpage-relationships-relationship-item-container {
	animation-delay: 0.1s;
}
