.characters-group-container {
	flex-shrink: 0;
	display: inline-block;
	border-radius: 4px;
	margin-top: -8px;
	margin-bottom: 24px;
	width: 100%;
	min-height: 631px;
	vertical-align: top;
	/* animation: fade-in 0.2s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
	opacity: 0; */
}

.characters-group-container-no-groups {
	display: none;
}

.characters-group-add-first-character-container {
	margin-top: 12px;
	height: calc(635px - 12px);
}

.characters-group-add-first-character-container .first-add-btn {
	border-radius: 5px;
}

@media (max-width: 950px) {
	.characters-group-container {
		margin: 0;
		width: 100%;
		min-height: 0;
	}

	.characters-group-add-first-character-container {
		height: 150px;
	}
}
