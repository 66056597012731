.locations {
	margin: 0 auto;
	padding: 0 24px;
	padding-bottom: 48px;
	max-width: 1900px;
}

.locations-is-on-map {
	position: relative;
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: unset;
	height: 100%;
	overflow: hidden;
}

.locations-loading-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: var(--colour-grey-one);
}

.locations-loading-container-fade {
	animation: locations-loading-container-fade 0.2s linear 1.75s 1;
	animation-fill-mode: forwards;
}

@keyframes locations-loading-container-fade {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}
}

.locations-loading-loading-circle-container {
	animation: locations-loading-loading-circle-container 0.6s linear 1;
	animation-fill-mode: forwards;
}

@keyframes locations-loading-loading-circle-container {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.locations-header {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	padding: 16px;
	width: 100%;
	box-sizing: border-box;
}

.locations-content {
	display: flex;
	gap: 32px;
}

.locations-map-btn {
	--borderWidth: 2px;

	flex-shrink: 0;
	position: relative;
	z-index: 2;
	border: solid var(--borderWidth) var(--colour-grey-four);
	border-radius: 16px;

	width: 400px;
	height: 100%;
	height: calc(var(--vh) - 166px);
	overflow: hidden;

	background: transparent;
	cursor: pointer;
	color: var(--text-colour-primary);
	box-shadow: 0 0 20px transparent;
	transition: 0.4s;

	opacity: 0;
	animation: fade-in 0.6s;
	animation-delay: 0.2s;
	animation-fill-mode: forwards;
}

.locations-map-btn::after {
	display: block;
	content: "";
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 16px;
	width: 100%;
	height: 100%;
}

.locations-map-btn:hover {
	border-color: var(--colour-grey-five);
	box-shadow: 0 0 20px var(--colour-accent);
	scale: 1.008;
}

.locations-map-btn svg {
	font-size: 48px;
}

.locations-map-btn img {
	position: absolute;
	z-index: -2;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + 5px);
	min-height: calc(100% + 5px);

	--hue-rotation: var(--colour-accent-hsl-h);
	filter: sepia(1) saturate(3.5) brightness(0.6) hue-rotate(calc(1deg * (var(--hue-rotation) - 38)));
	transition: filter 0.4s;
	object-fit: cover;
}

.locations-map-btn-label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.locations-map-btn-label-text {
	font-size: 16px;
	font-weight: 500;
}

@media (max-width: 1100px) {
	.locations-map-btn {
		width: 250px;
		height: 450px;
		max-height: calc(var(--vh) - 166px);
	}
}

@media (max-width: 750px) {
	.locations {
		padding: 0 8px;
	}

	.locations-is-on-map {
		padding: 0;
	}

	.locations-content {
		flex-direction: column;
		gap: 12px;
	}

	.locations-map-btn {
		width: 100%;
		height: 140px;
	}

	.locations-map-btn img {
		filter: blur(0px) brightness(60%);
	}
}
