.alignment-input-container {
	--alignmentInputContainerWidth: 85px;
	--alignmentInputBtnLineThickness: 5px;
	border-radius: 6px;
	width: var(--alignmentInputContainerWidth);
	height: var(--alignmentInputContainerWidth);
	background: var(--colour-grey-three);
}

.alignment-input-buttons-container {
	display: flex;
	flex-wrap: wrap;
	border-radius: 6px;
	width: var(--alignmentInputContainerWidth);
	height: var(--alignmentInputContainerWidth);
	overflow: hidden;
}

.alignment-input-btn {
	position: relative;
	flex-shrink: 0;
	border: 5px solid transparent;
	border-radius: 6px;
	width: calc(var(--alignmentInputContainerWidth) / 3 - 2 * 5px);
	height: calc(var(--alignmentInputContainerWidth) / 3 - 2 * 5px);
	transition: background 0.1s;
}

.alignment-input-btn:hover {
	background: var(--colour-grey-four);
}

.alignment-input-btn-active,
.alignment-input-btn-active:hover {
	background: var(--colour-grey-five);
}

.alignment-input-btn-line {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	content: "";
	border-radius: 5px;
	width: 100%;
	height: var(--alignmentInputBtnLineThickness);
	background: var(--text-colour-secondary);
}

.alignment-input-btn-active .alignment-input-btn-line {
	background: var(--text-colour-primary);
}

.alignment-input-btn-line:nth-child(2) {
	width: var(--alignmentInputBtnLineThickness);
	height: 100%;
}

.alignment-input-btn:nth-child(5) .alignment-input-btn-lines-container .alignment-input-btn-line {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.alignment-input-btn:nth-child(3) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(2),
.alignment-input-btn:nth-child(6) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(2),
.alignment-input-btn:nth-child(9) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(2) {
	left: unset;
	right: 0;
}

.alignment-input-btn:nth-child(7) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(1),
.alignment-input-btn:nth-child(8) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(1),
.alignment-input-btn:nth-child(9) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(1) {
	top: unset;
	bottom: 0;
}

.alignment-input-btn:nth-child(2) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(2),
.alignment-input-btn:nth-child(8) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(2) {
	display: none;
}

.alignment-input-btn:nth-child(4) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(1),
.alignment-input-btn:nth-child(6) .alignment-input-btn-lines-container .alignment-input-btn-line:nth-child(1) {
	display: none;
}
