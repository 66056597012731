.unit-page-primary-story {
	position: fixed;
	top: 6px;
	display: flex;
	align-items: center;

	width: max-content;
	height: 32px;
}

.unit-page-primary-story-icon {
	margin-right: 12px;
	border-radius: 100%;
	width: 28px;
	height: 28px;
}

.unit-page-primary-story-icon img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.unit-page-primary-story-name {
	width: max-content;
	font-size: 14px;

	color: var(--text-colour-primary);
}

@media (max-width: 750px) {
	.unit-page-primary-story {
		display: none;
	}
}
