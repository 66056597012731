.pop-up-container-hidden {
	display: none;
}

.pop-up-container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999999;

	width: 100vw;
	height: var(--vh);
}

.pop-up-container-open .pop-up-background {
	animation: pop-up-container-open-pop-up-background 0.4s;
	animation-fill-mode: forwards;
	backdrop-filter: blur(6px);
}

@keyframes pop-up-container-open-pop-up-background {
	0% {
		opacity: 0;
		backdrop-filter: blur(6px);
	}
	100% {
		opacity: 1;
		backdrop-filter: blur(6px);
	}
}

.pop-up-container-close .pop-up-background {
	animation: pop-up-container-close-pop-up-background 0.2s;
	animation-fill-mode: forwards;
}

@keyframes pop-up-container-close-pop-up-background {
	0% {
		opacity: 1;
		backdrop-filter: blur(6px);
	}
	100% {
		opacity: 0;
		backdrop-filter: blur(6px);
	}
}

.pop-up-container-open .pop-up-content-container {
	animation: pop-up-container-open-pop-up-content 0.4s;
	animation-fill-mode: forwards;
}

@keyframes pop-up-container-open-pop-up-content {
	0% {
		scale: 0.5;
		opacity: 0;
	}
	100% {
		scale: 1;
		opacity: 1;
	}
}

.pop-up-container-close .pop-up-content-container {
	animation: pop-up-container-close-pop-up-content 0.2s;
}

@keyframes pop-up-container-close-pop-up-content {
	0% {
		scale: 1;
		opacity: 1;
	}
	100% {
		scale: 0.5;
		opacity: 0;
	}
}

.pop-up-content-container {
	z-index: 200;
	border-radius: 4px;

	width: calc(100vw - 76px - 48px);
	max-width: 750px;
	height: 680px;
	max-height: calc(var(--vh) - 24px);
	overflow-y: overlay;
}

.pop-up-content-container::-webkit-scrollbar {
	width: 6px;
	background: transparent;
}

.pop-up-content-container::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--colour-scroll-bar-thumb-background);
}

.pop-up-content {
	padding: 16px 20px;
	border-radius: 8px;

	/* width: calc(100% - (34px * 2)); */
	--popupcontent-height: min(calc(680px - 48px - 24px), calc(var(--vh) - 24px - 48px - 24px));

	background: var(--colour-grey-two);
}

.pop-up-content-primary-container {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	padding: 12px 0;
}

.pop-up-content-primary-title {
	margin: 0;
	font-size: 20px;
	font-weight: 500;
}

.pop-up-content-primary-btn-container {
	margin-left: auto;
}

.pop-up-background {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 199;
	display: block;
	content: "";

	width: 100vw;
	height: var(--vh);

	background: #00000088;
	backdrop-filter: blur(6px);
}

@media (max-width: 750px) {
	.pop-up-content-container {
		width: calc(100vw - 24px);
		height: calc(600px - 24px);
		max-height: calc(var(--vh) - 24px);
	}

	.pop-up-content {
		padding: 12px 12px;
		width: calc(100% - (12px * 2));
		--popupcontent-height: min(calc(600px - 24px - (12px * 2)), calc(var(--vh) - 48px - (12px * 2)));
	}

	.pop-up-content-primary-title {
		font-size: 22px;
	}
}
