.home-search-container {
	position: fixed;
	z-index: 1;
	top: 12px;
	left: 50%;
	transform: translateX(calc(-50% + (var(--navbar-width) / 2)));
	width: calc(100% - var(--navbar-width));
	max-width: 1550px;
	text-align: center;
}

.home-search-container .search-input {
	padding: 10px 22px;
}

.home-search-container .search-input-placeholder-container {
	left: 24px;
}

@media (max-width: 750px) {
	.home-search-container {
		top: 6px;
		transform: translateX(-50%);
		padding-right: 6px;
		width: calc(100vw - 6px);
	}
}
