.unit-page-subpage-plot-item-groups-container {
	display: inline-block;
	margin-right: 24px;

	width: calc(356px - 24px);

	vertical-align: top;
}

.unit-page-subpage-plot-item-groups {
	padding-right: 12px;
	width: 100%;
}

@media (max-width: 1200px) {
	.unit-page-subpage-plot-item-groups-container {
		position: absolute;
		z-index: 1000;
		background: var(--colour-grey-one);
		top: 51px;
		margin-right: 0;
		padding: 0;
		width: 100%;
		height: 0;
		overflow: hidden;
		opacity: 0;
		transition: 0.2s;
	}

	.unit-page-subpage-plot-item-groups-container-displaying {
		height: calc(var(--vh) - var(--unitPagePaddingTop) - 36px - 6px - 107px);
		opacity: 1;
	}

	.unit-page-subpage-plot-item-groups {
		max-height: calc(var(--vh) - var(--unitPagePaddingTop) - 36px - 6px - 51px - 76px);
	}
}
