.story-settings-pronunciations-list-header {
	position: relative;
	display: flex;
	gap: 6px;
	margin-right: 8px;
	font-weight: 500;
}

.editable-container-is-editing.story-settings-pronunciations-container .story-settings-pronunciations-list-header {
	padding-right: calc(6px + 40px + 6px + 40px);
}

.story-settings-pronunciations-list-header::after {
	position: absolute;
	bottom: -1px;
	display: block;
	content: "";
	width: 100%;
	height: 2px;
	background: var(--colour-grey-four);
}

.editable-container-is-editing.story-settings-pronunciations-container .story-settings-pronunciations-list-header::after {
	display: none;
}

.story-settings-pronunciations-list {
	max-height: 250px;
	padding-bottom: 2px;
	padding-right: 8px;
	overflow-y: auto;
}

.story-settings-pronunciations-item-container {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	gap: 6px;
}

.story-settings-pronunciations-item-container::after {
	position: absolute;
	bottom: -1px;
	display: block;
	content: "";
	width: 100%;
	height: 2px;
	background: var(--colour-grey-four);
}

.story-settings-pronunciations-item-container:last-child:after,
.editable-container-is-editing.story-settings-pronunciations-container .story-settings-pronunciations-item-container::after {
	display: none;
}

.story-settings-pronunciations-item-value {
	padding: 8px 0;
	width: 50%;
}

.story-settings-pronunciations-item-container .story-settings-pronunciations-item-value {
	font-size: 13px;
}

.story-settings-pronunciations-item-input-container {
	width: 50%;
}

.story-settings-pronunciations-item-input-container .text-input-container {
	padding: 8px;
	border-radius: 5px;
	--horizontalPadding: 8px;
}

.story-settings-pronunciations-item-remove-btn .icon-btn {
	background: var(--colour-red);
}

.story-settings-pronunciations-item-remove-btn .icon-btn:hover {
	background: var(--colour-red-tint);
}

.story-settings-pronunciations-item-hear-btn-container-loading {
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}
