.unit-page-subpages-btns-container {
	display: flex;
	gap: 12px;
	justify-content: center;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 12px;
	margin-left: calc(8px + 8px);
	width: max-content;
	width: 100%;
}

.unit-page-subpages-btns {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	overflow-y: hidden;
	overflow-x: overlay;
	scroll-behavior: smooth;
	padding-right: 8px;
}

.unit-page-subpages-btns::-webkit-scrollbar {
	height: 0;
}

.unit-page-subpages-btn {
	flex: 1 0;
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: center;
	align-items: center;
	min-width: 110px;
	width: 110px;
	color: var(--text-colour-tertiary);
	transition: 0.2s;
	cursor: pointer;
}

.unit-page-container-subpages-header-not-full-size .unit-page-subpages-btn {
	gap: 0;
}

.unit-page-subpages-btn:hover {
	color: var(--text-colour-secondary);
}

.unit-page-subpages-btn-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 28px;
	transition: 0.2s;
}

.unit-page-container-subpages-header-not-full-size .unit-page-subpages-btn .unit-page-subpages-btn-icon {
	flex-shrink: 1;
	min-height: 0;
	height: 0px;
	overflow: hidden;
	opacity: 0;
}

.unit-page-subpages-btn svg {
	font-size: 28px;
}

.unit-page-subpages-btn-relationships svg {
	font-size: 29px;
}

.unit-page-subpages-btn-name {
	font-size: 13px;
	user-select: none;
}

.unit-page-subpages-btn-active,
.unit-page-subpages-btn-active:hover {
	color: var(--text-colour-primary);
}

/* .unit-page-subpages-btn {
	flex-shrink: 0;
	margin: 0;
	padding-right: 28px;
	border-radius: 14px;

	width: max-content;
}

.unit-page-subpages-btn .btn-list-item-value {
	font-size: 13px;
	white-space: pre-wrap;
	word-break: break-word;
	overflow-wrap: anywhere;
}

.unit-page-subpages-btn.btn-list-item-active,
.unit-page-subpages-btn.btn-list-item-active:hover {
	background: linear-gradient(0deg, var(--unitColour), var(--unitColourTint));
	color: #fff;
} */

.unit-page-subpages-btn-scroll-btn {
	display: none;
}

@media (max-width: 750px) {
	.unit-page-subpages-btns-container {
		margin: 0;
		gap: 0;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.unit-page-subpages-btns {
		justify-content: flex-start;
		width: calc(100% - 28px - 28px);
		flex-direction: row;
	}

	.unit-page-subpages-btn {
		padding-right: 15px;
		min-width: fit-content;
		width: calc((100% / 8) - 8px);
	}

	.unit-page-subpages-btns-is-authorized .unit-page-subpages-btn {
		width: calc((100% / 9) - 8px);
	}

	.unit-page-subpages-btn-scroll-btn {
		display: block;
	}
}
