.user-header-followers-buttons-container {
	display: inline-block;
	margin-left: auto;
	margin-right: 12px;
	vertical-align: middle;
}

.user-header-followers-btn {
	margin: 0 6px;
	border: 0;
	background: transparent;
	color: #aaa;
	transition: 0.1s;
	cursor: pointer;
}

.user-header-followers-btn:hover {
	color: #fff;
}

.user-header-followers-btn span {
	margin-right: 1px;
	font-weight: 600;
	color: #fff;
}

@media (max-width: 750px) {
	.user-header-followers-buttons-container {
		order: 2;
		margin-right: 0;
	}

	.user-header-followers-btn {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
	}

	.user-header-followers-btn span {
		margin-right: 0;
		font-size: 16px;
	}
}
