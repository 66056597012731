.story-primary-character-card,
.story-primary-character-card-placeholder {
	flex-shrink: 0;
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 8px;
	border: 5px solid var(--colour-accent);
	border-color: var(--characterColour);
	border-radius: 16px;

	width: 305px;
	height: 420px;
	overflow: hidden;

	background: var(--colour-grey-one);
	transition: scale 0.2s;
	cursor: pointer;
}

.story-primary-character-card:hover {
	scale: 1.02;
}

.story-primary-character-card-placeholder {
	margin-bottom: 0;
	animation: story-primary-character-card-placeholder 1.5s infinite;
}

@keyframes story-primary-character-card-placeholder {
	0% {
		border-color: var(--colour-grey-two);
		background: var(--colour-grey-two);
	}
	15%,
	55% {
		border-color: var(--colour-grey-four);
		background: var(--colour-grey-three);
	}
	88%,
	100% {
		border-color: var(--colour-grey-two);
		background: var(--colour-grey-two);
	}
}

.drag-drop-item-enable-drag-drop .story-primary-character-card {
	animation: wiggle-medium infinite 0.5s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even) .story-primary-character-card {
	animation-delay: 0.1s;
}

.story-primary-character-card-content {
	position: relative;
	z-index: 1;
	height: 100%;
}

.story-primary-character-card-top-container {
	display: flex;
	align-items: center;
	padding: 12px 20px;
}

.story-primary-character-card-top-name {
	font-size: 24px;
	font-weight: 600;
	color: var(--characterCardNameColour);
}

.story-primary-character-card-character-type {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	padding: 6px 12px;
	border-radius: 32px;
	height: fit-content;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	background: linear-gradient(0deg, var(--characterTypeColourGradient1), var(--characterTypeColourGradient2));
}

.story-primary-character-card-summary-items-container {
	position: absolute;
	top: 224px;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 0 16px;

	width: calc(100% - 16px);
	max-height: calc(100% - 224px);
	overflow: hidden;
}

.story-primary-character-card-summary-item {
	margin-bottom: 8px;
	padding: 8px 12px;
	border-radius: 8px;

	width: calc(100% - 24px);

	background: var(--characterColour);
	background: linear-gradient(0deg, var(--characterColourGradient1), var(--characterColourGradient2) 130%);
	color: #fff;
	text-align: left;
}

.story-primary-character-card-summary-item-label {
	font-size: 12px;
	font-weight: 400;
}

.story-primary-character-card-summary-item-text {
	font-size: 12px;
	font-weight: 600;
}

.font-size-s .story-primary-character-card-summary-item-label,
.font-size-s .story-primary-character-card-summary-item-text,
.font-size-xs .story-primary-character-card-summary-item-label,
.font-size-xs .story-primary-character-card-summary-item-text {
	font-size: 11px;
}

.story-primary-character-card-background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: inherit;

	width: 100%;
	height: 100%;
}

.story-primary-character-card-background img {
	transform: translate(-6px, -5px);

	border: 5px solid var(--colour-accent);
	border-color: var(--characterColour);
	border-radius: inherit;

	width: calc(100% + 2px);
	height: 100%;

	object-fit: cover;
	-webkit-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
}

@media (max-width: 750px) {
	.story-primary-character-card,
	.story-primary-character-card-placeholder {
		border-width: 4px;

		width: calc(100vw - 24px - 10px - 24px);
		max-width: min(441px * 0.7, calc((var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px) * (441 / 607)));
		height: calc((100vw - 24px - 10px - 24px) * (607 / 441));
		max-height: min(607px * 0.7, calc(var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px));
	}

	.story-primary-character-card-top-container {
		padding: 8px 12px;
	}

	.story-primary-character-card-top-name {
		font-size: 22px;
	}

	.story-primary-character-card-character-type {
		margin-top: 1px;
		margin-bottom: -1px;
		padding: 6px 10px;
		font-size: 12px;
	}

	.story-primary-character-card-summary-items-container {
		top: 54%;
		max-height: calc(100% - 54% + 1px);
	}

	.story-primary-character-card-summary-item-label {
		font-size: 12px;
	}

	.story-primary-character-card-summary-item-text {
		font-size: 12px;
	}
}
