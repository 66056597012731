.unit-page-subpage-plot-item-container {
	position: relative;
}

.unit-page-subpage-plot-item-container::after {
	position: absolute;
	bottom: 1px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-three);
}

.unit-page-subpage-plot-items-container-all .unit-page-subpage-plot-item-container:nth-last-child(2):after,
.unit-page-subpage-plot-items-container:not(.unit-page-subpage-plot-items-container-all) .unit-page-subpage-plot-item-container:last-child:after {
	display: none;
}

.unit-page-subpage-plot-items-container-all .unit-page-subpage-plot-item-container:nth-last-child(2) {
	padding-bottom: 34px;
}

.drag-drop-item-enable-drag-drop .unit-page-subpage-plot-item {
	-webkit-user-drag: element;
	user-select: none;
}

.drag-drop-item-enable-drag-drop.unit-page-subpage-plot-item-container {
	animation: wiggle-very-small infinite 0.7s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).unit-page-subpage-plot-item-container {
	animation-delay: 0.1s;
}

.unit-page-subpage-plot-item {
	position: relative;
	margin: 0;
}

/* Item Label */

.unit-page-subpage-plot-item .editable-item-item-single-line-text {
	margin-bottom: 3px;
}

.unit-page-subpage-plot-item .editable-item-item-single-line-text,
.unit-page-subpage-plot-item .editable-item-item-single-line-text .text-input-label,
.unit-page-subpage-plot-item .editable-item-item-single-line-text input {
	font-size: var(--font-size-h2);
	font-weight: 600;
}

.unit-page-subpage-plot-item:not(.editable-item-container-is-editing) .editable-item-item-single-line-text {
	margin-top: 1px;
}

/* OLD */

/* .unit-page-subpage-plot-item .content-item {
	margin: 0;
	margin-top: 8px;
}

.unit-page-subpage-plot-item-container-0,
.unit-page-subpage-plot-item-container-0 .content-item {
	margin-top: 0;
} */
