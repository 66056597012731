.landing-hero-description {
	margin: 48px auto;
	width: calc(100% - 48px);
	max-width: 1000px;
	color: var(--text-colour-primary);
	font-size: var(--font-size-h2);
	font-weight: 500;
	line-height: calc(var(--font-size-p-m-height) + 2px);
}

@media (max-width: 756px) {
	.landing-hero-description {
		margin-left: 6px;
		margin-right: 6px;
	}
}
