.unit-page-storyboard-player-timeline-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: calc(100% - 16px);
	height: 12px;
	cursor: pointer;
}

.unit-page-storyboard-player-container-is-editor-player .unit-page-storyboard-player-timeline-container {
	width: 100%;
}

.unit-page-storyboard-player-timeline {
	display: block;
	border-radius: 24px;
	overflow: hidden;
	width: 100%;
	height: 4px;
	background: var(--colour-grey-two);
	transition: 0.1s;
}

.unit-page-storyboard-player-timeline-container:hover .unit-page-storyboard-player-timeline {
	height: 8px;
}

.unit-page-storyboard-player-timeline-progress {
	display: block;
	width: var(--player_percent_watched);
	height: 100%;
	background: var(--colour-accent);
}
