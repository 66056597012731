.locations-location-container {
	/* --locationsDetailsHeight: calc(100% * 0.72 - 8px); */
	--locationsDetailsHeight: calc(100dvh - (2 * 10px));
	position: absolute;
	z-index: 3;
	right: 0px;
	display: flex;
	flex-direction: column;
	padding: 10px 16px;

	width: calc(100% - 8px - 46px - 2 * 16px);
	height: var(--locationsDetailsHeight);
}

.locations-location-container::before {
	position: absolute;
	z-index: -2;
	top: 0;
	left: 0;

	display: block;
	content: "";
	border: 1px solid var(--colour-grey-four);
	border-top: 0;
	border-bottom: 0;
	border-right: 0;

	width: calc(100% - 1px);
	height: 100%;

	background: var(--colour-grey-one);
}

.locations-location-container::after {
	position: absolute;
	z-index: -1;
	top: -4px;
	left: 0;
	border-radius: 2px;

	display: block;
	content: "";

	width: 100%;
	height: 1px;

	background: var(--colour-grey-four);
}

.locations-location {
	display: flex;
	flex-direction: column;
	height: var(--locationsDetailsHeight);
	overflow: hidden;
}

.locations-location-title {
	margin-bottom: 4px;
	font-size: var(--font-size-p-m);
	font-weight: 600;
}

@media (max-width: 768px) {
	.locations-location-container {
		--locationsDetailsHeight: calc(100% - 16px);
		position: absolute;
		left: calc(40px + 8px);
		padding: 16px;
		padding-top: 0;
		z-index: 2;

		width: calc(100vw - (2 * 16px) - 8px - 8px - 40px);
		max-height: var(--locationsDetailsHeight);
	}
}
