.characters-character-type-colour-container {
	margin: 12px 0;
	margin-left: 4px;
}

.characters-character-type-colour {
	display: flex;
	align-items: center;
	margin: 0;
}

.characters-character-type-colour-block {
	margin-right: 12px;
	border-radius: 4px;

	min-width: 32px;
	width: 32px;
	min-height: 32px;
	height: 32px;
}
