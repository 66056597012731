.unit-page-subpage-settings-card-background-container {
	margin-right: 6px;
	margin-bottom: 12px;
}

.unit-page-subpage-settings-card-background-image {
	display: block;
}

.unit-page-subpage-settings-card-background-image-image-container {
	position: relative;
	margin: 0 auto;
	margin-top: 12px;
	border-radius: 4px 4px 0 0;
	width: calc(412px * 0.7);
	height: calc(585px * 0.7);
	overflow: hidden;
}

.unit-page-subpage-settings-card-background-image-image-no-image {
	width: calc(412px * 0.7);
	height: calc(585px * 0.7);
}

.unit-page-subpage-settings-card-background-image-image-alignment {
	border-radius: inherit;
	min-width: 100%;
	min-height: 100%;
}

.unit-page-subpage-settings-card-background-image-image-img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: flex;
	overflow: visible;
	transform-origin: top;
	scale: var(--scale);
}

.unit-page-subpage-settings-card-background-image-image .image-input {
	transform-origin: top;
	scale: var(--scale);
}

.unit-page-subpage-settings-card-background-image-image-size {
	position: absolute;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
	user-select: none;

	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: flex;
	overflow: visible;
	transform-origin: top;
	scale: var(--scale);
}

.unit-page-subpage-settings-card-background-image-image-alignment-top-left {
	position: absolute;
	top: 0;
	left: 0;
}

.unit-page-subpage-settings-card-background-image-image-alignment-top {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-subpage-settings-card-background-image-image-alignment-top-right {
	position: absolute;
	top: 0;
	right: 0;
}

.unit-page-subpage-settings-card-background-image-image-alignment-center-left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.unit-page-subpage-settings-card-background-image-image-alignment-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.unit-page-subpage-settings-card-background-image-image-alignment-center-right {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.unit-page-subpage-settings-card-background-image-image-alignment-bottom-left {
	position: absolute;
	bottom: 0;
	left: 0;
}

.unit-page-subpage-settings-card-background-image-image-alignment-bottom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.unit-page-subpage-settings-card-background-image-image-alignment-bottom-right {
	position: absolute;
	bottom: 0;
	right: 0;
}

@media (max-width: 750px) {
	.unit-page-subpage-settings-card-background-image-image-container,
	.unit-page-subpage-settings-card-background-image-image-no-image {
		border-width: 5px;

		width: calc(100vw - 24px - 10px - 24px);
		max-width: min(calc(441px * 0.7), calc((var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px) * (441 / 607)));
		height: calc((100vw - 24px - 10px - 24px) * (607 / 441));
		max-height: min(calc(607px * 0.7), calc(var(--vh) - var(--navbar-mobile-height) - 8px - 12px - 10px - 24px - 39px - 24px));
	}
}
