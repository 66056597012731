.story-primary-characters-cards-container {
	position: relative;
	margin-top: -5px;
}

.story-primary-characters-cards {
	display: flex;
	width: 100%;
}

@media (max-width: 950px) {
	.story-primary-characters-cards-container {
		margin-top: 12px;
	}
}
