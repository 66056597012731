.login {
	position: relative;
	width: 100%;
}

.login-form {
	margin: 80px auto;
	margin-top: 14vh;
	padding: 24px;
	border-radius: 4px;

	width: calc(100% - 48px - 24px);
	max-width: 600px;

	text-align: center;
}

.login-form-title {
	margin-bottom: 24px;
	font-size: 28px;
	font-weight: 600;
	text-align: left;
}

.login-form-text-input-container {
	margin: 10px 0;
}

.login-form-submit-container {
	display: flex;
	align-items: center;
	justify-content: right;
	margin-top: 20px;
}

.login-form-submit-loading-circle-container {
	margin-right: 24px;
}

.login-form-forgot-password-container {
	margin: 24px 0;
}

.login-form-forgot-password {
	margin-left: auto;
	width: fit-content;
	font-size: 15px;
	color: var(--text-colour-secondary);
	transition: colour 0.1s;
	cursor: pointer;
}

.login-form-forgot-password:hover {
	color: var(--colour-blue-two);
}

@media (max-width: 750px) {
	.login-form {
		margin-top: 24px;
	}
}
