.user-header-buttons-container {
	display: inline-block;
	margin-right: 12px;
	vertical-align: middle;
}

.user-header-btn {
	margin: 0 6px;
}

.user-header-btn:last-child {
	margin-right: 0;
}

.user-header-follow-btn {
	margin: 0 6px;
	border: 0;
	border-radius: 32px;

	width: 96px;
	height: 36px;

	background: linear-gradient(180deg, #fff, #fff);
	color: #444;
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	transition: 0.2s;
}

.user-header-follow-btn:hover {
	background: linear-gradient(180deg, var(--colour-blue-tint), var(--colour-blue));
	color: #fff;
}

.user-header-follow-btn-is-following {
	background: linear-gradient(180deg, var(--colour-blue-tint), var(--colour-blue));
	color: #fff;
}

.user-header-follow-btn-is-following:hover {
	background: linear-gradient(180deg, var(--colour-red-tint), var(--colour-red));
}

.user-header-follow-btn-text-following {
	display: none;
}

.user-header-follow-btn-text-unfollow {
	display: none;
}

.user-header-follow-btn-is-following .user-header-follow-btn-text-follow {
	display: none;
}

.user-header-follow-btn-is-following .user-header-follow-btn-text-following {
	display: block;
}

.user-header-follow-btn-is-following:hover .user-header-follow-btn-text-following {
	display: none;
}

.user-header-follow-btn-is-following:hover .user-header-follow-btn-text-unfollow {
	display: block;
}

.user-header-block-btn {
	margin: 0 6px;
	border: 0;
	border-radius: 100%;

	background: var(--colour-grey-four);
	text-align: center;
	cursor: pointer;
	transition: 0.2s;
}

.user-header-block-btn:hover {
	background: var(--colour-red);
	fill: #fff;
}

.user-header-block-btn .icon-btn {
	--iconBtnWidth: 36px !important;
}

.user-header-block-btn svg {
	fill: var(--text-colour-tertiary);
	transition: 0.1s;
}

.user-header-block-btn:hover svg {
	fill: #fff;
}

.user-header-block-btn-has-blocked {
	background: var(--colour-red);
	fill: #fff;
}

.user-header-block-btn-has-blocked svg {
	fill: #fff;
}

.user-header-block-btn-has-blocked:hover {
	background: var(--colour-blue);
	fill: #fff;
}

@media (max-width: 750px) {
	.user-header-buttons-container {
		order: 4;
		margin: 12px;
		margin-left: auto;
		margin-right: 0;
	}

	.user-header-btn {
		margin: 2px 8px;
	}
}
