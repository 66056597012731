.story-settings-members-container {
	margin-top: 12px !important;
}

.story-settings-members-loading-container {
	margin: 8px 0;
	height: 50px;
}

.story-settings-members-list-container {
	padding: 2px 2px;
	padding-bottom: 4px;
}

.story-settings-members-list {
	max-height: 336px;
	overflow-y: scroll;
}

@media (max-width: 750px) {
	.story-settings-members-list-container {
		padding: 0;
		padding-bottom: 3px;
	}

	.story-settings-members-users-search-container {
		margin-left: 0;
		width: 100%;
	}
}
