.characters-create-character-type-container .pop-up-content-container {
	overflow-x: hidden;
}

.characters-create-character-type-form {
	display: flex;
	gap: 32px;
	flex-direction: column;
}

.characters-create-character-type-form-input-container {
	margin: 0;
}

.characters-create-character-type-form-input-label {
	margin-bottom: 4px;
	font-size: var(--font-size-p-s);
	font-weight: 600;
	color: var(--text-colour-secondary);
}

.characters-create-character-type-form-input-label {
	margin-bottom: 8px;
}

.characters-create-character-type-form-submit-container {
	text-align: right;
}

.characters-create-character-type-form-submit-btn-text {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}

.characters-create-character-type-form-submit-btn-icon {
	display: inline-block;
	margin-top: 1px;
	margin-left: 8px;
	vertical-align: middle;
	font-size: 15px;
}
