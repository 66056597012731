.user-banner-container {
	display: block;
	margin-top: -6px;
	width: 100%;
	height: min(20vw, 324px);
	background: var(--colour-grey-two);
}

.user-banner {
	width: 100%;
	height: 100%;
}

.user-banner img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 750px) {
	.user-banner-container {
		margin-top: -6px;
		margin-left: -6px;
		width: calc(100% + 12px);
		max-width: calc(100% + 12px);
		height: 120px;
	}

	.user-banner {
		border-radius: 0px;
	}
}
