.change-forgotten-password {
	position: relative;
	width: 100%;
}

.change-forgotten-password-message {
	margin: 80px auto;
	margin-top: 8vh;
	padding: 24px;
	border-radius: 4px;

	width: calc(100% - 48px - 24px);
	max-width: 600px;

	text-align: center;
}

.change-forgotten-password-message-icon {
	margin-bottom: 10px;
	font-size: 48px;
	color: var(--colour-green);
}

.change-forgotten-password-message-title {
	font-size: 24px;
	font-weight: 600;
}

.change-forgotten-password-message-input {
	margin: 32px 0;
}

.change-forgotten-password-message-error {
	margin: 16px 0;
	font-size: 14px;
	color: var(--colour-red);
}

.change-forgotten-password-message-error:last-child {
	margin-bottom: 0;
}
