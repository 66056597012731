.text-container {
	color: var(--text-colour-primary);
	white-space: pre-wrap;
	word-break: break-word;
	overflow-wrap: anywhere;
	font-size: var(--font-size-p-m);
	line-height: var(--font-size-p-m-height);
	display: grid;
	gap: 0;
}

.text-container-light-text {
	color: #fff;
}

.text-container h1 {
	margin: 4px 0;
	margin-top: calc(4px + 12px);
	font-size: var(--font-size-h1);
}

.text-container h2 {
	margin: 4px 0;
	margin-top: calc(4px + 12px);
	font-size: var(--font-size-h2);
}

.text-container h3 {
	margin: 4px 0;
	margin-top: calc(4px + 12px);
	font-size: var(--font-size-h3);
}

.text-container h4 {
	margin: 4px 0;
	margin-top: calc(4px + 12px);
	font-size: var(--font-size-h4);
}

.text-container h5 {
	margin: 4px 0;
	margin-top: calc(4px + 12px);
	font-size: var(--font-size-h5);
}

.text-container h6 {
	margin: 4px 0;
	margin-top: calc(4px + 12px);
	font-size: var(--font-size-h6);
	font-weight: 400;
	text-decoration: underline;
}

.text-container p {
	margin: 0;
}

.text-container ul,
.text-container ol {
	display: grid;
	gap: 5px;
	margin: 5px 0;
	padding-left: 20px;
}

.text-container li ul,
.text-container li ol {
	margin-bottom: -17px;
}

.text-container br {
	display: block;
	content: "";
	margin-bottom: -6px;
}

.text-container .line-break {
	display: block;
	content: "";
	margin-bottom: -6px;
}
