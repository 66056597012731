.home-genres-list-container {
	display: inline-block;
	vertical-align: top;
}

.home-genres-list {
	width: 224px;
	margin-right: auto;
}

.home-genres-list-title {
	margin-bottom: 16px;
	font-size: 32px;
	font-weight: 600;
}

.home-genres-content {
	padding-right: 12px;
	width: calc(100% - 12px);
	max-height: calc(var(--vh) - (12px + 42px + 12px) - 26px - 8px - 12px - 12px - 9px - 8px);
	overflow-y: overlay;
	overflow-x: hidden;
}

.home-genres-items {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.home-genres-list-search-container {
	margin-top: 16px;
	margin-bottom: 16px;
}

.home-genres-list-search-container .text-input {
	width: 100%;
}

.home-genres-list-loading-container {
	position: relative;
	width: 100%;
	height: 80px;
}

.home-genres-list-new-genre-container {
	display: flex;
	padding: 8px;
}

.home-genres-list-new-genre-label {
	margin-right: 8px;
	font-size: 14px;
	color: var(--colour-light-green);
}

.home-genres-list-new-genre-label-value {
	font-weight: 600;
}

@media (max-width: 750px) {
	.home-genres-list-container {
		margin-left: 0;
		width: 100%;
	}

	.home-genres-list {
		width: 100%;
		margin-right: 0;
	}

	.home-genres-content {
		padding-right: 0;
		width: 100%;
		max-height: unset;
	}

	.home-genres-list-title {
		margin-left: 0;
		margin-bottom: 12px;
		padding: 0 8px;
		box-sizing: border-box;
		font-size: 24px;
	}

	.home-genres-items {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 8px;
		padding-top: 0;

		box-sizing: border-box;
		width: 100%;
	}

	.home-genres-items::-webkit-scrollbar {
		border-radius: 4px;
		height: 6px;
		background: transparent;
		background: var(--colour-grey-one);
	}

	.home-genres-items::-webkit-scrollbar-thumb:horizontal {
		border-radius: 4px;
		background: var(--colour-blue);
	}

	.home-genres-list-search-container {
		margin-top: 0;
		margin-bottom: 8px;
		padding: 8px;

		box-sizing: border-box;
		width: 100%;
		max-width: 350px;
	}
}
