.unit-page-subpage-relationships-relationship-container {
	position: relative;
	z-index: 1;
	display: inline-block;
	vertical-align: top;
	width: 400px;
}

@media (max-width: 1100px) {
	.unit-page-subpage-relationships-relationship-container {
		position: absolute;
		top: 0;
		right: 0;
		margin-left: auto;
		height: calc(var(--unitPageSubpageMaxHeight) - 24px - 8px);
		width: calc(100%);
		display: none;
	}

	.unit-page-subpage-relationships-relationship {
		min-height: calc(100% - 16px);
	}

	.unit-page-subpage-relationships-relationship-container-is-displaying {
		display: block;
	}

	.unit-page-subpage-relationships-relationship-container .unit-page-relationship-relationship {
		margin: 0;
		padding: 12px;
	}
}

@media (max-width: 750px) {
	.unit-page-subpage-relationships-relationship-container {
		width: calc(100vw - 8px - 6px);
	}
}
