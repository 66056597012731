.home-stories-recommended-container {
	padding-bottom: 2px;
	width: 100%;
}

.home-stories-recommended-title {
	margin-bottom: 16px;
	font-size: 32px;
	font-weight: 600;
}

.home-stories-recommended-loading-circle-container {
	position: relative;
	height: 480px;
}

.home-stories-popular-list {
	display: flex;
}

.home-stories-recommended-list-item-container-hidden {
	position: absolute;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.home-stories-recommended-list {
	display: flex;
	gap: 8px;
	padding-bottom: 8px;

	width: 100%;
}

@media (max-width: 900px) {
	.home-stories-recommended-list-item-container-extra-space {
		display: none;
	}
}

@media (max-width: 750px) {
	.home-stories-recommended-title {
		margin-bottom: 12px;
		padding: 0 8px;
		box-sizing: border-box;
		font-size: 24px;
	}

	.home-stories-recommended-loading-circle-container {
		height: 305px;
	}

	.home-stories-recommended-list {
		flex-wrap: nowrap;
	}
}

@media (max-width: 484px) {
	.home-stories-recommended-loading-circle-container {
		height: calc(((((50vw - 22px) * 3) / 2) - 30px) + (5px * 2));
	}
}
