.story-genres-container {
	margin: 0;
}

.story-genres-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -3px;
	width: calc(100% + 6px);
}

.editable-container-is-editing .story-genres-list {
	margin-left: -4px;
	width: calc(100% + 8px);
}

.story-genres-item-placeholder {
	display: flex;
	align-items: center;
	margin: 3px;
	padding: 5px 10px;
	border-radius: 18px;

	width: 60px;
	min-height: 15px;
}

.story-genres-item {
	display: flex;
	align-items: center;
	margin: 3px;
	padding: 5px 10px;
	border-radius: 18px;

	background: var(--colour-grey-four);
	transition: background 0.1s;
}

.editable-container-is-editing .story-genres-item {
	margin: 4px;
	padding: 4px 10px;
	padding-right: 4px;
	min-width: min(calc(115px), calc(50% - 6px - 10px - 4px));
}

.drag-drop-item-enable-drag-drop.story-genres-item {
	user-select: none;
	animation: wiggle-large infinite 0.7s linear;
}

.drag-drop-item-enable-drag-drop:nth-child(even).story-genres-item {
	animation-delay: 0.2s;
}

.editable-container-is-editing .story-genres-item:hover {
	background: var(--colour-grey-five);
}

.story-genres-item-active {
	background: linear-gradient(0deg, var(--storyColour), var(--storyColourTint));
	color: #fff;
}

.editable-container-is-editing .story-genres-item-active:hover {
	background: linear-gradient(0deg, var(--storyColourTint), var(--storyColourTint));
}

.story-genres-item-title {
	font-size: 14px;
	line-height: 15px;
	text-align: left;
}

.story-genres-item-info {
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 1px;
}

.story-genres-item-stat {
	display: flex;
	align-items: center;
	margin-right: 8px;
}

.story-genres-item-stat:last-child {
	margin-right: 0;
}

.story-genres-item-stat svg {
	font-size: 13px;
}

.story-genres-item-stat-value {
	margin-left: 2px;
	font-size: 12px;
	font-weight: 600;
}

.story-genres-item-remove-btn-container,
.story-genres-item-add-btn-container {
	margin-left: auto;
	padding-left: 4px;
}

.story-genres-item-remove-btn-container .icon-btn svg {
	fill: #fff;
}

.story-genres-search-container {
	margin-top: calc(4px + 8px);
}

.story-genres-new-genre-container {
	display: flex;
	align-items: center;
	padding: 8px;
}

.story-genres-new-genre-label {
	margin-right: 8px;
	font-size: 14px;
	color: var(--colour-light-green);
}

.story-genres-new-genre-label-value {
	font-weight: 600;
}
