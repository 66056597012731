.unit-page-subpage-custom-items {
	display: inline-block;
	flex-flow: column wrap;
	width: 100%;
}

@media (max-width: 750px) {
	.unit-page-subpage-custom-items {
		height: calc(var(--unitPageSubpageMaxHeight) - 4px);
	}
}
