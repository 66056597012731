.characters-relationship-info-home-groups-title {
	margin-bottom: 6px;
	font-size: 16px;
	font-weight: 600;
}

.characters-relationship-info-home-groups-items {
	display: flex;
	flex-direction: column;
	gap: 6px;
	padding-right: 12px;
	height: 300px;
	overflow-y: overlay;
}

@media (max-height: 750px) {
	.characters-relationship-info-home-groups-items {
		height: 250px;
	}
}
