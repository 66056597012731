.image-input {
	border-radius: 100%;
	width: 100%;
	height: 100%;
}

.image-input-circular {
	width: 74px;
	height: 74px;
}

.image-input-value {
	position: relative;
	margin: auto;
	border-radius: 4px;
	width: 100%;
	height: 100%;
	background: var(--colour-grey-one);
	transition: 0.2s;
	cursor: pointer;
}

.image-input-circular .image-input-value {
	border-radius: inherit;
}

.image-input-value img {
	border-radius: inherit;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-input-value-icon {
	position: absolute;
	z-index: 3;
	border-radius: inherit;

	width: 100%;
	height: 100%;

	font-size: 48px;
	color: var(--colour-blue);
	opacity: 0;
	transition: 0.2s;
}

.image-input-value-icon svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 48px;
	height: 48px;
}

.image-input-value:hover .image-input-value-icon {
	opacity: 1;
}

.image-input-circular .image-input-value-icon svg {
	width: 32px;
	height: 32px;
}

.image-input-value-icon::after {
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	content: "";
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--colour-grey-four);
	opacity: 0.6;
}

.image-input input {
	display: none;
}
