.user-header-names {
	width: fit-content;
	max-width: 100%;
	vertical-align: middle;
}

.user-header-names-is-authorized {
	max-width: 100%;
}

.editable-container-is-editing.user-header-names-nickname-container {
	align-items: flex-end;
}

.user-header-names-nickname,
.user-header-names-nickname-container input {
	--text-input-font-size: 24px;
	font-size: 24px;
	font-weight: 600;
}

.user-header-names-nickname-container {
	width: fit-content;
}

.user-header-names-nickname {
	width: 100%;
}

.user-header-names-username {
	font-size: 16px;
	font-weight: 300;
	color: var(--text-colour-secondary);
}

.theme-light .user-header-names-username {
	color: #000;
}

@media (max-width: 750px) {
	.user-header-names {
		order: 3;
		flex: 1 0 calc(100% - 24px - 156px);
		margin: 12px;
		max-width: 100%;
	}

	.user-header-names-is-authorized {
		flex: 1 0 calc(100% - 24px - 100px);
		max-width: calc(100% - 12px);
	}

	.user-header-names-nickname,
	.user-header-names-nickname-container input {
		font-size: 18px;
	}

	.user-header-names-username {
		font-weight: 400;
		font-size: 14px;
	}
}
