.lore-list {
	display: flex;
}

.lore-list-loading-container {
	position: relative;
	margin: 0 auto;
	width: fit-content;
	height: min(300px, 50vh);
}

.lore-list-add-first-container {
	padding-top: 12px;
	height: min(max(60vh, 200px), 550px);
}

.lore-list-add-first-container .first-add-btn {
	border-radius: 5px;
}
