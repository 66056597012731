.characters-character-type-container {
	display: inline-block;
	flex-shrink: 0;
	margin-left: 8px;
	max-width: 1200px;
	min-height: calc(100dvh - 53px - 16px - 24px - 92px);
	vertical-align: top;
}

.characters-character-type-primary {
	width: 100%;
	height: 40px;
}

.characters-character-type {
	display: flex;
	gap: 16px;
	flex-direction: column;
}

@media (max-width: 950px) {
	.characters-character-type-container {
		margin: 0;
		margin-top: 6px;
		padding: 0 6px;
		box-sizing: border-box;
		width: 100%;
	}

	.characters-character-type-delete-container {
		margin: 24px 12px;
	}
}
