.objects {
	margin: 0 auto;
	padding: 0 24px;
	padding-bottom: 48px;
	max-width: 1900px;
}

@media (max-width: 750px) {
	.objects {
		padding: 0 8px;
	}
}
