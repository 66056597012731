.landing-hero-register-btn {
	position: relative;
	display: flex;
	align-items: center;
	gap: 0 10px;
	margin: 0;
	padding: 10px 18px;
	border: 0;
	border-radius: 32px;

	height: fit-content;

	background: transparent;
	color: var(--text-colour-primary);
	font-size: var(--font-size-h2);
	font-weight: 500;
	transition: 0.2s;
	cursor: pointer;
}

.landing-hero-register-btn:hover {
	color: #fff;
}

.landing-hero-register-btn::before {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	display: block;
	content: "";
	border-radius: inherit;
	width: 100%;
	height: 100%;
	background: var(--colour-grey-three);
	opacity: 0.6;
	transition: 0.2s;
}

.landing-hero-register-btn:hover::before {
	background: var(--colour-grey-five);
}

@media (max-width: 756px) {
	.landing-hero-register-btn {
		margin: 0 6px;
	}
}

@media (max-width: 500px) {
	.landing-hero-register-btn {
		width: max-content;
	}

	.landing-hero-register-btn::before {
		opacity: 1;
	}
}
