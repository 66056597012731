.home-genres-list-item {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 3px 14px;
	padding-right: 6px;
	border-radius: 18px;

	min-width: min(115px, calc(50% - 14px - 6px - 22px));

	background: var(--colour-grey-four);
	word-break: break-word;
	overflow-wrap: anywhere;
	transition: background 0.1s;
	cursor: pointer;
}

.home-genres-list-item:hover {
	background: var(--colour-grey-five);
}

.home-genres-list-item-favourited {
	background: linear-gradient(0deg, var(--colour-blue), var(--colour-blue-tint));
	color: #fff;
}

.home-genres-list-item-favourited:hover {
	background: linear-gradient(0deg, var(--colour-blue-tint), var(--colour-blue-tint));
}

.home-genres-list-item-title {
	margin-right: 8px;
	font-size: 14px;
	line-height: 15px;
	text-align: left;
}

.editable-container-is-editing .home-genres-list-item-title {
	margin-right: 0;
}

.home-genres-list-item-info {
	display: flex;
	align-items: center;
}

.home-genres-list-item-stat {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.home-genres-list-item-stat:last-child {
	margin-right: 0;
}

.home-genres-list-item-stat svg {
	font-size: 13px;
}

.home-genres-list-item-stat-value {
	margin-left: 3px;
	font-size: 12px;
	font-weight: 600;
}

.home-genres-list-item-add-btn-container {
	margin-left: auto;
	padding-left: 4px;
}

.home-genres-list-item-favourite-btn-container {
	display: block;
	margin-left: auto;
	padding-left: 4px;
}

.home-genres-list-item-unfavourite-btn-container {
	display: none;
	margin-left: auto;
	padding-left: 4px;
}

.home-genres-list-item-delete-btn-container .icon-btn:hover {
	color: var(--text-colour-secondary);
}

.home-genres-list-item-delete-btn-container svg {
	width: 74% !important;
}

.home-genres-list-item-favourited .home-genres-list-item-delete-btn-container svg {
	fill: #fff;
}

.home-genres-list-item-unfavourite-btn-container .icon-btn svg {
	fill: #fff;
}

.home-genres-list-item-favourited .home-genres-list-item-favourite-btn-container {
	display: none;
}

.home-genres-list-item-favourited .home-genres-list-item-unfavourite-btn-container {
	display: block;
}

.home-genres-list-item .icon-btn,
.home-genres-list-item .icon-btn:hover {
	color: var(--text-colour-primary);
}

@media (max-width: 750px) {
	.home-genres-list-item {
		margin: 0;
		box-sizing: border-box;
		width: 100%;
		max-width: 350px;
	}

	.home-genres-list-item-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
}
