.substories-list-substories-posters-container {
	position: relative;
	margin-top: -24px;
	margin-left: -12px;
	margin-bottom: 8px;
	width: calc(100% + 24px);
	overflow-y: visible;
}

.substories-list-substories-posters {
	display: flex;
	margin-top: -24px;
	padding-top: 24px;
}

.substories-list-substories-posters-placeholders-container {
	margin-top: -48px;
}

.substories-list-substories-posters-add-first-container {
	padding-top: 32px;
	height: min(max(60vh, 200px), 550px);
}

.substories-list-substories-posters-add-first-container .first-add-btn {
	border-radius: 5px;
}

@media (max-width: 750px) {
	.substories-list-substories-posters-container {
		margin-left: 0;
		width: 100%;
	}
}
